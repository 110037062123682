import React, { useState,useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";
import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

export default function ProductsCount({ projectId}) {   
  const token = useSelector((state) => state.session.token);
  const [countValue, setCountValue] = useState('-');
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/products-count?project_id=${projectId}`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setCountValue(result.data.data);
    };    
    projectId>0&&isVisible&& fetchData();    
  }, [isVisible, projectId]);
  return (    
        <VizSensor onChange={doLoadData}>
          <div>
            {countValue==='-'?(
              <CircularProgress size={16} />
            ):(
              countValue
            )}
          </div>
        </VizSensor>      
  );
}
