import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from "@material-ui/core/Slide";

// import { Helpers } from "../../utility/helpers";



const useStyles = makeStyles((theme) => ({}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function MergeConfirmDialog(props) {
  const classes = useStyles();
  const { open, onClose, product, handleAccept } = props;

  const handleAcceptClick = () => {
    // alert("ok");
    handleAccept && handleAccept()
  };
  const handleClose = () => {
    // alert("close");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm merge</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <table>
            {product && product.original && (
              <tr>
                <td>{product.original.product.fullname}</td><td>{product.original.product.brand}</td><td>G#{product.original.product.product_matching_group_id}</td>
              </tr>
            )}
            {product &&
            product.potentials &&
            product.potentials.filter((p) => p.product_matching_group_id >0).map((item, i) => (
              <tr>
               <td>{item.fullname}</td><td>{item.brand}</td><td>G#{item.product_matching_group_id}</td>
              </tr>
            ))}
          </table>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  variant="outlined" onClick={handleAcceptClick} color="secondary">
          Agree
        </Button>
        <Button  variant="outlined" onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
