import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  tableCell:{
    padding: theme.spacing(1)
  }
}));

export default function SimilarListItem({similarGroup, openManageSimilarGroupDialog} ) {
  const classes = useStyles();
  return (
      <TableRow key={similarGroup.id}>
        <TableCell align="center" className={classes.tableCell}>{similarGroup.id}</TableCell>
        <TableCell align="left"  className={classes.tableCell}>
            <table style={{width:"100%"}}>
              {similarGroup.groups.map(group=>(
                <tr ><td style={{border:"solid 1px #DDD"}}>{group.name}</td><td style={{width:"50px",textAlign:"center",border:"solid 1px #DDD"}} >{group.size}</td><td style={{width:"50px",textAlign:"center",border:"solid 1px #DDD"}}>{group.products_count}</td></tr>
              ))}
            </table>            
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>{similarGroup.count}</TableCell>
        <TableCell align="center"  className={classes.tableCell}>{similarGroup.groups.length}</TableCell>
        <TableCell align="center"  className={classes.tableCell}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => openManageSimilarGroupDialog(similarGroup.groups[0])}
          >
            Manage
          </Button>
        </TableCell>
      </TableRow>
  );
}
