import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import DialogStatusUpdate from "./DialogStatusUpdate";
import DialogThankYouMessage from "./DialogThankYouMessage";
import statusStock from "../data/statusStock";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import TimeAgo from "react-timeago";

const useStyles = makeStyles((theme) => ({
  productName: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
    overflow:"hidden",
  },
  productStatus: {
    color: "#FFF",
    textAlign: "center",
    height: "46px",
    width: "148px",
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      width: "77px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "65px",
      height: "23px",
    },
  },
  productStatusText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "21px",
    lineHeight: "46px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      // paddingTop: "4px",
      // paddingBottom: "6px",
      lineHeight: "25px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      lineHeight: "23px",
      // paddingTop: "2px",
      // paddingBottom: "3px",
    },
  },
  productLastUpdate: {
    fontSize: "21px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
   
    [theme.breakpoints.down("sm")]: {
      width:"60px",
      fontSize: "10px",
      
    },
   
    [theme.breakpoints.down("xs")]: {
      // width:"50px",
      fontSize: "10px",
      // paddingTop: "2px",
      // paddingBottom: "3px",
    },
    overflow:"hidden",
    // lineHeight: "12px",
  },
  productUpdateButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",

    width: "148px",
    height: "44px",

    border: "solid 1px",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "65px",
      height: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "65px",
      height: "22px",
    },
  },
  productUpdateButtonText: {
    fontSize: "21px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      paddingLeft: "8px",
    },
  },
  iconArrowDropDown: {
    width: "1.5em",
    height: "1.5em",
    // fontSize: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "1em",
      height: "1em",
      // fontSize: "1.5rem",
    },
  },
  tdUpdate: {
    //padding:"20px",
    width:"200px",
    [theme.breakpoints.down("sm")]: {
      width:"67px",
      // fontSize: "1.5rem",
    },
  },
}));
export default function ProductStockRow(props) {
  const classes = useStyles();
  const [product, setProduct] = useState(props.product);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogThankYouOpen, setIsDialogThankYouOpen] = useState(false);
  const handleOnClick = (statusId) => {
    const newProduct = { ...product };
    newProduct.status = statusId;
    newProduct.updated = Date.now();
    const postData = {
      productID: props.productID,
      statusID: statusId,
      storeID: props.storeID,
    };

    // console.log(postData);
    let url = `${envConfig.API_ROOT}${
      envConfig.API_VERSION
    }/products/stockupdate`;
    axios
      .put(url, postData)
      .then((response) => {
        if (response.data.success) {
          setProduct(newProduct);
          setIsDialogOpen(false);
          setIsDialogThankYouOpen(true);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  };
  const handleOnThankYouClose = () => {
    setIsDialogThankYouOpen(false);
  };
  const currentDDate =Date.now();
  const lastUpdate = <TimeAgo date={currentDDate} />;
  // console.log(lastUpdate.render);
  return (
    <tr className={classes.tr}>
      <td align="left">
        <Typography noWrap={true} className={classes.productName}>
          {product.name}
        </Typography>
      </td>
      <td align="center">
        <div
          className={classes.productStatus}
          style={{ backgroundColor: statusStock[product.status].color }}
        >
          <Typography noWrap={true} className={classes.productStatusText}>
            {statusStock[product.status].name}
          </Typography>
        </div>
      </td>
      <td align="center" className={classes.tdUpdate}>
        <Typography noWrap={true} className={classes.productLastUpdate}>
          {product.updated && <TimeAgo date={product.updated} />}
          {/* {product.updated} */}
        </Typography>
      </td>
      <td align="right">
        <div className={classes.productUpdateButton} onClick={handleOnOpen}>
          <Typography className={classes.productUpdateButtonText}>
            Update
          </Typography>
          <ArrowDropDownIcon className={classes.iconArrowDropDown} />
        </div>
        <DialogStatusUpdate
          open={isDialogOpen}
          onClose={handleOnClose}
          onStatusClick={handleOnClick}
          product={product}
        />
        <DialogThankYouMessage
          open={isDialogThankYouOpen}
          onClose={handleOnThankYouClose}
        />
      </td>
    </tr>
  );
}
