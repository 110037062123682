import React, { useState, useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import StoreLogoOnline from "../../StoreLogo/StoreLogoOnline";
import ProductItem from "../../Product/ProductItem";
import Button from "@material-ui/core/Button";
import Grow from '@material-ui/core/Grow';

import ProductItemLoadMore from "./ProductItemLoadMore";
// import TodaysDealsCategoriesFilter from "../../TodaysDeals/TodaysDealsCategoriesFilter";
import SearchBox from "./SearchBox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../../bin/env.config";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  viewAllContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    cursor: "pointer",
    textTransform: "uppercase",
  },
  viewAllStoreName: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "1.125rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  btnCategory:{
    margin: theme.spacing(1),
    borderRadius:"16px"
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ViewAllDialogLoadMore(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [selectedCount, setSelectedCount] = useState('[all]');
  const [productsFilter, setProductsFilter] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showMoreCats, setShowMoreCats] = useState(false);
  // const [showCats,setShowMoreCats] = useState(false);

  const province = useSelector((state) => state.location.province);
  // const storeCodes = useSelector(
  //   (state) => state.location.filteredStores
  // ).reduce(function(a, b) {
  //   return a + `,'${b.storeID}'`;
  // }, `'0'`);

  const { open, onClose, companyData } = props;

  const {
    productShare,
    productSelect,
    productView,
    productLike,
    productAlert,
    showAlert,
    openEditProductDialog,
    isAdmin
  } = props;
  const removeProductFromState = (sppId) => {
    setProducts(products.filter((p) => p._id != sppId));
  };
  const updateProductFromState = (inputState)=>{
    setProducts(products.map((p) =>{
      let currentProduct = {...p};
      if( currentProduct._id === inputState.sppId){
        currentProduct.pricing.regularPrice = parseFloat(inputState.regularPrice);//: product.pricing.regularPrice,
        currentProduct.pricing.currentPrice = parseFloat(inputState.currentPrice);
        currentProduct.pricing.saleDescription = inputState.saleDescription; 
         currentProduct.priceDisplayText2 = '';
        currentProduct.pricing.discountType = parseInt(inputState.discountType); 
        //isOnSale=product.,
        currentProduct.pricing.discountAmount = parseFloat(inputState.discountAmount);
        currentProduct.pricing.discountCondition = parseFloat(inputState.discountCondition);
        currentProduct.pricing.realizedPercentDiscount = parseFloat(inputState.realizedPercentDiscount); 
        currentProduct.categoryId = parseInt(inputState.categoryId);  
        // console.log(currentProduct);     
      }     
      return currentProduct;
    }));    
  }
  //load more products when scroll down
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(15);
  const [skip, setSkip] = useState(0);

  useEffect(() => {
    const fetchCategories = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/categories`;
      axios.get(url).then((result) => {
        // console.log(result.data.data);
        let categories = [...result.data.data];
        categories.forEach(function(element) {
          element.selected = false;
        });
        // console.log(categories);
        setCategories(categories);
      });
      setIsLoading(false);
    };
    fetchCategories();
  }, []);
  const merge2ArrayProducts=(firstArrayProducts, secondArrayProducts)=>{
    let ret = [];
    //
    let secondArrayProductsFilter = secondArrayProducts.filter((item)=>!firstArrayProducts.find(i=>i._id==item._id||i.pricing.realizedPercentDiscount<item.pricing.realizedPercentDiscount));
    return [...firstArrayProducts, ...secondArrayProductsFilter];
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // console.log(com)
      //Fix error with province not define
      let selectedCategoryIds = categories.reduce(
        function(accumulator, currentCat) {
          if (currentCat.selected) return accumulator + "," + currentCat.id;
          return accumulator;
        },
        ["0"]
      );
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/featured-more?limit=${limit}&skip=${skip}`;
      const result = await axios.post(url, {
        companyId: companyData.company._id,
        storeId: companyData.products[0].store.storeID,
        province: province || companyData.products[0].store.province,
        categoryIds: selectedCategoryIds,
      });

      if (skip === 0) {
        // if (selectedCategoryIds === "0") {
          // console.log(companyData.products);
          setProducts(
            merge2ArrayProducts(companyData.products,result.data.data)
            // result.data.data
          );
        // } else {
        //   // console.log('here'+selectedCategoryIds);
        //   setProducts(result.data.data);
        // }
      } else {
        setProducts(Array.from(new Set([...products, ...result.data.data])));
      }
      console.log(products);
      setLoadMore(result.data.data.length === limit);
      setIsLoading(false);
    };
    fetchData();
  }, [skip, categories]);

  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };

  useEffect(() => {
    if (searchText.length > 0) {
      let productsFilterTemp = products.filter(function(product) {
        if (product.fullName.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      setProductsFilter([...productsFilterTemp]);
    } else {
      setProductsFilter([...products]);
    }
  }, [products, searchText]);

  const doSearch = (searchValue) => {
    setSearchText(searchValue);
  };

  const handOnCategoriesClick = (itemId) => {
    const categoriesTemp = categories.map((category) => {
      if (category.id === itemId) {
        category.selected = !category.selected;
      }
      return category;
    });
    setCategories(categoriesTemp);
    // const count = categoriesTemp.reduce((t, {selected}) => t+selected?1:0, 0);
    // setSelectedCount(count===0?'[All]':`[${count}]`);
    setSkip(0);
    setAnchorEl(null);
  }; //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {companyData.company.name}
          </Typography>
          {/* <Hidden smUp>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Categories 
            </Button>
            <Menu
              id="categories-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              // onClose={handleClose}
            >
              <MenuItem onClick={() => setAnchorEl(null)}>
                <CloseIcon /> <Typography>Close</Typography>
              </MenuItem>
              <Divider />
              {categories &&
                categories.map((category) => (
                  <MenuItem
                    selected={category.selected}
                    onClick={() => handOnCategoriesClick(category.id)}
                  >
                    {category.description}
                  </MenuItem>
                ))}
            </Menu>
          </Hidden> */}
          {/* <div className={classes.search}>
            <SearchBox doSearch={doSearch} />
          </div> */}
        </Toolbar>
      </AppBar>

      <StoreLogoOnline company={companyData.company} />
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <div className={classes.viewAllContainer}>
            <span className={classes.viewAllStoreName}>
              {companyData.products[0]&&companyData.products[0].store.name}
            </span>
          </div>
        </Grid>
      </Grid>
      {/* //Begin Categories */}
      {/* <Hidden smDown>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Grid item>
            <div>
              {categories &&
                categories.slice(0,3).map((category) => (                  
                  <Button
                    size="small"
                    className={classes.btnCategory}
                    color="secondary"
                    variant={category.selected ? "contained" : "outlined"}
                    onClick={() => handOnCategoriesClick(category.id)}
                  >
                    {category.description}
                  </Button>                 
                ))}
              {showMoreCats &&
                categories.slice(3).map((category) => (
                  <Grow in={showMoreCats} style={{ transformOrigin: '0 0 0' }}
                  {...(showMoreCats ? { timeout: 1000 } : {})}>
                  <Button
                    size="small"
                    className={classes.btnCategory}
                    color="secondary"
                    variant={category.selected ? "contained" : "outlined"}
                    onClick={() => handOnCategoriesClick(category.id)}
                  >
                    {category.description}
                  </Button>
                  </Grow>
                ))}
              <Button
                size="small"
                className={classes.margin}
                variant="contained"
                color="primary"
                onClick={() => setShowMoreCats(!showMoreCats)}
              >
                {showMoreCats?"Show less": "Show more"}
              </Button>
              
            </div>
          </Grid>
        </Grid>
      </Hidden> */}
      {/* End show categories */}
      <Grid container spacing={24}>
        {productsFilter.map((item) => (
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <ProductItem
              key={item._id}
              product={item}
              productShare={productShare}
              productSelect={productSelect}
              productView={productView}
              productLike={productLike}
              productAlert={productAlert}
              showAlert={showAlert}
              openEditProductDialog={openEditProductDialog}
              removeProductFromState={removeProductFromState}
              updateProductFromState={updateProductFromState}
              isAdmin={isAdmin}
            />
          </Grid>
        ))}
        {loadMore && <ProductItemLoadMore doLoadMore={doLoadMore} />}
      </Grid>
    </Dialog>
  );
}
