import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import envConfig from "../../../bin/env.config";
// import store from "../../../store/store";

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
  toolbarRight: {
    minHeight: "64px",
  },
  toolbarTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },

  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  coopItem: {
    fontWeight: "bold",
    fontSize: "1.2em",
    backgroundColor: "#DDD",
  },
  matchedItem: {
    backgroundColor: "rgba(100, 235, 147, 0.4)",
  },
  missingItem: {
    backgroundColor: "rgba(255, 90, 110, 0.4)",
  },
  extraItem: {
    backgroundColor: "rgba(255, 207, 89, 0.4)",
  },
}));

export default function GoldenTestResult() {
  const history = useHistory();
  const classes = useStyles();
  const [goldenTestResults, setGoldenTestResults] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/test/golden-list`;
      const result = await axios.get(url);
      setGoldenTestResults(result.data.data);
      // dispatch(setCategories(categories));
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Golden data test matching result
          </Typography>
          {/* <div className={classes.sliderContainer}>
              <div style={{ padding: 20 }}>
                <Button
                  ref={btnAcceptRef}
                  variant="contained"
                  color="secondary"
                  // size="small"
                  // className={classes.removeButton}
                  disabled={acceptDisabled}
                  onClick={handleUpdate}
                >
                  Update result
                </Button>
            </div>
          </div> */}
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbarRight} />
        <div className={classes.reviewPanel}>
          <div>
            <h3>Totals:</h3>
            <b>Co-op "gold standard" products:</b>
            {goldenTestResults.totalGoldStandardProduct}
            <br />
            <b>Total products in "gold standard" list:</b>{" "}
            {goldenTestResults.totalGoldStandardProductList} (on bettercart =
            'yes')
            <br />
            <b className={classes.matchedItem}>Matched:</b>{" "}
            {goldenTestResults.matched}/
            {goldenTestResults.totalGoldStandardProductList} products ={" "}
            {Math.round(
              (goldenTestResults.matched * 100) /
                goldenTestResults.totalGoldStandardProductList
            )}
            %;
            <br />
            <b className={classes.missingItem}>Missing:</b>{" "}
            {goldenTestResults.missing}/
            {goldenTestResults.totalGoldStandardProductList} products ={" "}
            {Math.round(
              (goldenTestResults.missing * 100) /
                goldenTestResults.totalGoldStandardProductList
            )}
            %;
            <br />
            <b className={classes.extraItem}>Extra:</b>{" "}
            {goldenTestResults.extra}/
            {goldenTestResults.totalGoldStandardProductList} products ={" "}
            {Math.round(
              (goldenTestResults.extra * 100) /
                goldenTestResults.totalGoldStandardProductList
            )}
            %;
            <br />
          </div>
        </div>
        <div className={classes.reviewPanel}>
          <div className={classes.summaryLeft}>
            <h3 className={classes.matchedItem}>Rate success:</h3>
            <table>
              {goldenTestResults.rateSuccess &&
                goldenTestResults.rateSuccess.reverse().map((value, index) => (
                  <tr>
                    <td style={{ textAlign: "right" }}>
                      {(10 - index) * 10}%:
                    </td>
                    <td style={{ textAlign: "right" }}>{value}</td>
                  </tr>
                ))}
            </table>
          </div>
          <div className={classes.summaryRight}>
              <h3>&nbsp;</h3>
              <table  style={{ border: "1px solid #DDD" }}>
                <tr style={{ border: "1px solid #DDD", backgroundColor:"#EEE" }}>
                  <th>#</th>
                  <th>Category</th>
                  <th>Coop products</th>
                  <th>Total products list</th>
                  <th>Matched</th>
                  <th>Missing</th>
                  <th>Extra</th>
                </tr>
              {goldenTestResults.categorySummary &&
                goldenTestResults.categorySummary.map((category, index) => (
                  <tr  style={{ border: "1px solid #DDD", backgroundColor:"#EEE" }} >
                    <td style={{ textAlign: "right" }}>
                      {index+1}
                    </td>
                    <td>{category.categoryName}</td>
                    <td style={{ textAlign: "right" }}>{category.coopProducts}</td>
                    <td style={{ textAlign: "right" }}>{category.goldProducts}</td>
                    <td style={{ textAlign: "right" }} className={classes.matchedItem}>{category.matched}</td>
                    <td style={{ textAlign: "right" }} className={classes.missingItem}>{category.missing}</td>
                    <td style={{ textAlign: "right" }} className={classes.extraItem}>{category.extra}</td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
        <div className={classes.reviewPanel}>
          <table>
            {goldenTestResults.detail &&
              goldenTestResults.detail.map((product,index) => (
                <>
                  <tr className={classes.coopItem}>
                    <td colspan="6">{product.item.category_name}</td>    
                  </tr>
                  <tr className={classes.coopItem}>
                    <td align="center">{index+1}.R#:{product.item.no}</td>
                    <td>ID:{product.item.product_id}</td>
                    <td>UPC:{product.item.upc}</td>
                    <td>
                      {product.item.search_name}&nbsp;
                      {product.item.product_matching_group_id > 0 && (
                        <a
                          href={`/matching-group/${product.item.product_matching_group_id}`}
                          target="_blank"
                        >
                          {`#${product.item.product_matching_group_id}`}
                        </a>
                      )}
                    </td>
                    <td>
                      Matched:{product.matched.length}/
                      {product.totalGoldenItemToMatching} = {product.rate}%
                    </td>
                    <td>Extra:{product.extra.length}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colspan="3">
                      <table>
                        <tr>
                          <th style={{ textAlign: "right" }}>Row</th>
                          <th>Store</th>
                          <th>Location</th>
                          <th>Product Id</th>
                          <th>Product Name</th>
                          <th>Note</th>
                        </tr>
                        {product.matched.map((matchedItem) => (
                          <tr className={classes.matchedItem}>
                            <td>{matchedItem.row_no}</td>
                            <td>{matchedItem.store}</td>
                            <td>{matchedItem.location}</td>
                            <td>{matchedItem.product_id}</td>
                            <td>{matchedItem.product_name}</td>
                            <td></td>
                          </tr>
                        ))}
                        {product.missing.map((missingItem) => (
                          <tr className={classes.missingItem}>
                            <td>{missingItem.row_no}</td>
                            <td>{missingItem.store}</td>
                            <td>{missingItem.location}</td>
                            <td>{missingItem.product_id}</td>
                            <td>{missingItem.name_on_bettercart}</td>
                            <td>
                              {missingItem.note}
                              {missingItem.product_matching_group_id > 0 && (
                                <a
                                  href={`/matching-group/${missingItem.product_matching_group_id}`}
                                  target="_blank"
                                >
                                  {`#${missingItem.product_matching_group_id}`}
                                </a>
                              )}
                            </td>
                          </tr>
                        ))}
                        {product.extra.map((extraItem) => (
                          <tr className={classes.extraItem}>
                            <td>{extraItem.row_no}</td>
                            <td>{extraItem.company_name}</td>
                            <td>{extraItem.store_name}</td>
                            <td>{extraItem.product_id}</td>
                            <td>{extraItem.product_name}</td>
                            <td></td>
                          </tr>
                        ))}
                      </table>
                    </td>
                  </tr>
                </>
              ))}
          </table>
        </div>
      </main>
    </div>
  );
}
