const companiesDefaultState = {
  companies: []
};

export default (state=companiesDefaultState, action) => {
  switch (action.type) {
    case 'SET_COMPANIES' :
      return {
        ...state,
        companies: action.companies
      };
    default:
      return state;
  }
};
