import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Store from "@material-ui/icons/Store";

// import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import Favorite from "@material-ui/icons/Favorite";
import LocationOn from "@material-ui/icons/LocationOn";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import Share from "@material-ui/icons/Share";
import AttachMoney from "@material-ui/icons/AttachMoney";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Hidden from "@material-ui/core/Hidden";

import { addFavoriteProduct } from "../../actions/session";
import {
  setFavoriteProductError,
  setFavoriteProductSuccess,
} from "../../actions/user";
import { startAddProductToCart } from "../../actions/cart";

import Skeleton from "react-loading-skeleton";

import { Helpers } from "../../utility/helpers";
import globalStyles from "../../styles/styles";

import { startRemoveProductToCart, updateProduct } from "../../actions/cart";
const EditProductInfoBlock = lazy(() =>
  import("../ProductMatching/EditProductInfoBlock")
);
const FixThePrice = lazy(() => import("../FixThePrice/FixThePrice"));

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1) - 2,
  },
  iconSmall: {
    fontSize: 20,
  },
  cardWrapper: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  cardlogo: {
    textAlign: "center",
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  mediaDetail: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 250,
    height: 250,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
  },
  buttonProgress: {
    ...globalStyles.buttonSpinnerProgress,
  },
  productName: {
    // lineHeight: '1em'
    fontSize: "1em",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85em",
    },
  },
  productPrice: {
    // fontSize: '20px',
    fontWeight: "bold",
    // ,
    // lineHeight: '1.2em'
  },
  productPriceDigit: {
    display: "inline-block",
  },
  pricePer: {
    fontSize: "0.8rem",
    color: "#999",
  },
  productDiscount: {
    backgroundColor: "#00b04f",
    color: "#fff",
    padding: ".3em",
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "4px 0 0 0",
    display: "block",
    fontSize: "14px",
  },
  locationIcon: {
    // fontSize: "0.8rem",
    // marginTop: theme.spacing(1),
    // marginRight: theme.spacing(1),
    position: "relative",
    top: "2px",
    width: "auto",
    // width: theme.typography.display1.fontSize,
    height: "0.8rem",
  },
  regularPrice: {
    fontWeight: "normal !important",
    display: "inline-block",
    fontSize: "0.9em",
    paddingLeft: "1em",
    // ,
    // lineHeight: '0.8em !important'
  },
  youSave: {
    fontWeight: "bold",
  },
  comparePriceButtonContainer: {
    paddingTop: 0,
  },
  comparePriceButtonContainerDetail: {
    display: "none",
  },
  comparePriceButton: {
    display: "block",
    width: "100%",
    "& .MuiButton-label": {
      fontWeight: "600",
    },
  },
  comparePriceButtonDetail: {
    display: "none",
  },
  dealText: {
    display: "block",
    paddingRight: "5px",
    fontSize: "0.9em",
  },
  quantityButton: {
    backgroundColor: theme.palette.primary.main,
    padding: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  quantityAddButton: {
    padding: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  },
  quantityRemoveButton: {
    padding: 0,
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%",
    marginLeft: "0",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
  },
  quantityButtonIcon: {
    fontSize: "35px !important",
    color: "#fff",
    fontWeight: "bold",
  },
  horizontal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quantityWrapper: {
    border: "1px solid #ccc",
    width: "100%",
    //marginRight: '0',
    //padding: '6px 3px',
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
  },
  quantityWrapperItem: {
    flex: "1 0 21%",
    //margin: '5px',
    height: "100%",
    textAlign: "center",
  },
  quantityText: {
    marginTop: "5px",
    fontSize: "18px !important",
    fontWeight: "bold",
  },

  addToCart: {
    // borderTopLeftRadius: '0',
    // borderBottomLeftRadius: '0',
    // marginLeft: '0 !important',
    "& .MuiButton-label": {
      fontWeight: "600",
    },
  },
  addToCartText: {
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  storeLogo: {
    width: "175px",
    height: "auto",
    margin: theme.spacing(1) + "px",
    opacity: "0.9",
  },
  secondaryActionButtonContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  secondaryActionButtonContainerDetail: {
    //position: 'absolute',
    display: "block",
    //top: '130px',
    right: "15px",
    width: "200px",
    minWidth: "100px",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    [theme.breakpoints.down("sm")]: {
      right: "0px",
      margin: "auto",
    },
  },
  CardActionsDetail: {
    display: "block",
    //alignItems: 'center'
  },
  productActionsDetail: {
    right: "10px",
    width: "200px",
    minWidth: "150px",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    [theme.breakpoints.down("sm")]: {
      right: "0px",
      margin: "auto",
    },
  },

  secondaryActionButton: {
    fontSize: "0.72em",
    padding: "5px",
  },
  secondaryActionButtonDetail: {
    // paddingTop: '10px',
    marginLeft: "0px !important",
    padding: "5px",
    marginTop: "5px",
  },
  shareIcon: {
    position: "absolute",
    paddingRight: "5px",
    left: "5px",
  },
  icon: {
    position: "absolute",
    paddingRight: "5px",
    left: "5px",
  },
  favouriteIcon: {
    position: "absolute",
    paddingRight: "5px",
    left: "5px",
    color: "#ff0000",
  },
  favouriteButton: {
    color: "#ff0000",
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "40px",
    minWidth: "40px",
  },
});

class ProductItem extends Component {
  constructor() {
    super();
    this.state = {
      isLoginModalOpen: false,
      isProductInTheCart: false,
      isFavoriteProduct: false,
      isAlertProduct: false,
      localQuantityCount: 1,
      selectedProductId: "",
    };
  }

  componentDidMount = () => {
    // console.log(this.props.product);
    const cartQuantityCount = this.getProductQuantityInTheCartFn(
      this.props.product
    );
    if (cartQuantityCount > 0) {
      this.setState({
        localQuantityCount: cartQuantityCount,
      });
    }

    this.setState({
      isProductInTheCart: this.isProductInTheCartFn(
        this.props.product,
        this.props.cart.itemMappings
      ),
    });

    if (Helpers.isLoggedIn(this.props.session.user)) {
      this.setState({
        isFavoriteProduct: this.isFavoriteProduct(this.props.product),
        isAlertProduct: this.isAlertProduct(this.props.product),
      });
    }
  };

  componentWillReceiveProps = (nextProps, nextState) => {
    // console.log('componentWillReceiveProps');
    // console.log(nextProps);
    if (nextProps.product) {
      const isProductInTheCart = this.isProductInTheCartFn(
        nextProps.product,
        nextProps.cart.itemMappings
      );
      const cartQuantityCount = this.getProductQuantityInTheCartFn(
        nextProps.product
      );
      const isFavoriteProduct = this.isFavoriteProduct(nextProps.product);
      const isAlertProduct = this.isAlertProduct(nextProps.product);

      if (
        nextState.localQuantityCount &&
        this.state.localQuantityCount !== nextState.localQuantityCount
      ) {
        this.setState({
          localQuantityCount: nextState.localQuantityCount,
        });
      }

      if (
        cartQuantityCount > 0 &&
        this.state.localQuantityCount !== cartQuantityCount
      ) {
        this.setState({
          localQuantityCount: cartQuantityCount,
        });
      }

      if (this.state.isProductInTheCart !== isProductInTheCart) {
        this.setState({
          isProductInTheCart: isProductInTheCart,
        });
      }

      if (Helpers.isLoggedIn(this.props.session.user)) {
        if (this.state.isFavoriteProduct !== isFavoriteProduct) {
          this.setState({
            isFavoriteProduct: isFavoriteProduct,
          });
        }

        if (this.state.isAlertProduct !== isAlertProduct) {
          this.setState({
            isAlertProduct: isAlertProduct,
          });
        }
      }
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    const isProductInTheCart = this.isProductInTheCartFn(
      nextProps.product,
      nextProps.cart.itemMappings
    );
    if (this.state.isProductInTheCart !== isProductInTheCart) {
      // console.log('YES UPDATE: change in product state ' + isProductInTheCart + ' ' + this.state.isProductInTheCart);
      return true;
    }

    if (
      nextState.localQuantityCount &&
      this.state.localQuantityCount !== nextState.localQuantityCount
    ) {
      return true;
    }

    if (this.state.isFavoriteProduct !== nextState.isFavoriteProduct) {
      return true;
    }

    if (this.state.isAlertProduct !== nextState.isAlertProduct) {
      return true;
    }

    if (nextProps.product !== this.props.product) {
      // console.log('UPDATE BECAUSE PROPS CHANGED');
      return true;
    } else {
      // console.log('SKIP UPDATE');
      return false;
    }
  };

  renderLoadingState = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Skeleton width={300} />
          <br />
          <div style={{ fontSize: 80 }}>
            <Skeleton />
          </div>
          <Skeleton width={200} />
          <br />
          <br />
        </CardContent>
        <CardActions>
          <div style={{ fontSize: 25 }}>
            <Skeleton width={300} />
          </div>
        </CardActions>
      </Card>
    );
  };

  isProductInTheCartFn = (product, itemMappings) => {
    // console.log('--------');
    //console.log('props.product: ' + JSON.stringify(this.props.product));
    //console.log('itemMappings: '+JSON.stringify(itemMappings));
    // console.log('----------');
    return product && typeof itemMappings[product._id] === "object";
    // return this.props.product.isInTheCart;
  };

  isFavoriteProduct = (product) => {
    let isFavorite = false;
    if (product && this.props.session.user) {
      for (let favoriteProduct of this.props.session.user.favoriteProducts) {
        if (favoriteProduct.productID === product.productID) {
          isFavorite = true;
          break;
        }
      }
    }
    return isFavorite;
  };

  isAlertProduct = (product) => {
    let isAlert = false;
    if (product && this.props.session.user) {
      if (this.props.session.user.alertProducts) {
        for (let alertProduct of this.props.session.user.alertProducts) {
          if (alertProduct.productID === product.productID) {
            isAlert = true;
            break;
          }
        }
      }
    }
    return isAlert;
  };

  getProductQuantityInTheCartFn = (product) => {
    let quantity = 0;
    if (product) {
      const itemProduct = this.props.cart.items.find(
        (item) => item._id === product._id
      );
      if (itemProduct) {
        quantity = itemProduct.quantity;
      }
    }
    return quantity;
  };

  // renderBrand () {
  //   return (
  //     this.props.product.name.includes(this.props.product.brand) ? null : this.props.product.brand
  //   );
  // }

  isWalmart = () => {
    const { product } = this.props;
    return product.store.companyData._id === "14";
  };

  isRegularPriceAndCurrentPriceDifferent = () => {
    const { product } = this.props;

    if (!product.pricing.regularPrice) {
      return false;
    }

    return product.pricing.currentPrice !== product.pricing.regularPrice;
  };

  pricingText = () => {
    const { classes, product } = this.props;
    return (
      <div>
        <span className={classes.productPrice}>
          {product.priceDisplayText1 != "Flyer Item"
            ? product.priceDisplayText1
            : ""}
        </span>
      </div>
    );
  };

  discountText = () => {
    const { classes, product } = this.props;
    return (
      <span className={classes.productDiscount}>
        {product.pricing.realizedPercentDiscount}% off
      </span>
    );
  };

  regularPriceText = () => {
    const { classes, product } = this.props;
    if (product.isOnSale && product.pricing.regularPrice) {
      return (
        //#200521
        <Typography variant="body1" className={classes.regularPrice}>
          Reg ${product.pricing.regularPrice.toFixed(2)}
        </Typography>
      );
    }
  };

  // dealText = () => {
  //   const { product } = this.props;
  //   return product.priceDisplayText2;
  // }

  // isSpecialDiscount = () => {
  //   const { product } = this.props;
  //   const discountType = product.pricing.discountType
  //   return discountType === 3 || discountType === 4 || discountType === 5
  // }
  showSuccessAddedAlert = () => {
    this.props.showAlert("success", "Success! added to your shopping cart");
  };
  showSuccessRemoveAlert = () => {
    this.props.showAlert("error", "Success! removed from your shopping cart");
  };
  decreaseQuantity = (product) => {
    const quantity = this.state.localQuantityCount - 1;
    if (quantity > 0) {
      this.setState({
        localQuantityCount: quantity,
      });
      product.quantity = quantity;
      this.props.updateProduct(product);
      //#20200605 this.showSuccessRemoveAlert();
    } else {
      this.removeProduct(product);
    }
  };

  increaseQuantity = (product) => {
    const quantity = this.state.localQuantityCount + 1;
    product.quantity = quantity;
    this.props.updateProduct(product);
    //#20200605 this.showSuccessAddedAlert()
    this.setState({
      localQuantityCount: quantity,
    });
  };

  productSelect = (product) => {
    Helpers.collectClick(product.productID, 3); //Click on add to cart button:3
    this.props.startAddProductToCart(
      { ...product, quantity: this.state.localQuantityCount },
      "todaysDeals"
    );
    this.showSuccessAddedAlert();
  };

  removeProduct = (product) => {
    this.props.startRemoveProductToCart(product);
    this.showSuccessRemoveAlert();
  };

  onFavoriteProductChanged = (product) => {
    this.props.productLike(product, this.state.isFavoriteProduct);
    if (this.props.session.user) {
      this.setState({
        isFavoriteProduct: !this.state.isFavoriteProduct,
      });
    }
  };

  onAlertProductChanged = (product) => {
    this.props.productAlert(product, this.state.isAlertProduct);
    if (this.props.session.user) {
      this.setState({
        isAlertProduct: !this.state.isAlertProduct,
      });
    }
  };

  renderProduct() {
    const { classes, product } = this.props;
    // Force to use https for external links
    let productImageUrl = product.imageURL;
    
    // console.log(product);
    // console.log(isAdmin);

    return (
      <div className={classes.cardWrapper}>
        <div className={classes.cardlogo}>
          {this.props.page === "detail" ? (
            <img
              className={classes.storeLogo}
              src={product.store.companyData.companyLogoLink}
            />
          ) : (
            ""
          )}
        </div>
        <Card
          className={classNames({
            [classes.card]: true,
            [classes.horizontal]: this.props.horizontal,
          })}
        >
          <CardMedia
            className={
              this.props.page === "detail" ? classes.mediaDetail : classes.media
            }
            component="img"
            image={productImageUrl}
            onClick={
              this.props.page !== "detail"
                ? () => this.props.productView(product)
                : () => ""
            }
            title={this.props.product.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../resources/images/no-picture-available.png";
            }}
          />

          <CardContent
            className={classNames({
              [classes.cardContent]: true,
              [classes.centeredCardConent]: this.props.horizontal,
            })}
          >
            <Typography className={classes.productName} variant="body2">
              {Helpers.fullProductNameWithBrand(product)} {Helpers.productSize(product)}
            </Typography>

            <Typography variant="caption">
              <LocationOn className={classes.locationIcon} />{" "}
              {product.store.name}
            </Typography>
            <br />
            <Typography
              variant="h6"
              className={classes.productPriceDigit}
              color={product.isOnSale ? "secondary" : "secondary"}
            >
              {//Helpers.isSpecialDiscount(product) ? Helpers.dealText(product) : this.pricingText()
              Helpers.isSpecialDiscount(product)
                ? Helpers.dealText(product)
                : Helpers.pricingText(product)}
            </Typography>
            {this.regularPriceText()}
            {!Helpers.isSpecialDiscount(product) && (
              <Typography className={classes.dealText} variant="body1">
                {Helpers.dealText(product)}
              </Typography>
            )}

            {product.pricing.realizedPercentDiscount > 1
              ? this.discountText()
              : ""}

            <Typography
              variant="body1"
              className={classes.youSave}
              color={product.isOnSale ? "secondary" : "secondary"}
            >
              {Helpers.savePrice(product) !== ""
                ? " You save $" + Helpers.savePrice(product)
                : ""}
            </Typography>

            <Typography variant="caption">{product.dateText}</Typography>
            {product.avgPrice > 0 ? (
              <Typography variant="caption">
                Average price last 3 months $
                {Helpers.asDecimalText(product.avgPrice)}
              </Typography>
            ) : (
              ""
            )}
            <Typography className={classes.pricePer}>
              {product.pricing.currentPriceLb !== 0
                ? "$" + product.pricing.currentPriceLb + "/lb"
                : ""}{" "}
              {product.pricing.currentPrice100 !== 0
                ? " $" + product.pricing.currentPrice100 + "/100"
                : ""}{" "}
              {product.pricing.currentPriceLb !== 0 &&
              product.pricing.currentPrice100 !== 0
                ? "g"
                : ""}
              {product.pricing.currentPriceLb === 0 &&
              product.pricing.currentPrice100 !== 0
                ? "ml"
                : ""}
            </Typography>
            {this.props.isAdmin && (
              <Suspense fallback={<div>Loading...</div>}>
                <EditProductInfoBlock product={product}  page={this.props.page} 
                openEditProductDialog={this.props.openEditProductDialog} 
                removeProductFromState={this.props.removeProductFromState}
                updateProductFromState={this.props.updateProductFromState}/>
              </Suspense>
            )}
          </CardContent>
          <div
            className={
              this.props.page === "detail"
                ? classes.CardActionsDetail
                : classes.CardActions
            }
          >
            <CardActions
              //className={classes.productActions}
              className={
                this.props.page === "detail"
                  ? classes.productActionsDetail
                  : classes.productActions
              }
            >
              {/* {
                this.props.showFavoriteButton && (
                  <Button
                    onClick={ () => this.onAddToFavoriteProducts(this.props.product) }
                    color="secondary"
                    size="small"
                    variant="outlined"
                    disabled={ this.props.user.favoriteProductLoader }
                    >
                    <Favorite />
                  {this.props.user.favoriteProductLoader && <CircularProgress size={24} className={classes.buttonProgress} /> }
                  </Button>
                )
              } */}
              {this.state.isProductInTheCart && (
                <div className={classes.quantityWrapper}>
                  <div className={classes.quantityWrapperItem}>
                    <Button
                      className={classes.quantityRemoveButton}
                      //small="true"
                      onClick={() => {
                        this.decreaseQuantity(product);
                      }}
                      variant="contained"
                      color="primary"
                      disabled={this.state.localQuantityCount == 0}
                    >
                      <Remove className={classes.quantityButtonIcon} />
                    </Button>
                  </div>
                  <div className={classes.quantityWrapperItem}>
                    <Typography className={classes.quantityText}>
                      <span>{this.state.localQuantityCount}</span>
                    </Typography>
                  </div>
                  <div className={classes.quantityWrapperItem}>
                    <Button
                      className={classes.quantityAddButton}
                      variant="contained"
                      color="primary"
                      //small="true"
                      onClick={() => {
                        this.increaseQuantity(product);
                      }}
                    >
                      <Add className={classes.quantityButtonIcon} />
                    </Button>
                  </div>
                </div>
              )}

              {!this.state.isProductInTheCart && (
                <Button
                  onClick={() => this.productSelect(this.props.product)}
                  className={classes.addToCart}
                  color="primary"
                  variant="contained"
                  disabled={this.state.isProductInTheCart}
                  fullWidth={true}
                >
                  {this.state.isProductInTheCart
                    ? "Add to cart"
                    : "Add to cart"}
                </Button>
              )}
            </CardActions>
            <CardActions
              //className={ classes.comparePriceButtonContainer }
              className={
                this.props.page === "detail"
                  ? classes.comparePriceButtonContainerDetail
                  : classes.comparePriceButtonContainer
              }
            >
              <Button
                color="secondary"
                variant="contained"
                className={
                  this.props.page === "detail"
                    ? classes.comparePriceButtonDetail
                    : classes.comparePriceButton
                }
                //className={classes.comparePriceButton}
                onClick={
                  this.props.page !== "detail"
                    ? () => this.props.productView(product)
                    : () => ""
                }
              >
                Compare <Hidden smDown>This </Hidden>Price
              </Button>
            </CardActions>
            {/* <CardActions 
              //className={ classes.secondaryActionButtonContainer }
              className = { this.props.page==="detail" ? classes.secondaryActionButtonContainerDetail : classes.secondaryActionButtonContainer } 
            >
              <TestCom/>
              </CardActions> */}
            <CardActions
              //className={ classes.secondaryActionButtonContainer }
              className={
                this.props.page === "detail"
                  ? classes.secondaryActionButtonContainerDetail
                  : classes.secondaryActionButtonContainer
              }
            >
              <Button
                color="primary"
                variant="outlined"
                fullWidth={true}
                //className={ classes.secondaryActionButton }
                className={
                  this.props.page === "detail"
                    ? classes.secondaryActionButtonDetail
                    : classes.secondaryActionButton
                }
                onClick={() => this.props.productShare(product)}
              >
                {this.props.page === "detail" && (
                  <Share className={classes.icon} />
                )}
                <Hidden mdDown>
                  <Share className={classes.icon} />
                </Hidden>
                Share deal
                {/* </Hidden>Share <Hidden mdDown>the</Hidden> deal               */}
              </Button>
              <Button
                color={this.state.isAlertProduct ? "secondary" : "primary"}
                variant="outlined"
                onClick={() => this.onAlertProductChanged(product)}
                fullWidth={true}
                className={
                  this.props.page === "detail"
                    ? classes.secondaryActionButtonDetail
                    : classes.secondaryActionButton
                }
              >
                {this.props.page === "detail" && (
                  <AttachMoney className={classes.icon} color="secondary" />
                )}
                <Hidden mdDown>
                  <AttachMoney className={classes.icon} color="secondary" />{" "}
                </Hidden>
                {this.state.isAlertProduct ? "Remove" : "Get"} alerts
                {/* {this.state.isAlertProduct? "Remove": "Get"} <Hidden mdDown>sale</Hidden> alerts */}
              </Button>
              <Button
                variant={this.props.page === "detail" ? "outlined" : "text"}
                color="primary"
                fullWidth={true}
                //className={ classes.favouriteButton }
                className={
                  this.props.page === "detail"
                    ? classes.secondaryActionButtonDetail
                    : classes.favouriteButton
                }
                onClick={() => this.onFavoriteProductChanged(product)}
              >
                {this.state.isFavoriteProduct ? (
                  <Favorite className={classes.favouriteIcon} />
                ) : (
                  <FavoriteBorder className={classes.favouriteIcon} />
                )}
                {this.props.page === "detail" ? "Add to Faves" : ""}
              </Button>
              {this.props.page === "detail" && this.props.isAdmin && (
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth={true}
                  onClick={(event) => {
                    event.preventDefault();
                    Helpers.collectClick(product.productID, 7);
                    window.open(product.externalLink);
                  }}
                  className={
                    this.props.page === "detail"
                      ? classes.secondaryActionButtonDetail
                      : classes.secondaryActionButton
                  }
                >
                  <Store color="primary" className={classes.icon} /> View on
                  store
                </Button>              
              )}
              {/* //Fix pricing
              {this.props.page === "detail" && isAdmin && (
                <Suspense fallback={<div>Loading...</div>}>
                  <FixThePrice product={product} />
                </Suspense>
              )} */}
              {/* <Button color="secondary" variant="outlined"  fullWidth={true} 
                  className = { this.props.page==="detail" ? classes.secondaryActionButtonDetail : classes.secondaryActionButton } 
                >
                   Feedback
                </Button>  */}
            </CardActions>
          </div>
        </Card>
      </div>
    );
  }

  render() {
    // console.log('render??');
    return (
      <div>
        {this.props.isLoading
          ? this.renderLoadingState()
          : this.renderProduct()}
        {/* <Dialog
              open={ this.state.isShareOpen }
              onClose = { this.onShareAFriend }
              >
              <DialogTitle>Share BetterCart</DialogTitle>
              <DialogContent>
                Help your friends save money!
                <br /><br />
                Select a method to share below:<br /><br />                  
                <br /><br />
              </DialogContent>
        </Dialog>           */}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
  user: state.user,
  cart: state.cart,
});
const mapDispatchToProps = (dispatch, props) => ({
  startAddProductToCart: (product, productSource) =>
    dispatch(startAddProductToCart(product, productSource)),
  startRemoveProductToCart: (product) =>
    dispatch(startRemoveProductToCart(product)),
  updateProduct: (product) => dispatch(updateProduct(product)),
  addFavoriteProduct: (user, product) =>
    dispatch(addFavoriteProduct(user, product)),
  setFavoriteProductError: (value) => dispatch(setFavoriteProductError(value)),
  setFavoriteProductSuccess: (value) =>
    dispatch(setFavoriteProductSuccess(value)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ProductItem)
);
