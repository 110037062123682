import axios from "axios";
import envConfig from "../bin/env.config";

export const setCategories = (categories = []) => ({
  type: "SET_CATEGORIES",
  categories,
});

export const clearCategories = () => ({
  type: "CLEAR_CATEGORIES",
});

export const fetchCategories = () => {
  return (dispatch) => {
    // console.log('here');
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/categories`;
    axios.get(url).then((result) => {
      // console.log(result.data.data);
      let categories = [...result.data.data];
      categories.forEach(function(element) {
        element.selected = false;
      });
      // console.log(categories);
      dispatch(setCategories(categories));
    });
  };
};

// export const setSelectedCategories = (results) =>{
//   return (dispatch)=>{
//     const productsArray = results.reduce(function(a, b) {
//             return [...a,...b.products];
//           }, []);
//     concon
//   }
// }
