import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  IconButton
} from "@material-ui/core";
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  productImage: {
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  }, 
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divImageContainer: {
    display: "flex",
    width:"100%",
    height:"100%",
    alignItems: "center", 
    justifyContent:"center",    
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
export default function DialogImageZoomIn({imageUrl}) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };  
  return (
    <>
    {/* Zoom In Icon */}
    <IconButton
            style={{
              position: 'absolute',
              top: 60,
              right: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
            onClick={handleOpen}
          >
            <ZoomInIcon />
    </IconButton>
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle>    
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>     
      </DialogTitle>
        <DialogContent >
        <div className={classes.divImageContainer}>
          <img
                className={classes.productImage}
                // style={{ width: '300px',maxHeight:"400px"}}
                src={imageUrl}
                onError={addDefaultSrc}
              />
            </div>
        </DialogContent>
    </Dialog>
    </>
    
  );
}
