import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";

import SettingsIcon  from "@material-ui/icons/Settings";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  TextField
} from "@material-ui/core";
import TimeAgo from "react-timeago";
import axios from "axios";
import envConfig from "../../../bin/env.config";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export function PrintStatusObject({statusObject, setDoProcessFlag}){
  let updatedDateTime=new Date(statusObject.updated_at).toLocaleString()
  // const classes = useStyles();
  //1: waiting, 2: processing, 3: completed, 4: fail
  const handleClick=()=>{
    setDoProcessFlag&&setDoProcessFlag(true)
  }
  return (
    <>
      {statusObject.status=='Waiting'&&(
        <Button  variant="contained" startIcon={<SettingsIcon />} onClick={handleClick}>
              Process
        </Button>
      )}
      {statusObject.status=='Running'&&(
        <>
          <LinearProgress color="secondary" />
          <h4>
          {statusObject.updated_at && <TimeAgo date={statusObject.updated_at} />}
          </h4>
                    
        </>
      )}
      {((statusObject.status=='Completed')||(statusObject.status=='Fail'))&&(
        <>
          <h4>{statusObject.status} at {updatedDateTime} </h4>
          <Button  variant="contained" startIcon={<SettingsIcon />} onClick={handleClick}>
              Re-Process
          </Button>         
        </>
      )}
      <p>You can close this window and do other tasks while waiting for the system to process</p>
    </>
  )
}
export default function DialogWaitingCalculateMatches({open, onClose, project_id, flow_type='Matching'}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [doProcessFlag, setDoProcessFlag] = useState(false);
  const [statusObject, setStatusObject] = useState({})
  useEffect(() => {
    const getStatus = async () => { 
      setIsLoading(true);    
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/get-calculate-status`;
      const result = await axios.post(url,{id:project_id,flow_type:flow_type},{
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setStatusObject(result.data.data);
      setIsLoading(false);    
    };
    open&&getStatus();
  }, [open]);
  useEffect(() => {
    const doProcess = async () => { 
      setIsLoading(true);    
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/do-calculate-process`;
      const result = await axios.post(url, {id:statusObject.company_report_tool_project_id,flow_type:statusObject.flow_type},{
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setStatusObject(result.data.data);
      setIsLoading(false);    
      // setStatusObject(result.data.data);
    };
    doProcessFlag&&doProcess();
  }, [doProcessFlag]);
  
  return (
      <Dialog
        //fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="Calculate"
      >
        <DialogTitle>Calculate {flow_type}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LinearProgress color="secondary" />
          ) : (
            <PrintStatusObject statusObject={statusObject} setDoProcessFlag={setDoProcessFlag}/>
          )}
        </DialogContent>        
      </Dialog>
  );
}
