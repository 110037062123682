import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {  
  Typography,
  IconButton,
  Button,
  Paper,
  FilledInput,
  FormControlLabel,
  Checkbox

} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import SessionTitle from './SessionTitle';
import SessionItem from './SessionItem';
import ButtonEdit from "./Session/ButtonEdit";

import axios from "axios";
import envConfig from "../../bin/env.config";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  dnd:{
    display:'grid',
    width:'100%',
    height:'100%',
    padding: ".5rem",    
    gap:'.5rem',
    gridTemplateRow:'repeat(auto-fill,300px)'
  },
  dndSession: {
    width:'100h',
    backgroundColor:"#DDD",     
  },
  dndSessionTitle: {
    width:'100h',
    fontSize:'1.5rem',
    fontWeight:"bold",
    marginBottom:'.5rem',
    padding: ".5rem",    
    marginBottom:"0",   
  },
  dndItemContainer: {
    minHeight: "50px",
    // backgroundColor:"#DDD",
    gap:'.5rem',
    borderColor:"#DDD",
    display:'grid',
    gridTemplateColumns:'repeat(auto-fill,300px)'
  },
  dndItem:{
    margin:".5rem",
    padding:".5rem",
    borderRadius:"5px",
    backgroundColor:"white",
    color: "#282c34", 
    '& > *': {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      margin: 0,
    },
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
  },
  current:{
    backgroundColor:"black"
  }
}));


export default function LinkManagePage() {
  const history = useHistory();
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);    
  const [dataUpdate, setDataUpdate] = useState([]);    
  const token = useSelector((state) => state.session.token);

  const [reloadData, setReloadData] = useState(0);
  // Drag drop
  const [dragging, setDragging]= useState(false)
  const [draggingItem, setDraggingItem]= useState(false)
  const [ready2Update, setReady2Update]= useState(false)
  
  const dragItem = useRef()
  const dragItemNode = useRef()

  // const handleDragSessionStart = (e, params) =>{
  //   dragSession.current = params;
  //   dragSessionNode.current = e.target;
  //   dragSessionNode.current.addEventListener('dragend',handleDragSessionEnd)
  //   setTimeout(()=>{
  //     setDragging(true)
  //   },0)
  // }
  // const handleDragSessionEnter = (e,params)=>{
  //   const currentSession = dragSession.current;
  //   if(e.target!== dragSessionNode.current){
  //     setDataSource(oldList=>{
  //       let newList = JSON.parse(JSON.stringify(oldList));
  //       newList.splice(params.sessionI,0,newList.splice(currentSession.sessionI,1)[0])
  //       dragSession.current = params
  //       return newList
  //     })
  //   }
  // }
  // const handleDragSessionEnd = ()=>{
  //   setDragging(false)
  //   dragSessionNode.current.removeEventListener('dragend',handleDragSessionEnd)
  //   dragSessionNode.current = null
  //   dragSession.current = null
  // }

  const handleDragItemStart = (e, params) =>{
    
    dragItem.current = params;
    dragItemNode.current = e.target;
    dragItemNode.current.addEventListener('dragend',handleDragItemEnd)
    setTimeout(()=>{
      setDraggingItem(true)
      setReady2Update(false)
    },0)
  }
  const handleDragItemEnter = (e,params)=>{
    const currentItem = dragItem.current;
    if(e.target!== dragItemNode.current){
      setDataSource(oldList=>{
        let newList = JSON.parse(JSON.stringify(oldList));
        newList[params.sessionI].items.splice(params.itemIndex,0,newList[currentItem.sessionI].items.splice(currentItem.itemIndex,1)[0])
        dragItem.current = params
        return newList
      })
    }
  }
  const handleDragItemEnd = ()=>{
    setDraggingItem(false)
    dragItemNode.current.removeEventListener('dragend',handleDragItemEnd)
    dragItemNode.current = null
    dragItem.current = null
    //Update server side
    // setDataUpdate(dataSource)
    setReady2Update(true)
  }

  // const getSessionStyles = (params)=>{
  //   const currentSession = dragSession.current;
  //   if(currentSession.sessionI == params.sessionI){
  //     return [classes.current, classes.dndSession].join(" ")
  //   }
  //   return classes.dndSession
  // }
  const getItemStyles = (params)=>{
    const currentItem = dragItem.current;
    if((currentItem.sessionI == params.sessionI)&&(currentItem.itemIndex==params.itemIndex)){
      return [classes.current, classes.dndItem].join(" ")
    }
    return classes.dndItem
  }
  const doReloadData =()=>{setReloadData(reloadData+1)}
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/link-manage/links`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
      setIsLoading(false);
    };  
    fetchData();    
  }, [reloadData]); 
  useEffect(() => {
    const updateServer = async () => {
      const postData = dataSource.map((session, sessionIndex)=>{
        let items = session.items.map((item, itemIndex)=>{
          return {id:item.id, session_id: session.id, sort_order:itemIndex}
        })
        return {id:session.id, sort_order:sessionIndex,items: items} 
      })
      // console.log('Update---------------',postData);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/link-manage/update`;      
      await axios.post(url, {data:postData}, {
        headers: {
          Authorization: token,
        },
      });      
    };
   
    // console.log(dataUpdate)
    dataSource.length>0 && !draggingItem && ready2Update && updateServer();    
  }, [dataSource,draggingItem,ready2Update]);   
  
  return (
    <>
     <CssBaseline />
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Links
          </Typography> 
          <>
            {editMode&&<ButtonEdit session={null} reloadData={doReloadData}/>}
            &nbsp;&nbsp;
            <FormControlLabel
              control={
                <Checkbox
                  checked={editMode}
                  onChange={() => setEditMode(!editMode)}
                  name="Edit mode"
                  color="secondary"
                />
              }
              label="Edit mode"
            />
          </>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
          <div className={classes.dnd}>
            {dataSource&&dataSource.map((session, sessionI) => (
              <div 
              key={`session-${sessionI}`} 
              // draggable={editMode}
              // onDragStart={(e)=>{handleDragSessionStart(e,{sessionI})}} 
              // onDragEnter={dragging?(e)=>{handleDragSessionEnter(e,{sessionI})}:null}
              // className={dragging?getSessionStyles({sessionI}):classes.dndSession}
              onDragEnter={editMode&& draggingItem && !session.items.length?(e)=>{handleDragItemEnter(e,{sessionI, itemIndex:0})}:null}
              // onDrop={(e)=>{setDataUpdate(dataSource)}}
              >
                <div className={classes.dndSessionTitle} >
                  <SessionTitle session={session} editMode={editMode} reloadData={doReloadData}/>                 
                </div>
                <div className={classes.dndItemContainer}>
                  {session.items&&session.items.map((item, itemIndex) => (
                    <div
                      key={`item-${itemIndex}`}
                      draggable={editMode}
                      onDragStart={editMode?(e)=>{handleDragItemStart(e,{sessionI,itemIndex})}:null} 
                      onDragEnter={editMode&&draggingItem?(e)=>{handleDragItemEnter(e,{sessionI,itemIndex})}:null}
                      className={editMode&&draggingItem?getItemStyles({sessionI,itemIndex}):classes.dndItem}
                    >
                      <SessionItem item={item} session={session} editMode={editMode} reloadData={doReloadData}/>
                    </div>                    
                  ))}                   
                </div>                            
              </div>
            ))}                                  
         </div>
      )}
    </>
  );
}
