const cartDefaultState = {
  itemMappings: {},
  items: [],
};

export default (state = cartDefaultState, action) => {
  let newState;
  switch (action.type) {
    case 'LOAD_SAVED_CART' :
      const cart = {...action.cart};
                      
      newState = {
        ...cart,
      }            
      return {
        ...newState             
      };
    
    case 'ADD_PRODUCT' :
      const product = {...action.product};

      let newMappings = {
        ...state.itemMappings
      };
      newMappings[product._id] = product;
      product.quantity = product.quantity ? product.quantity : 1;            
      
      newState = {
        ...state,
        itemMappings: newMappings,
        items: [
          ...state.items,
          product
        ],
      }

      localStorage.setItem('cart', JSON.stringify(newState));
      localStorage.removeItem('pricecomparison');
      
      return {
        ...newState             
      };
      
    case 'REMOVE_PRODUCT' :
      let updatedMappings = {
        ...state.itemMappings
      };
      
      delete updatedMappings[action.product._id];

      newState = {
        ...state,
        itemMappings: updatedMappings,
        items: state.items.filter(({ _id }) => _id !== action.product._id)
      }
      
      localStorage.setItem('cart', JSON.stringify(newState));
      localStorage.removeItem('pricecomparison');

      return {
        ...newState
      };

    case 'CLEAR_CART' :
      localStorage.removeItem('cart');
      localStorage.removeItem('pricecomparison');
      return {
        ...state,
        itemMappings: {},
        items: []
      };    

    case 'UPDATE_PRODUCT' : 
      const items = [...state.items];

      let index = 0;
      for (let p of items) {
        if (p._id === action.product._id) {
          break;
        }
        index++;
      }

      items[index] = action.product;

      newState = {
        ...state,
        items: items
      }
      
      localStorage.setItem('cart', JSON.stringify(newState));
      localStorage.removeItem('pricecomparison');
      
      return {
        ...state,
        items: items
      };
    default:
      return state;
  }  
};
