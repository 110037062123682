import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Slider from "@material-ui/core/Slider";
import Badge from "@material-ui/core/Badge";
import SearchBox from "../SearchBox";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";
import TableRowLoadMore from "../TableRowLoadMore";
import ReviewCoopPanel from "./PbsReviewInOnePage/ReviewCoopPanel";
import TotalBlock from "./PbsReviewInOnePage/TotalBlock";


const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.secondary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
  toolbarRight: {
    minHeight: "64px",
  },
  toolbarTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerFooter: {
    borderTop: "2px solid green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "18px",
    //paddingBottom: "px",
  },
  drawerFooterContainer: {
    height: "300px",
    width: drawerWidth,
    overflow: "auto",
  },
  drawerFooterImage: {
    width: 200,
    height: 200,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sliderContainer: {
    width: 300,
    paddingRight: 20,
  },
  totalBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: "64px",
    backgroundColor: theme.palette.secondary.main,
    // fontSize:"36px",
    color: "#FFF",
  },
  selectListGroup:{
    color: theme.palette.primary.main,
    // fontSize:"1.2rem",
    backgroundColor:"#fff",
    marginRight:"8px",
    '& div':{
      paddingLeft:"8px"
    }
  },
}));

export default function CoopMatchesPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(100);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("no");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  
  const [showAcceptedOnRight, setShowAcceptedOnRight] = useState(false);
  const totalBlock = React.createRef();
  const btnAcceptRef = React.createRef();
  const [categories, setCategories] = useState([]);
  const [list2MatchingStores, setList2MatchingStores] = useState([]);
  const [selectedList2MatchingStoreId, setSelectedList2MatchingStoreId] = useState(1); 

  const productId = props.match.params.id;
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // console.log('here'+searchText);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/list-coop?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        {
          q: searchText,
          categoryId: selectedCategoryId,
          list2MatchingStoreId: selectedList2MatchingStoreId,
          showAccepted: true,
        },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      console.log(result.data.data);
      if (skip === 0) {
        setGroups(result.data.data);
        // console.log('here 1xxx')
        // console.log()
        // console.log('here 1xxx')
        if (selectedGroup&&selectedGroup.id) {
          
          const found = result.data.data.some(
            (el) => el.id === selectedGroup.id
          );
          !found && setSelectedGroup(result.data.data[0]);
        } else {
          if(productId){
            const found = result.data.data.some(
              (el) => el.id === productId
            );
            !found && setSelectedGroup(result.data.data[0]);
          }else{
            setSelectedGroup(result.data.data[0]);
          }
        }
      } else {
        setGroups([...groups, ...result.data.data]);
      }
      setLoadMore(result.data.data.length === limit);
      setIsLoading(false);
    };

    fetchData();
  }, [
    searchText,
    selectedCategoryId,
    selectedList2MatchingStoreId,
    skip,
    order,
    orderBy,
   ]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
 
 useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/categories`;
      const result = await axios.get(url, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      });
      setCategories(result.data.data);
      // dispatch(setCategories(categories));
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchDataListGroup = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/test/list-store`;
      const result = await axios.get(url);
      setList2MatchingStores(result.data.data);
    };
    fetchDataListGroup();
  }, []);
  const handleCategoryChange = (event) => {
    setSelectedCategoryId(event.target.value);
    setSkip(0);
  };
  const handleSelectedGroupClick = (
    group,
    showAcceptedOnRightOption = false
  ) => {
    setSelectedGroup(group);
    setShowAcceptedOnRight(showAcceptedOnRightOption);
  };
  const handleList2MatchingStoreIdChange = (event) => {
    setSelectedList2MatchingStoreId(event.target.value);
    setSkip(0);
  };
  
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {/* {selectedGroup && selectedGroup.product && (
              <>
                {selectedGroup.product.fullname}
                {selectedGroup.product.size &&
                  `(${selectedGroup.product.size})`}
              </>
            )} */}
            Review the marked "wrong" products
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.totalBlock}>
          <Select className={classes.selectListGroup}  value={selectedList2MatchingStoreId} onChange={handleList2MatchingStoreIdChange} > 
              {list2MatchingStores.length > 0 &&
                list2MatchingStores.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
          </Select>
          
        </div>
        <div className={classes.toolbar}>
          {" "}
          <div className={classes.toolbarTop}>
            <Select value={selectedCategoryId} onChange={handleCategoryChange}>
              <MenuItem value="0">
                <em>All categories</em>
              </MenuItem>
              {categories.length > 0 &&
                categories.map((item) => (
                  <MenuItem value={item.id}>{item.description}</MenuItem>
                ))}
            </Select>
          </div>
          <SearchBox
            doSearch={doSearch}
            value={searchText}
            searchOnChange={false}
          />{" "}
        </div>
        <Divider />
        <div className={classes.drawerContainer}>
          <List className={classes.listProducts}>
            {groups.length > 0 &&
              groups.map((group, index) => (
                <ListItem
                  button
                  key={group.id}
                  selected={
                    selectedGroup ? group.id === selectedGroup.id : false
                  }
                  onClick={() => handleSelectedGroupClick(group, false)}
                >
                  <ListItemIcon>
                    <img
                      className={classes.productImage}
                      alt={group.product.name}
                      src={
                        group.product.image_url
                          ? group.product.image_url
                          : "/resources/images/no-picture-available.png"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${group.no}.${group.product.fullname}`}
                    secondary={`${group.product.size} #${group.product_upc}`}
                  />
                  <ListItemSecondaryAction>
                    {!group.accept_count && (
                      <Chip label={group.matched_count} color="primary" />
                    )}
                    {group.accept_count > 0 && (
                      <Badge color="primary" badgeContent={group.accept_count}>
                        <Chip label={group.matched_count} color="secondary" />
                      </Badge>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          {loadMore && <TableRowLoadMore doLoadMore={doLoadMore} colSpan={4} />}
        </div>        
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbarRight} />
        <div className={classes.reviewPanel}>
          {selectedGroup && (
            <ReviewCoopPanel
              selectedGroup={selectedGroup}
              showAcceptedList={showAcceptedOnRight}
            />
          )}
        </div>
      </main>
    </div>
  );
}
