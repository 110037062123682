import React, { useState } from "react";
import {  useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import LocationOn from "@material-ui/icons/PinDrop";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import StoreLogoOnline from "../../StoreLogo/StoreLogoOnline";
import StoreOnMapDialog from "./DialogFull";
import ProductStockRow from "./ProductStockRow";

const useStyles = makeStyles((theme) => ({
  storeDetailContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //background: "#334455",
    maxWidth: "750px",
    paddingBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: "14px",
    },
  },
  storeTextContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px",
    // }    
  },
  storeButtonsContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  storeText: {
    fontWeight: "500",
    fontFamily: "Montserrat",
    fontSize: "21px",
    // lineHeight: "13px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "13px",
    },
    // overflow:"hidden"
  },
  storeLink: {
    // fontWeight: "bold",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    textTransform: "none",
    margin: "4px",
    // '&:hover': {
    //     color: '#fff'
    // }
  },
  storeLinkContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    //paddingLeft: theme.spacing(1),
  },
  storeDetailProductListTable: {
    width: "100%",
    padding: "2px 4px",    
    "& tr>td":{
      paddingTop:"2px",
      paddingBottom:"2px",
      borderBottom:"1px solid #DDD"
    }
  },
  logo: {
    height: "36px",
    width: "auto",
    marginBottom: "-4px",
    [theme.breakpoints.down("sm")]: {
      height: "24px",
      marginBottom: "-4px",
    },
    // marginRight: "-24px",
  },
  iconPin:{
    height: "36px",   
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "24px",     
    },
  }
}));
export default function StoreDetail(props) {
  // const [products, setProducts] = useState([]);
  const history = useHistory();
  const {
    store: { storeDetails, products },
  } = props;
  const company = { name: storeDetails.companyName, companyLogoLink:storeDetails.companyLogoLink };

  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  };
  const goHomePage = () => {
    //push this store in to stores
    const stores = JSON.parse(localStorage.getItem("stores")) || [];
    //console.log(stores.length);
    if (stores.length>0) {
      history.push("home");
    } else {
      history.push("stores");
    }
    //redirect to home
  };
  return (
    <div className={classes.storeDetailContainer}>
      <StoreLogoOnline company={company} />
      <div className={classes.storeTextContainer}>
        <Typography className={classes.storeText} noWrap={true}>
          {Math.round(storeDetails.distance * 100) / 100}km -{" "}
          {storeDetails.storeName}
        </Typography>
        <div className={classes.storeButtonsContainer}>
          <Button onClick={handleOnOpen} className={classes.storeLink}>
            <div className={classes.storeLinkContainer}>
              <LocationOn className={classes.iconPin} />
              <Typography noWrap={true} className={classes.storeText}>
                Map It
              </Typography>
            </div>
          </Button>
          <Button onClick={goHomePage} className={classes.storeLink}>
            <div className={classes.storeLinkContainer}>
              <Typography className={classes.logoContainer}>
                <img
                  alt={company.name}
                  src="./resources/images/Better-Cart-Logo---just-cart---square---nobg@3x.png"
                  className={classes.logo}
                />
              </Typography>
              <Typography noWrap={true} className={classes.storeText}>
                View on BetterCart
              </Typography>
            </div>
          </Button>
        </div>
      </div>
      <table className={classes.storeDetailProductListTable}>
        <tbody>
        {/* {console.log(products.map(product=>"true"))} */}
          { products.map((product) => (
            <ProductStockRow
              key={product.id}
              productID={product.id}
              product={product}
              storeID={storeDetails.storeID}
            />
          ))} 
        </tbody>
      </table>
      <StoreOnMapDialog
        open={isDialogOpen}
        onClose={handleOnClose}
        store={storeDetails}
      />
    </div>
  );
}
