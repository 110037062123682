import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Utility } from "../../../../utility/utility";
import { setRmtRefresh } from "../../../../actions/session";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";

import ReviewItem from "./ReviewItem";
import ReviewItemBlank from "./ReviewItemBlank";
import SimilarButton from "./SimilarButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  rootLeft: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    backgroundColor: theme.palette.secondary.main,
    // paddingTop: "64px",
    // width:"50%"
  },
  rootRight: {
    display: "flex",
  },

  acceptContainer: {
    backgroundColor: "#DDD",
  },
  allWrongButton:{
    color: '#fff',
    backgroundColor: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:active': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:focus': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
  },
  title: {
    paddingLeft: '10px',
    paddingRight: '50px',
    fontSize: '20px',
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },

}));

export default function ReviewPanel({
  selectedProjectId,
  similarTypes
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const dispatch = useDispatch();
  const { rmtRefresh } = useSelector(
    (state) => state.session
  );
  const [includeProductsFromSameCompany, setIncludeProductsFromSameCompany] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/pick-data`;
      const result = await axios.post(
        url,
        {
          projectId: selectedProjectId,
          includeProductsFromSameCompany: includeProductsFromSameCompany
        },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      setProduct({
        sim_name:result.data.data.sim_name,
        original:result.data.data.original,
        potential: result.data.data.potential
      });
      
      // console.log(result)
      setIsLoading(false);     
    };
    fetchData();
  }, [rmtRefresh,selectedProjectId,includeProductsFromSameCompany]);
  const handleGoNext = () => {
    // setCount2Next(count2Next+1)
    dispatch(setRmtRefresh());
    
  };
  const handleSkipItClick = (e)=>{ 
      // setIsLoading(true);     
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/skip-it`;
        const postData = {
          product_matched_id: product.potential.id,
          product_id: product.original.id,
          project_id: selectedProjectId,
          sim_name:product.sim_name,
          simSource: '_ve',
        };
        // console.log(postData);
        axios
          .post(url, postData, {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          })
          .then(() => {          
            handleGoNext();
          })
          .catch((err) => {
            console.log(err);
          });
        
  }
  const handleSimilarClick = (e)=>{ 
    // setIsLoading(true);     
    
      
}  
const handleNotAMatchLocal = () => {
  const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/doesnot-match`;
  const postData = {
    product_matched_ids: [product.potential.id],
    product_id: product.original.id,
    project_id: selectedProjectId,
    sim_name:product.sim_name,          
    simSource: '_ve',
  };
  axios
    .post(url, postData, {
      headers: {
        Authorization: JSON.stringify(store.getState().session.token),
      },
    })
    .then((response) => {
        handleGoNext();    
    })
    .catch((err) => {
      console.log(err);
    });
  //  onRemove&&onRemove(productMatch.id);
};
const handleMatchesLocal = () => {
  const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/matches`;
  if (product.original.id && product.potential.id > 0) {
    const postData = {
      product_matched_id: product.potential.id,
      product_id: product.original.id,
      project_id: selectedProjectId,
      sim_name:product.sim_name,          
      simSource: '_ve',
    };
    // console.log(postData);
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {       
        handleGoNext();  
      })
      .catch((err) => {
        console.log(err);
      });
    //  onRemove&&onRemove(productMatch.id);
  }
};
  return (
    <>
        {/* { console.log(product)} */}
          
          <Grid
            className={classes.root}
            container
            spacing={0}
            // alignItems="center"
            // justifyContent="center"
          >
            <Grid item xs={12} md={12} >
              <Typography className={classes.title}  noWrap>
               Similarity: {isLoading?<CircularProgress size={16} />:Utility.roundToNDecimal(product.sim_name,8)+'   ---   '}
               
               <FormControlLabel
                control={
                  <Checkbox
                    checked={includeProductsFromSameCompany}
                    onChange={() => setIncludeProductsFromSameCompany(!includeProductsFromSameCompany)}
                    name="Include Products From Same Company"
                    color="secondary"
                  />
                }
                title="Include Products From Same Company"
                label="Include products from same company"
              />
              </Typography>
              
            </Grid>
             {isLoading &&(
              <ReviewItemBlank/>
              )}
            {(!isLoading) &&product && product.original && 
              <ReviewItem
                  key={'original'}
                  productMatch={product.original}
                  groupId={product.original.product_matching_group_id}
                  simSource={'_ve'}
                  // handleGoNext={handleGoNext}
              />
            }
            {isLoading &&(
            <ReviewItemBlank/>
            )}
            { (!isLoading)&&product && product.potential&&             
                    <ReviewItem
                      key={'potential'}
                      productMatch={product.potential}
                      groupId={product.original.product_matching_group_id}
                      simSource={'_ve'}
                    />
            }
            {product.sim_name&&
            <Grid
                 container
                 spacing={0}
                 alignItems="flex-end"
                 justifyContent="flex-start"
                 className={classes.acceptContainer}
              >
                
                <div style={{ padding: "20px" }}>
                  <Button
                    // ref={btnAllWrongRef}
                    variant="contained"
                    color="primary"
                    onClick={handleSkipItClick}
                    disabled={isLoading}
                    title="Skip it"
                  >
                    Skip it
                  </Button>
                </div>
                  
                  <div style={{ padding: "20px 0px" }}>
                  <Button
                    variant="contained"
                    className={classes.allWrongButton}
                    disabled={isLoading}
                    onClick={handleNotAMatchLocal}
                    title="Doesn't match"
                  >
                    Doesn't match
                  </Button>
                </div>
                <div style={{ padding: "20px" }}>
                  <Button
                    // ref={btnAcceptRef}
                    variant="contained"
                    color="secondary"
                    // size="small"
                    // className={classes.removeButton}
                    disabled={isLoading}
                    onClick={handleMatchesLocal}
                    title="Matches"
                  >
                    Matches
                  </Button>
                  </div>                  
                  <div style={{ padding: "20px" }}>
                  <SimilarButton similarTypes={similarTypes} selectedProjectId={selectedProjectId} product={product} handleGoNext={handleGoNext} />
                  </div>
              </Grid>
              }
          </Grid>
          
          
        </>
   
  );
}
