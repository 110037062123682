const searchFiltersDefaultState = {
  filteredStores: [],
  proximity: 35
}

export default (state = searchFiltersDefaultState, action) => {
  switch (action.type) {
    case 'SET_FILTERED_STORES' :
      // const stores = action.filteredStores.map((store) => store._id);
      // localStorage.setItem('stores', stores.join(','));
      localStorage.setItem('stores', JSON.stringify(action.filteredStores))
      // localStorage.removeItem('pricecomparison');
      return {
        ...state,
        filteredStores: action.filteredStores
      }

    case 'SET_PROXIMITY' :
    // console.log('set proximity');
      localStorage.setItem('proximity', action.proximity)
      return {
        ...state,
        proximity: action.proximity
      }
    default:
      return state
  }
}
