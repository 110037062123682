import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDispatch, useSelector } from "react-redux";
import {
    setCategories
  } from "../../actions/categories";
  import {
    searchProduct,
    clearSearchHistory,
  } from "../../actions/products";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: '2px 4px',
    display: "flex",
    alignItems: "center",
    // marginBottom:'8px',
    backgroundColor: "#f6f6f6",
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: "4px",
    
  },
  searchIcon: {
    display:"flex",
    height: "100%",
    position: "absolute",
    alignItems: "center",
    minWidth: "64px",
    justifyContent: "center",
    zIndex: "1000",
    top: "0px",
    right: "0px",
    backgroundColor: "#2650a2",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    color: "#fff",
  },
  searchButtonText: {
    fontSize: "1.8rem",
    fontWeight: "bold",    
    color: "#fff !important",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    fontSize:"1.3rem",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem"           
    },
  },  
  categoryContainer:{
    display:'flex',
  },
  iconButtonCategoryMenu: {
    padding: 8,
    color: theme.palette.primary.main,
    display: "none", 
    [theme.breakpoints.down("sm")]: {
      display: "block"           
    },
  },
  buttonCategoryMenu:{
    whiteSpace: 'nowrap',
    fontSize:"1.3rem",
    lineHeight: "1.5rem",
    textTransform: 'capitalize',
    [theme.breakpoints.down("sm")]: {
      display: "none"           
    },
  },
  divider: {
    height: 28,
    margin: 4,
    marginRight: '10px'
  },
}));


export default function ProductSearchBox(props) {
  const classes = useStyles();
  const categories = useSelector(state => state.categories.categories);
  const selectedCategory = categories&&categories.find(category=>category.selected);
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenCategoryMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCategoryMenu = (id) => {
    setAnchorEl(null);
    const categoriesTemp = categories.map(category=>{
        if(category.id===id){
            category.selected = true;
        }else{
          category.selected = false;
        }
        return category;
    });
    dispatch(setCategories(categoriesTemp));
    handleClick();
  };

  const [searchValue, setSearchValue] = useState('');
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleClear = () => {
    setSearchValue("");
    dispatch(clearSearchHistory());
  };
 
  
  const keyPressed = ({ key }) => {
    if (key === "Enter") {
      handleClick();
    }
  };
  const handleClick=()=>{
      searchValue&&dispatch(searchProduct(searchValue,selectedCategory?selectedCategory.description:'All Categories'));      
  }
  
  return (
    <>
    <div className={classes.container}>
      <div className={classes.categoryContainer}>
        <IconButton className={classes.iconButtonCategoryMenu} aria-label="menu" onClick={handleOpenCategoryMenu}>
          <MenuIcon />
        </IconButton>
        
          <Button color="primary" className={classes.buttonCategoryMenu} onClick={handleOpenCategoryMenu}
           endIcon={<ExpandMoreIcon/>}
          >{selectedCategory?selectedCategory.description:'All Categories'}</Button>
          <Divider className={classes.divider} orientation="vertical" />
        
      </div>
      <InputBase
        className={classes.inputRoot}
        placeholder={"Search for a product"}
        onChange={handleChange}
        value={searchValue}
        // defaultValue={value}
        onKeyPress={keyPressed}
        inputProps={{ "aria-label": "search" }}
        autoFocus={true}
      />
      <div className={classes.searchIcon}>
        {searchValue.length > 0 ? (
          <IconButton
            onClick={handleClear}
            className={classes.iconButton}
            aria-label="clear"
          >
            <ClearIcon className={classes.searchButtonText}/>
          </IconButton>
        ) : (
          <IconButton className={classes.iconButton} aria-label="search" onClick={handleClick}>
            <SearchIcon className={classes.searchButtonText}/>
          </IconButton>
        )}
      </div>
    </div>
    <Menu
        id="category-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseCategoryMenu}
      >
        <MenuItem onClick={handleCloseCategoryMenu}>All Categories</MenuItem>
        {categories&&categories.map((category)=>
          <MenuItem selected={category.selected} onClick={()=>handleCloseCategoryMenu(category.id)}>{category.description}</MenuItem>
        )}
    </Menu>
    </>
  )   
}

