import axios from 'axios';
import envConfig from '../bin/env.config';

export const setLoading = (isLoading = true) => ({
  type: 'SET_LOADING',
  isLoading
});

export const setErrorStatus = (errorOccurred = true) => ({
  type: 'SET_ERROR_OCCURRED',
  errorOccurred
});

export const setSelectedStoreCart = (selectedStoreCart) => ({
  type: 'SET_SELECTED_STORE_CART',
  selectedStoreCart: selectedStoreCart
});

export const setProducts = (result = []) => ({
  type: 'SET_PRODUCTS',
  products: result
});

export const loadPriceComparisonResultFromLocal = (data = []) => ({    
    type: 'LOAD_PRICE_COMPARISON_RESULT_FROM_LOCAL',
    data: data
});

export const setPriceComparisonResult = (result = []) => {
  return (dispatch, getState) => {
    const state = getState();
    const cartItems = state.cart.items;

    dispatch({
      type: 'SET_PRICE_COMPARISON_RESULT',
      priceComparisonResult: result,
      cart: cartItems
    });
  };
};

export const updateProductPick = (product, store, row, col) => ({
  type: 'UPDATE_PRODUCT_PICK',
  product: product,
  store: store,
  row: row,
  col: col
});

export const startPriceCompare = (params) => {
  return (dispatch, getState) => {
    // console.log('start price compare');
    dispatch(setLoading(true));
    // dispatch(setErrorStatus(false));
    // dispatch(setPriceComparisonResult([]));

    // const url = `http://localhost:8000/api/products/pricecompare`;

    const state = getState();
    const proximity = state.searchfilters.proximity;
    const filteredStores = state.searchfilters.filteredStores;
    params.proximity = proximity;

    if (filteredStores && filteredStores.length > 0) {
      const stores = filteredStores.map((store) => store._id);        
      params.stores = stores.join(',');
      //params.stores = filteredStores.map((store) => store._id);
    }



    // const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/pricecompare-es`;
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/pricecompare-matching`;
    //const url = `${envConfig.API_ROOT}/products/pricecompare`;
    axios.post(url, 
      params
      // ,
      // {
      //   headers: {
      //     'Authorization': JSON.stringify(getState().session.token)
      //   }
      // }
      )
      .then((response) => {
        const data = response.data.data;
        // setTimeout(() => {
          // dispatch(setLoading(false));
          dispatch(setPriceComparisonResult(data));
        // }, 1000);
      })
      .catch((err) => {
        // dispatch(setLoading(false));
        dispatch(setErrorStatus(true));
      });
  };
}
