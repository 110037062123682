import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "30px 20px",
  },
  content: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  contentText1: {
    fontSize: "25px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },

  signImgContainer: {
    //paddingTop: "16px",
  },
  signImg: {
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(14),
    },
  },
  contentText2: {
    fontSize: "30px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },

  logoImgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "15px",
    //height: "84px",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "18px",
    // },
  },
  logoImg: {
    height: "150px",
    [theme.breakpoints.down("sm")]: {
      height: "84px",
    },
  },

  buttonCustom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "4px 20px",
    // width: "70px",
    height: "78px",

    border: "solid 1px",
    backgroundColor: "#fff",
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    borderRadius: "4px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#FFF",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
     
      "& $buttonCustomText": {
        color: "#FFF",
      }
    },
    [theme.breakpoints.down("sm")]: {
      height: "56px",
      padding: "4px 10px",
    },
  },
  buttonCustomText: {
    flex:"inline-flex",
    fontSize: "27px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    textAlign: "center",
    // padding: "4px 20px",
    textDecoration: "none",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
   
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: "12px",
    // },
  },
}));

export default function ProductStatusPageBottom(props) {
  const classes = useStyles();
  const history = useHistory();
  const goToBetterCart = () => {
    // const stores = props.stores;
    //push this store in to stores
    const stores = JSON.parse(localStorage.getItem("stores")) || [];
    if (stores.length>0) {
      history.push("home");
    } else {
      history.push("stores");
    }
    // and redirect to hom
    // history.push("/stores");
  };
  return (
    <div className={classes.root}>
      <div className={classes.buttonCustom} >
        <a
          className={classes.buttonCustomText}
          href="mailto:support@bettercart.ca?subject=Product tracking suggestion!"
        >
          SHOULD WE BE TRACKING ANOTHER PRODUCT? LET US KNOW!
        </a>
      </div>
      <div className={classes.content} >
        <Typography className={classes.contentText2}>
          Don’t overspend on your grocery cart!
        </Typography>     
        <Typography className={classes.contentText1}>
          We compare grocery prices, and help reduce your grocery bill.
        </Typography>
      </div>
      <Typography className={classes.logoImgContainer}>
        <img
          src="./resources/images/Better-Cart-Logo---just-cart---square@3x.png"
          className={classes.logoImg}
        />
      </Typography>
      {/* <Button
        className={classes.buttonCustom}
        onClick={goToBetterCart}
        //disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant="contained"
        color="secondary"
        disableElevation
      >
        <Typography className={classes.buttonCustomText} noWrap={true}>
        FIND MORE PRODUCTS ON BETTERCART
        </Typography>
      </Button> */}
      <div className={classes.buttonCustom} onClick={goToBetterCart}>
        <Typography className={classes.buttonCustomText}>
          FIND MORE PRODUCTS ON BETTERCART
        </Typography>
      </div>
    </div>
  );
}
