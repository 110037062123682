import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

export default function ProjectProductMatchingInfo({open,project}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSummary, setDataSummary] = useState({
          total_matching: 0, //total need check/ matching
          total_checked: 0, //total accepted, wrong-all
          total_leave_it: 0, //total leave-it          
        }
  );
  useEffect(() => {
    
    const getData = async () => {
      setIsLoading(true);
      let putData = {
         project_id:project.id,
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-matching-summary`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setDataSummary(result.data.data);
      setIsLoading(false);
    };
    project&&open&&getData();
  }, [project,open]);
  return (
    <>
    {isLoading ? `Loading ...` : (
      
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Total products in the matching tool:</TableCell><TableCell>{dataSummary.total_matching}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Total checked:</TableCell><TableCell>{dataSummary.total_checked}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Total leave-it:</TableCell><TableCell>{dataSummary.total_leave_it}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )}
  </>  
  );
}
