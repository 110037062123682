import React, { useState, useEffect } from "react"; 
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import ButtonEdit from "./Session/ButtonEdit";
import ButtonAddItem from "./Item/ButtonEdit";
import ButtonDelete from "./Session/ButtonDelete";
import {
    IconButton,
  } from "@material-ui/core";
  const useStyles = makeStyles((theme) => ({
          iconButton:{
          padding:0,
          paddingLeft:'.5rem'
      }
    }))
export default function SessionTitle({session, editMode, reloadData}){
    const classes = useStyles();
    return(
        <>
            {session.name}
            {editMode&&session&&(
                <>
                  <ButtonEdit session={session} reloadData={reloadData}/>
                  <ButtonDelete session={session} reloadData={reloadData}/>
                  <ButtonAddItem item={null} session={session} reloadData={reloadData}/>
                </>
            )}  
        </>            
    )
}