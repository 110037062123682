import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

export default function ProjectProductMatchingAssignedDoingStatusInfo({open,project,user_id}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSummary, setDataSummary] = useState({
          accepted: 0, 
          wrong_all: 0,
          leave_it: 0, 
        }
  );
  useEffect(() => {    
    const getData = async () => {
      setIsLoading(true);
      let putData = {
         project_id:project.id,
         user_id:user_id
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-assigned-doing-summary`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setDataSummary(result.data.data);
      setIsLoading(false);
    };
    project&&open&&getData();
  }, [project,user_id, open]);
  return (
    <>
    {isLoading ? `Loading ...` : (      
      <ul style={{padding:'0px',margin:'0px'}}>
        <li>Accepted:<span style={{color:'green'}}>{dataSummary.accepted}</span></li>
        <li>Wrong all:<span style={{color:'red'}}>{dataSummary.wrong_all}</span></li>
        <li>Leave it:<span style={{color:'blue'}}>{dataSummary.leave_it}</span></li>
      </ul>
    )}
  </>  
  );
}
