import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Cart from '../Cart/Cart';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Badge from '@material-ui/core/Badge';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import Fab from '@material-ui/core/Fab';
import Skeleton from 'react-loading-skeleton';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

let drawerWidth = 240;

const styles = theme => ({ 
  badge: {
    top: '12px',
    left: '-20px',
    right: 'auto',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
  },
  fab: {
    top: 'auto',
    right: 20,
    left: 'auto',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
    // ,
    // height: '80px'
  },
  shoppingCartButton: {
    height: theme.spacing(5.5),
    width:'230px',
    fontSize: '13px',
    marginLeft: '5px',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      width: '55px',
      height: theme.spacing(6),
      display: 'flex',
      flexDirection: 'column'
    },        
  },
  shoppingListText: {
    fontFamily: 'Montserrat',
    fontWeight:"600",
    paddingLeft: "5px",
    fontSize: "1.2rem"
  },
});

class CartButton extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isCartDrawerOpen: false
    };
  }

  openCartDrawer = () => {
    this.setState({ isCartDrawerOpen: true });
  }

  closeCartDrawer = () => {
    this.setState({ isCartDrawerOpen: false });
  }

  defaultButton = () => {
    const { cart, classes, fixedFab } = this.props;
    return (
      <Fab 
        color="primary" 
        className={ fixedFab ? classes.fab : classes.shoppingCartButton }
        aria-label="cart"
        size="large"
        variant="extended"
        onClick={this.openCartDrawer}>
        {
          cart.length > 0 ?
          (
            <Badge 
              badgeContent={cart.length} 
              color="error" 
              classes={{ badge: classes.badge }}>
              <ShoppingCart className={ classes.shopppingCartWithBadge } />
            </Badge>
          ) :
          (
            <ShoppingCartOutlined
              onClick={this.openCartDrawer}
             />
          )
        }
        <Hidden smDown><Typography className={classes.shoppingListText}>&nbsp;My cart</Typography></Hidden>                 
      </Fab>
    );
  }

  renderButton = () => {
    if (this.props.editButton) {
      return <Button 
        size="small"
        fullWidth={true}
        onClick={this.openCartDrawer} 
        variant="outlined">Edit
      </Button>;
    } else {
      return this.defaultButton()
    }
  }

  render () {
    const { classes, products, editButton, customSeeMySavingsHandlerFn, anchor } = this.props;
    return(
      <div>
        {
          products.isLoadingTodaysDeals ? <Skeleton width={80} className={ classes.cartButton } /> : this.renderButton()
        }
        <SwipeableDrawer
          anchor={ anchor ? anchor : 'right' }
          open={this.state.isCartDrawerOpen}
          onOpen={() => {}}
          onClose={this.closeCartDrawer}
        >
          <Cart
            customSeeMySavingsHandlerFn={ customSeeMySavingsHandlerFn }
            hideRemoveButtons= { false }
            closeCartDrawer={this.closeCartDrawer}
          />
        </SwipeableDrawer>
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  products: state.products
});

const mapDispatchToProps = (dispatch, props) => ({
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CartButton));
