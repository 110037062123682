import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@material-ui/core';
import axios from "axios";
import envConfig from "../../../../bin/env.config";

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  input: {
    width:'100%',
    backgroundColor: "rgba(255,255,0,0.3)",
    padding:'2px 8px 2px 8px',
    fontSize:'0.875rem'
  }, 
}));

export default function EditNoteTextBox(props) {
  const classes = useStyles();
  const { productId, note} = props;
  const [inputValue, setInputValue] = useState(note)
  const token = useSelector((state) => state.session.token);

  const onChange = (event) => {
    //Update data
    setInputValue(event.target.value)
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/note`;
    axios.post(
      url,
      { 
        productId: productId,
        note: event.target.value
      },
      {
        headers: {
          Authorization: JSON.stringify(token),
        },
      }
    );    
  };
  return (
    <InputBase
        className={classes.input}
        placeholder={`Note`}
        value={inputValue}
        onChange={onChange}
    />
  );
}
