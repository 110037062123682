import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
// import AddIcon from '@material-ui/icons/Add';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
 
import { fetchUserProfile, removeFavoriteProduct } from '../../actions/session';
import { startAddProductToCart } from '../../actions/cart';

import ErrorMessage from '../Error/ErrorMessage';
import axios from "axios";
import envConfig from "../../bin/env.config";

import ReactGA from 'react-ga';

import Heading from "../Heading/Heading";

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
  // title: {
  //   marginLeft: theme.spacing(3),
  //   marginBottom: theme.spacing(2)
  // },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "28px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  progressSpinner: {
    marginTop: theme.spacing(2)
  }
});

class FavoriteProducts extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isProductAddSuccessShowing: false
    }
    ReactGA.pageview("Favorite Products");
  }

  componentDidMount () {
    // console.log(this.props.cart);
    // console.log(this.props.session.user);

    this.props.fetchUserProfile();
  }

  showProducAddToCartSuccess = () => {
    this.setState({
      isProductAddSuccessShowing: true
    });
  }

  hideProductAddToCartSuccess = () => {
    this.setState({
      isProductAddSuccessShowing: false
    });
  }

  onBack = () => {
    this.props.history.push('/home');
  }
  getProductOnStore = (product)=>{
    //Get product from axios put into cart
    let productOnStore = {};
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/${product.productID}/stores/${product.storeId}`;
   
    axios
      .get(url)
      .then((response) => {
        productOnStore =  response.data.product;
      })
      .catch((err) => {});
    return productOnStore;
  }

  onAddProductToCart = (product) => {
   
    this.props.startAddProductToCart(product);
    this.showProducAddToCartSuccess();
  }

  onRemoveProductFromFavorites = (product) => {
    this.props.removeFavoriteProduct(product);
  }

  isProductInTheCart = (product) => {
    //#bug -- need check to update after api is completed
    // console.log(this.props.cart);
    // console.log(product)
    return typeof this.props.cart.itemMappings[product._id] === 'object';
  }

  renderLoadedState = () => {
    const { classes, session } = this.props;
    return (
      <div>
        <Heading page={"favorite"}/>
           
        {
          session.user.favoriteProducts.length === 0 && (
            <div>
              <br />
              <Typography align="center" variant="h5" className={ classes.title }>
                No products to show. 
              </Typography>
              <Typography align="center" variant="h6" className={ classes.title }>            
                You can save a favourite by clicking on the heart icon beside any product.
              </Typography>            
            </div>
          )
        }

        {
          session.user.favoriteProducts.length > 0 && (
            <div>
              <Typography variant="caption" className={ classes.title }>
                Add your favorite products to your existing cart
              </Typography>
              <Paper>
                <List> 
                  {
                    session.user.favoriteProducts.map((product) => {
                      return (
                        <ListItem key={ product._id }>
                          <ListItemText
                            primary={ product.name }
                            secondary={ product.description }
                          >
                          </ListItemText>

                          <ListItemSecondaryAction>
                            <IconButton
                              disabled={ this.isProductInTheCart(product) }
                              onClick={ () => { this.onAddProductToCart(product) } }
                            >
                              <AddShoppingCart/>
                            </IconButton>
                            <IconButton
                              onClick={ () => { this.onRemoveProductFromFavorites(product) } }
                              >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>

                        </ListItem>
                      )
                    })
                  }
                </List> 
              </Paper>
            </div>
          )
        }

      </div>
    );
  }

  render () {
    const { classes, user, session } = this.props;

    return (
      <div>
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton color="inherit" onClick={this.onBack} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="title" color="inherit">
              Your favorite products
            </Typography>
          </Toolbar>
        </AppBar>

        <Grid container justifyContent="center" className={ classes.root }>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            {
              user.isLoadingUserProfile && (
                <Typography align="center" className={ classes.progressSpinner }>
                  <CircularProgress />
                </Typography>
              )
            }
            
            {
              (!user.isLoadingUserProfile && !user.errorOccurredLoadingUserProfile) && this.renderLoadedState()
            }

            {
              user.errorOccurredLoadingUserProfile && <ErrorMessage />
            }
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.isProductAddSuccessShowing}
          autoHideDuration={3000}
          onClose={this.hideProductAddToCartSuccess}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Product has been added to your list</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.hideProductAddToCartSuccess}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    )

  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
  user: state.user,
  cart: state.cart
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchUserProfile: () => dispatch(fetchUserProfile()),
  startAddProductToCart: (product) => dispatch(startAddProductToCart(product)),
  removeFavoriteProduct: (product) => dispatch(removeFavoriteProduct(product))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoriteProducts)));