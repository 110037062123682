import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutline from "@material-ui/icons/RemoveCircleOutline";
import DialogConfirm from "./DialogConfirm";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonRemoveBProduct({ item, removeItem }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
  const doRemoveItem=()=>{
    setIsDialogOpen(false);
    removeItem&&removeItem()
  }
  return (
    <>
      {item&&(
        <IconButton onClick={handleOnOpen} title={item.productMatchingGroup.id+' - '+item.productMatchingGroup.fullname}>
          <RemoveCircleOutline/>
        </IconButton>      
      )}      
      <DialogConfirm
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        id = {item.id}
        reloadData={doRemoveItem}
      />
    </>
  );
}
