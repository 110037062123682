const suggestionsDefaultState = {
  searchString: '',
  suggestions: []
};

export default (state = suggestionsDefaultState, action) => {
  switch (action.type) {
    case 'SET_SUGGESTIONS_SEARCH_STRING' :      
      return {
        ...state,
        searchString: action.searchString
      };
    case 'SET_SUGGESTIONS' :
      return {
        ...state,
        suggestions: action.suggestions
      };
    case 'CLEAR_SUGGESTIONS' :
      return {
        ...state,
        suggestions: []
      };
    default:
      return state;
  }
};
