import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import LocationPicker from "../LocationPicker/LocationPicker";
import WhatInStockBanner from "./help/WhatInStockBanner";
import LearnMoreAboutProjectFooter from "./help/LearnMoreAboutProjectFooter";

import { setLocationAndUpdateStoresNearby } from "../../actions/location";
import AppBarBackButton from "./help/AppBarBackButton";

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },  
  contentText1: {
    maxWidth:"750px",
    textAlign:"center",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    padding: "30px",
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "23px",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  contentText2: {
    maxWidth:"750px",
    textAlign:"center",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "29px",
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      // width: "260px",
      fontSize: "18px",
      lineHeight: "22px",
    },
    // width: "450px",
    color: theme.palette.secondary.main,
  },
  imgContainer: {
    paddingTop: "30px",
  },
  heartImg: {
    height: "auto",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
    },
  },
});

class LocationPickerPage extends Component {
  state = {
    isLocationPickerOpen: false,
    open: true,
  };

  constructor(props) {
    super(props);
  }

  openLocationPickerModal = () => {
    this.setState({ isLocationPickerOpen: true });
  };

  // closeLocationPickerModal = () => {
  //   this.setState({ isLocationPickerOpen: false });
  // };

  onLocationPick = () => {
    this.setState({
      isLocationPickerOpen: false,
    });
  };

  changeLocation = () => {
    this.setState({
      isLocationPickerOpen: true,
    });
  };

  goStorePickerPage = () => {
    //console.log(this.props.location);
    if (!!this.props.location.postal) {
      // this.props.goNextPage();
      this.props.history.push("product-status");
    } else {
      this.setState({
        errorOccurred: true,
      });
    }
  };
 

  render() {
    const { classes, location } = this.props;

    return (
      <div className={classes.root}>
        <AppBarBackButton />
        <WhatInStockBanner />
        <Typography className={classes.imgContainer}>
          <img
            src="./resources/images/Canadian-Heart.png"
            className={classes.heartImg}
          />
        </Typography>
        <Typography className={classes.contentText2}>
          Start by entering your postal code and we'll find your local stores:
        </Typography>
        <div className={classes.locationPicker}>
          <LocationPicker
            onLocationPick={this.onLocationPick}
            goNextPage={this.goStorePickerPage}
            buttonText={"GO!"}
            hideDistanceChose={true}
          />
        </div>
        <Typography className={classes.contentText1}>
          After you shop, please take a moment to help others by updating your
          local stock levels.
        </Typography>

        <LearnMoreAboutProjectFooter />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  location: state.location,
  session: state.session,
});
const mapDispatchToProps = (dispatch, props) => ({
  setLocationAndUpdateStoresNearby: (postal, latitude, longitude) =>
    dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude)),
  dispatch: (params) => dispatch(params),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocationPickerPage)
);
