import React,{useEffect,useState} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CitiesDropDownBox from './CitiesDropDownBox'
import ChainDropDownBox from './ChainDropDownBox'
import StatusDropDownBox from './StatusDropDownBox'
const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.secondary.main,
  },
  button: {    
    marginRight:'8px',
  },
  textbox: {
    marginRight:'20px',
    marginTop:'8px',
    width:'200px'
  }, 
  textbox1: {
    marginRight:'20px',
    width:'80px'
  }, 
}));

export default function FilterBox({dataStatus,doSearch}) {
  const classes = useStyles();
  const history = useHistory();
  const [inputState, setInputState] = useState({q: "", city: "saskatoon",status:1,company_id:0});
  
  const handleInputTypingChange = (event) => {    
    // setInputState({
    //   ...inputState,
    //   [event.target.name]: event.target.value,
    // }); 
    handleChange(event.target.name,event.target.value)
  };
  const handleChange = (name, value) =>{    
    setInputState({
      ...inputState,
      [name]: value,
    });   
  }
  // useEffect(() => {
  //   // const delayDebounceFn = setTimeout(() => {
  //      doSearch(inputState)      
  // // return () => clearTimeout(delayDebounceFn)
  // }, [inputState]);
  const onSearchClick = () => {
    doSearch(inputState)
  };
  const openSummaryReport = () => {
    history.push(`/matching/ood-summary-report`);
  };
  return (
    <><CitiesDropDownBox
        onChange={handleChange}
      />
      <ChainDropDownBox
        onChange={handleChange}
      />
           
      <StatusDropDownBox
        dataStatus={dataStatus}
        onChange={handleChange}
      />
     <TextField
        label="Product's name"
        name="q"
        variant="outlined"
        size="small"
        onChange={handleInputTypingChange}
        value={inputState.q}
        margin="normal"
        className={classes.textbox}
      />
       <Button
          color="primary"
          variant="contained"
          onClick={onSearchClick}
        >
          Search
        </Button>
&nbsp;
         <Button
          color="secondary"
          variant="contained"
          onClick={openSummaryReport}
        >
          Summary Report
        </Button>                
    </>
  );
}
