import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import SummaryReportFilterBox from "./SummaryReportFilterBox";
import SummaryReportCity from "./SummaryReportCity";
import SummaryReportCompany from "./SummaryReportCompany";

import axios from "axios";
import envConfig from "../../../bin/env.config";
import { convertToViewDate } from "../../../utility/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export default function SummaryReportPage() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataStatus, setDataStatus] = useState({list:[],dict:{}});  
  const [dataSourceRoot, setDataSourceRoot] = useState([]);  
  const [dataSource, setDataSource] = useState([]);  
  const token = useSelector((state) => state.session.token);
  
  const loader = document.querySelector(".loader");
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };
  
  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/ood-summary-report`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
      setDataSourceRoot(result.data.data);
      setIsLoading(false);
      hideLoader();
    };
    const fetchStatus = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/status`;      
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      let list = result.data.data
      let dict = Object.assign({}, ...list.map((x) => ({[x.id]: x})));
      setDataStatus({list:list,dict:dict});
      setIsLoading(false);
      hideLoader();
    };
    fetchStatus();
    fetchData();
  }, []);  
  const doFilter = (props)=>{
    let tempData = dataSourceRoot
    if(props.city){tempData = tempData.filter(row => row.city.toLowerCase()===props.city.toLowerCase())}
    if(props.company_id){tempData = tempData.filter(row => row.company_id===props.company_id)}
    setDataSource(tempData)
  }
  
  const  totalFooter= new Array(15).fill(0)
  const calTotalRow = (row)=>{    
    totalFooter[0]+= row.total_2_status_1
    totalFooter[1]+= row.total_1_status_1
    totalFooter[2]+= row.total_1_status_1-row.total_2_status_1    
    totalFooter[3]+= row.total_2_status_2
    totalFooter[4]+= row.total_1_status_2 
    totalFooter[5]+= row.total_2_status_3
    totalFooter[6]+= row.total_1_status_3 
    totalFooter[7]+= row.total_2_status_4
    totalFooter[8]+= row.total_1_status_4 
    totalFooter[9]+= row.total_2_status_5
    totalFooter[10]+= row.total_1_status_5 
    totalFooter[11]+= row.total_1_status_5-row.total_2_status_5    
    totalFooter[12]+= row.total_2_ood_2
    totalFooter[13]+= row.total_1_ood_2 
    totalFooter[14]+= row.total_1_ood_2-row.total_2_ood_2    
    
  }
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Out of date Summary Report
          </Typography> 
          {dataStatus.list.length&&<SummaryReportFilterBox doFilter={doFilter} />}
                  
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12} md={6} >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <SummaryReportCity dataStatus={dataStatus}/>
                </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <SummaryReportCompany dataStatus={dataStatus}/>
                </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              
                  <Table className={classes.table}  aria-label="simple table">
                    <TableHead >
                    {dataStatus.list.length>0&&(
                      <>
                        <TableRow>
                          <TableCell align="left" rowSpan={2}>City</TableCell>
                          <TableCell align="left" rowSpan={2}>Chain</TableCell>
                          <TableCell align="left" rowSpan={2}>Store</TableCell>
                          <TableCell align="center" colSpan={3} style={{color:dataStatus.dict[1].color}}>                      
                              {dataStatus.dict[1].name}                     
                          </TableCell>
                          <TableCell align="center" colSpan={2} style={{color:dataStatus.dict[2].color}}>                      
                              {dataStatus.dict[2].name}                      
                          </TableCell>
                          <TableCell align="center" colSpan={2} style={{color:dataStatus.dict[3].color}}>                      
                              {dataStatus.dict[3].name}                      
                          </TableCell>
                          <TableCell align="center" colSpan={2} style={{color:dataStatus.dict[4].color}}>                      
                              {dataStatus.dict[4].name}                      
                          </TableCell>
                          <TableCell align="center" colSpan={3}  style={{color:dataStatus.dict[5].color}}>                      
                              {dataStatus.dict[5].name}                      
                          </TableCell>
                          <TableCell align="center" colSpan={3}>
                            Total
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {/* <TableCell align="center">data_year_week</TableCell> */}                  
                          <TableCell align="center">Last Week</TableCell>
                          <TableCell align="center">This Week</TableCell>
                          <TableCell align="center">Diff</TableCell>
                          <TableCell align="center">Last Week</TableCell>
                          <TableCell align="center">This Week</TableCell>
                          <TableCell align="center">Last Week</TableCell>
                          <TableCell align="center">This Week</TableCell>
                          <TableCell align="center">Last Week</TableCell>
                          <TableCell align="center">This Week</TableCell>
                          <TableCell align="center">Last Week</TableCell>
                          <TableCell align="center">This Week</TableCell>
                          <TableCell align="center">Diff</TableCell>
                          <TableCell align="center">Last Week</TableCell>
                          <TableCell align="center">This Week</TableCell>
                          <TableCell align="center">Diff</TableCell>
                        </TableRow>
                      </> 
                    )}
                      
                    </TableHead>
                    <TableBody>
                      
                      {dataSource&&dataSource.map((row, i) => (
                        <>
                          <TableRow> 
                            <TableCell align="left">{row.city}</TableCell>
                            <TableCell align="left">{row.company}</TableCell>
                            <TableCell align="left">{row.store}</TableCell>
                            <TableCell align="center" >{row.total_2_status_1}</TableCell>
                            <TableCell align="center" style={{color:dataStatus.dict[1].color}}>{row.total_1_status_1}</TableCell>
                            <TableCell align="center">
                              <span style={{whiteSpace: 'nowrap',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {row.total_1_status_1-row.total_2_status_1} 
                                {row.total_1_status_1-row.total_2_status_1!==0&&(
                                  <i class="material-icons" style={{color:row.total_1_status_1-row.total_2_status_1>0?'red':'green',fontSize:"1rem"}}>{row.total_1_status_1-row.total_2_status_1>0?'arrow_upward':'arrow_downward'}</i>  
                                )
                                }                        
                              </span>
                            </TableCell>
                            <TableCell align="center" >{row.total_2_status_2}</TableCell>
                            <TableCell align="center" style={{color:dataStatus.dict[2].color}}>{row.total_1_status_2}</TableCell>
                            <TableCell align="center" >{row.total_2_status_3}</TableCell>
                            <TableCell align="center" style={{color:dataStatus.dict[3].color}}>{row.total_1_status_3}</TableCell>
                            <TableCell align="center" >{row.total_2_status_4}</TableCell>
                            <TableCell align="center" style={{color:dataStatus.dict[4].color}}>{row.total_1_status_4}</TableCell>
                            <TableCell align="center" >{row.total_2_status_5}</TableCell>
                            <TableCell align="center" style={{color:dataStatus.dict[5].color}}>{row.total_1_status_5}</TableCell>
                            <TableCell align="center">
                              <span style={{whiteSpace: 'nowrap',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {(row.total_1_status_5||0)-(row.total_2_status_5||0)}
                                {(row.total_1_status_5||0)-(row.total_2_status_5||0)!==0&&(
                                  <i class="material-icons" style={{color:(row.total_1_status_5||0-row.total_2_status_5||0)>0?'red':'green',fontSize:"1rem"}}>{(row.total_1_status_5||0-row.total_2_status_5||0)>0?'arrow_upward':'arrow_downward'}</i>  
                                )
                                }                        
                              </span>
                            </TableCell>
                            
                            <TableCell align="center">{row.total_2_ood_2}</TableCell>
                            <TableCell align="center">{row.total_1_ood_2}</TableCell>                      
                            <TableCell align="center">
                              <span style={{whiteSpace: 'nowrap',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {row.total_1_ood_2-row.total_2_ood_2} 
                                {row.total_1_ood_2-row.total_2_ood_2!==0&&(
                                  <i class="material-icons" style={{color:row.total_1_ood_2-row.total_2_ood_2>0?'red':'green',fontSize:"1rem"}}>{row.total_1_ood_2-row.total_2_ood_2>0?'arrow_upward':'arrow_downward'}</i>  
                                )
                                }                        
                              </span>
                            </TableCell>
                          </TableRow> 
                          {calTotalRow(row)}                  
                        </>
                      ))}
                    </TableBody>  
                    <TableFooter>
                      <TableRow>
                      <TableCell align="right" colSpan={3} style={{fontWeight:"bold",fontSize:"0.875rem",color:'black'}}>Total</TableCell>
                      {totalFooter.map((totalValue, index)=>(
                        <TableCell align="center" style={{fontWeight:"bold",fontSize:"0.875rem", color:'black'}}>
                          <span style={{whiteSpace: 'nowrap',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            {totalValue}
                            { [2, 11, 14].includes(index)&&totalValue!==0&&(
                              <i class="material-icons" style={{color:totalValue>0?'red':'green',fontSize:"1rem"}}>{totalValue>0?'arrow_upward':'arrow_downward'}</i>  
                              )
                            }
                          </span>
                        </TableCell>
                      ))}
                      </TableRow>
                    </TableFooter>           
                  </Table>              
            </Paper>
          </Grid> 
        </Grid>
      )}
    </>
  );
}
