import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button
} from "@material-ui/core";
import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonSelectGroupId({ product, potentialProduct, reloadData }) {
  const classes = useStyles();
  const history = useHistory();
  const token = useSelector((state) => state.session.token);
  const [serverUpdating, setServerUpdating] = useState(false);
  const handleSelected = ()=>{
    setServerUpdating(true)
  } 
  const handleCreateAndMatch = ()=>{
    // history.push(`/possible-existing/${product.productID}`);
    window.open(`/possible-existing/${potentialProduct.productID}`);
  } 

  useEffect(() => {
    const serverUpdate = async () => {
      let putData = {
        product_id: product.id,
        pmg_id: potentialProduct.pmg_id
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/select-group_id`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setServerUpdating(false)
      reloadData()
    };
    serverUpdating&&serverUpdate();
  }, [serverUpdating]);
  return (
    <>
       {potentialProduct.pmg_id>0?(          
            <Button  variant="outlined"  color="secondary" onClick={handleSelected}>
              {potentialProduct.pmg_id}
            </Button>
        ):(
          <Button  variant="outlined" color="primary" onClick={handleCreateAndMatch}>
            Create
          </Button>
        )}
    </>
  );
}
