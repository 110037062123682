import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  // title: {
  //   //marginLeft: theme.spacing(2),
  //   flex: 1,
  //   "&:hover": {
  //     cursor: "pointer",
  //     // backgroundColor: theme.palette.secondary.main,
  //     // color: "#fff",
  //   },
  // },
  toolBar:{
    minHeight:"48px",
    [theme.breakpoints.down("sm")]: {
      minHeight:"36px",
    },
  },
  iconButton:{
    padding:"0px",
    margin:"0px"
  },
  arrowBackIcon:{
    height:"36px",
    width:"36px",
    [theme.breakpoints.down("sm")]: {
      height:"24px",
      width:"24px",
    
    },
  }
}));

export default function AppBarBackToLandingPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const { setLocation, goLandingPage } = props;
  const handOnClick = () => {
    setLocation("");
    goLandingPage();
  };
  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          onClick={handOnClick}
          aria-label="close"
          className={classes.iconButton}
        >
          <ArrowBackIcon className={classes.arrowBackIcon}/>
        </IconButton>       
      </Toolbar>
    </AppBar>
  );
}
