import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  
}));
function preventDefault(event) {
  event.preventDefault();
}
export default function ParserInfoBlock({item}) {
  const classes = useStyles();
  return (
    <>
    <Title>{item.name}</Title>
    <Typography component="p" variant="h4">
      {item.value}
    </Typography>
    <Typography color="text.secondary" sx={{ flex: 1 }}>
    {item.description}
    </Typography>
    <div>
      <Link color="primary" href="#" onClick={preventDefault}>
        View balance
      </Link>
    </div>
    </>
    
  );
}
