import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogRecheck from "./DialogRecheck"
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";
import { Utility } from "../../../../utility/utility";
import { title } from "process";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  rootLeft: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    backgroundColor: theme.palette.secondary.main,
    // paddingTop: "64px",
    // width:"50%"
  },
  rootRight: {
    display: "flex",
  },

  acceptContainer: {
    backgroundColor: "#DDD",
  },
  allWrongButton: {
    color: '#fff',
    backgroundColor: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(154, 0, 54)',
    },
    '&:active': {
      backgroundColor: 'rgb(154, 0, 54)',
    },
    '&:focus': {
      backgroundColor: 'rgb(154, 0, 54)',
    },
  },
  title: {
    paddingLeft: '10px',
    paddingRight: '50px',
    fontSize: '20px',
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },

}));

export function LastActionItem({ item }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  };
  return (<>
    <ListItem
      button
    onClick={() => handleOnOpen()}
      title={"Click to review and undo this action"}
    >

      <ListItemText
        primary={`${item.product_id} - ${item.product_matched_id}: ${Utility.roundToNDecimal(item.sim_name,8)}`}
        secondary={<span><span>Reviewed date:# {item.reviewed_date};&nbsp;&nbsp;</span><span>
          {item.action_taken === 1 && (<span style={{ fontWeight: 'bold', color: '#00b04f' }}>Matches</span>)}
          {item.action_taken === 0 && (<span style={{ fontWeight: 'bold', color: '#DC004E' }}>Does not match</span>)}
          {item.action_taken === 2 && (<span style={{ fontWeight: 'bold', color: '#2650A2' }}>Skip it</span>)}
          {item.action_taken === 3 && (<span style={{ fontWeight: 'bold'  }}>Similar</span>)}
        </span></span>}
      />

    </ListItem>
    <DialogRecheck
      open={isDialogOpen}
      onClose={handleOnClose}
      item={item}
      />
  </>

  )

}
export default function LastActions({
  selectedProjectId
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { rmtRefresh } = useSelector(
    (state) => state.session
  );
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (selectedProjectId >= 0) {
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/last-actions`;
        const result = await axios.post(
          url,
          {
            projectId: selectedProjectId
          },
          {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          }
        );
        setDataSource(result.data.data);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [rmtRefresh, selectedProjectId]);

  return (
    <List className={classes.listProducts}>
      {isLoading ? (
        <ListItem
          button
        // onClick={() => handleSelectedGroupClick(group)}
        >
          <ListItemText
            primary={`Loading ...`}
            secondary={<CircularProgress size={16} />}
          />
        </ListItem>
      ) : (<>
        {dataSource.length > 0 &&
          dataSource.map((item, index) => (
            <LastActionItem key={index} item={item} />
          ))}
      </>
      )}
    </List>

  );
}
