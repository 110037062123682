import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PlacesAutocomplete from 'react-places-autocomplete';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
  // setLocation,
  setLocationAndUpdateStoresNearby
} from '../../actions/location';

import {
  geocodeByAddress,
  // geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

const styles = theme => ({ 
  locationButton: {
    justifyContent: 'center'
  }
});

class LocationPickerPage extends Component {

  constructor (props) {
    super(props);

    this.state = {
      searchValue: this.props.location.postal ? this.props.location.postal : '',
      issSearching: false,
      postalCodeNotFound: false,
      errorOccurred: false
    };
    // console.log(this.props.location);
  }

  onSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    //console.log(e.target.value);
    this.setState({
      searchValue: searchTerm,
      postalCodeNotFound: false,
      errorOccurred: false
    })
  }

  setLocation = () => {

    this.setState({
      issSearching: true
    });
    
    let postal = '';
    geocodeByAddress(this.state.searchValue)
      .then(results => {

        if (results.length === 0) {
          throw Error("Invalid search value");
        }

        const address = results[0];
        postal = address.address_components[0].long_name;
        this.setState({
          searchValue: postal
        });

        return getLatLng(address)
      })
      .then(latLng => {
        // console.log(latLng);
        this.setState({
          issSearching: false
        });
        // this.props.setLocation(postal, latLng.lat, latLng.lng);
        this.props.setLocationAndUpdateStoresNearby(postal, latLng.lat, latLng.lng);
        this.props.onLocationPick({
          postal,
          latitude: latLng.lat,
          longitude: latLng.lng
        })
      })
      .catch(error => {
        console.error('Error', error);

        let postalCodeNotFound = false;
        let errorOccurred = false;

        if (error === 'ZERO_RESULTS') {
          postalCodeNotFound = true;
        } else {
          errorOccurred = true;
        }

        this.setState({
          issSearching: false,
          postalCodeNotFound,
          errorOccurred
        });
      });
  };

  render () {
    const { classes } = this.props;
    return (
      <div>
        <Grid container justifyContent="center">
          <Grid item>
            <Input
              placeholder="e.g. V5W 2T3"
              value={ this.state.searchValue }
              onChange={this.onSearchInputChange}
            />
            <br/>
            {
              this.state.postalCodeNotFound ? 
                (
                  <Typography
                    variant="subheading"
                    color="error"
                    >
                    Please try another postal code.
                  </Typography>
                )

              : ''
            }

            {
              this.state.errorOccurred ? 
                (
                  <Typography
                      variant="subheading"
                      color="error"
                    >
                    An error occurred. Please try again.
                  </Typography>
                  
                )
                : ''
            }
          </Grid>
        </Grid>

        <br/>

        <DialogActions>

          {
            this.state.issSearching 
            ? 
              (
                <CircularProgress />
              )
            :
            (
              <Button
                onClick={ () => this.setLocation() }
                color="primary"
                variant="contained"
                disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
                >
                Save your location
              </Button>
            )
          }
        
        </DialogActions>

      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  location: state.location
});

const mapDispatchToProps = (dispatch, props) => ({
  // setLocation: (postal, latitude, longitude) => dispatch(setLocation(postal, latitude, longitude)),
  setLocationAndUpdateStoresNearby: (postal, latitude, longitude) => dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude))
});

export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(LocationPickerPage));
