import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


import axios from "axios";
import envConfig from "../../bin/env.config";
import {  useSelector } from "react-redux";

import ProductMatchingItem from "./ProductMatchingItem";
import ProductMatchingItemLoading from "./ProductMatchingItemLoading";
import SearchBox from "./SearchBox";
import CompanyDropDownBox from "./CompanyDropDownBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },
}));

export default function PotentialMatches4ProjectPage(props) {
  const history = useHistory();
  const classes = useStyles();
  
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState('2'); //ES search default

  const [original, setOriginal] = useState({});
  const [matches, setMatches] = useState([]);
  const projectID = props.match.params.projectid;
  const productID = props.match.params.id;
  
  const companies = useSelector(state => state.companies.companies);
  const token = useSelector(state => state.session.token);
  const selectedCompany = companies&&companies.find(company=>company.selected);
  

  const loader = document.querySelector(".loader");
  // const updateContainer = document.querySelector(".updateContainer");

  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };
  useEffect(() => {
    const fetchData = async () => {
      if (skip === 0) {
        showLoader();
        setIsLoading(true);
      }
      // setIsLoading(true);
      let apiElasticSearch = (searchType ==='1'?'potentiales':'potential');
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/${apiElasticSearch}/${productID}?limit=${limit}&skip=${skip}&t=${searchType}&q=${searchText}&c=${selectedCompany?selectedCompany._id:0}&p=${projectID?projectID:0}`;
      const result = await axios(url,
        {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      // console.log(url);
      if (skip === 0) {
        setOriginal(result.data.data.original);
        setMatches(result.data.data.matches);
      } else {
        setMatches([...matches, ...result.data.data.matches]);
      }

      if (skip === 0) {
        setLoadMore(
          result.data.data.matches && result.data.data.matches.length === limit
        );
        setIsLoading(false);
        hideLoader();
      }

      // setIsLoading(false);
    };

    fetchData();
  }, [searchText,searchType, skip, selectedCompany]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  const handleViewGroupClick = () => {
    if (original.product_matching_group_id) {
      history.push(`/matching-group/${original.product_matching_group_id}`);
    }
  };
  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);    
  };
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.push(`/`)}
            aria-label="Close"
          >
            <HomeIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Potential matches 
            {/* Potential matches / {original.name} {original.size} */}
          </Typography>
          <Select  value={searchType} onChange={handleSearchTypeChange}>
              <MenuItem value="1">
                <em>Elastic Search</em>
              </MenuItem>
              <MenuItem value="2">
                <em>PostgreSQL Similar</em>
              </MenuItem>
              <MenuItem value="4" >
                <em>PostgreSQL ilike</em>
              </MenuItem>
              <MenuItem value="5" >
                <em>PostgreSQL ilike Attributes</em>
              </MenuItem>
              <MenuItem value="3" >
                <em>ML Minhash</em>
              </MenuItem>
          </Select> 
          <CompanyDropDownBox/>
          <SearchBox
            doSearch={doSearch}
            value={searchText}
            searchOnChange={false}
          />
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewGroupClick}
          >
            View Matching Group
          </Button>
          {/* <Typography variant="h6" noWrap>
            {original.name} {original.size}
          </Typography> */}
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <div className={classes.reviewPanel}>
          {original.product_id && (
            <Hidden mdDown>
              <ProductMatchingItem
                productMatch={original}
                showType={"original"}
              />
            </Hidden>
          )}
          <Grid
            className={classes.root}
            container
            spacing={0}
            // alignItems="center"
            // justifyContent="center"
          >
            {matches.map((item, i) => (
              <ProductMatchingItem
                key={i}
                productMatch={item}
                groupId={original.product_matching_group_id}
                showType={"potential"}
              />
            ))}
            {loadMore && <ProductMatchingItemLoading doLoadMore={doLoadMore} />}
          </Grid>
        </div>
      )}
    </>
  );
}
