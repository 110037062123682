import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";
const useStyles = makeStyles((theme) => ({
  companyImage: {
    // width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      // width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
}));
export default function CompanyLogo({company}) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  return (    
        <VizSensor onChange={doLoadData}>
          <div>
            {!isVisible?(
              <CircularProgress size={16} />
            ):(
              <img
                className={classes.companyImage}
                alt={company.name}
                src={company.companyLogoLink}
                // onClick={() => handGroupView(productMatching.id)}
              />
            )}
          </div>
        </VizSensor>      
  );
}
