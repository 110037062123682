import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";



export default function PriceComparisonSummaryChart({matchedData}) {
  const [chartState, setChartState] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let labels = [];
    let totalCost = [];
    // let missingItems = [];
    matchedData.forEach((priceComparisonResult)=> { 
      labels.push(priceComparisonResult.store.name) 
      totalCost.push(priceComparisonResult.total.toFixed(2)) 
      // missingItems.push(priceComparisonResult.countMissing.toFixed(0)) 
    });
    setChartState({
      labels: labels,
      datasets: [
        {
          label: "Total cost",
          fill: false,
          lineTension: 0.5,
          backgroundColor: "rgba(0,176,79,1)",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
          data: totalCost,
        },
      ],
    });
    setIsLoading(false);
   
  }, []);
  return (
    <>
      {!isLoading&&<Bar
          data={chartState}
          options={{
            title:{
              display:true,
              text:'Price Comparison Summary',
              fontSize:20
            },
            legend:{
              text: '$',
              display:true,
              position:'top'
            }
          }}
        />}
    </>
  );
}
