import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import productsReducer from '../reducers/products';
import cartReducer from '../reducers/cart';
import priceComparisonReducer from '../reducers/pricecomparison';
import location from '../reducers/location';
import searchfilters from '../reducers/searchfilters';
import session from '../reducers/session';
import user from '../reducers/user';
import suggestions from '../reducers/suggestions';
import categories from '../reducers/categories';
import companies from '../reducers/companies';
import packageJson from '../../package.json';
// import swupdate from '../reducers/swupdate';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers =  combineReducers({
  products: productsReducer,
  cart: cartReducer,
  pricecomparison: priceComparisonReducer,
  location: location,
  searchfilters: searchfilters,
  session: session,
  user: user,
  suggestions: suggestions,
  categories:categories,
  companies:companies,
  // swupdate:swupdate
});

function saveToLocalStorage(state){
  try{  
    const serializedState = JSON.stringify(state)

    localStorage.setItem(`bettercart.v${packageJson.version}`,serializedState)
  }catch(e){
    //console.log(e)
  }
}
function loadFromLocalStorage(){
  try{
    const serializedState = localStorage.getItem(`bettercart.v${packageJson.version}`);

    if(serializedState===null) {
      localStorage.clear();
      return undefined
    }
    return JSON.parse(serializedState);
  }catch(e){
    //console.log(e)
    return undefined;
  }
}
const persistedState = loadFromLocalStorage();
const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);
store.subscribe(()=>saveToLocalStorage(store.getState()))
export default () => {
  return store;
};
