import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

export default function ProjectProductMatchingAssignedMatchedStatusInfo({open,project,user_id}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSummary, setDataSummary] = useState({
          groups: 0, 
          products: 0 
        }
  );
  useEffect(() => {    
    const getData = async () => {
      setIsLoading(true);
      let putData = {
         project_id:project.id,
         user_id:user_id
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-assigned-matched-summary`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setDataSummary(result.data.data);
      setIsLoading(false);
    };
    project&&open&&getData();
  }, [project,user_id, open]);
  return (
    <>
    {isLoading ? `Loading ...` : (      
      <ul style={{padding:'0px',margin:'0px'}}>
        <li>Groups:<span style={{color:'blue'}}>{dataSummary.groups}</span></li>
        <li>Products:<span style={{color:'green'}}>{dataSummary.products}</span></li>        
      </ul>
    )}
  </>  
  );
}
