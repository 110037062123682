import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForwardIos';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import Hidden from '@material-ui/core/Hidden';

import CloseIcon from '@material-ui/icons/Close';

import StoreBestProductMatch from './StoreBestProductMatch';
import LoginPage from '../Login/LoginPage';
import ErrorMessage from '../Error/ErrorMessage';

import { startPriceCompare, updateProductPick, setSelectedStoreCart , loadPriceComparisonResultFromLocal} from '../../actions/pricecomparison';
import { addSavedCart } from '../../actions/session';
import { setSavedCartSuccess } from '../../actions/user';
import CartButton from '../CartButton/CartButton';
import { loadSavedCart } from '../../actions/cart';
import { setFilteredStores } from '../../actions/searchfilters';
import { setProducts } from '../../actions/pricecomparison';


import { Helpers } from '../../utility/helpers';
import { Utility } from '../../utility/utility';
import globalStyles from '../../styles/styles';
import Slider from "react-slick";
import Drawer from '@material-ui/core/Drawer';
import ReactToPrint from "react-to-print";
import ReactGA from 'react-ga';


const styles = (theme) => ({
  column: {
    // flexGrow: 1,
    // width: '350px'
  },
  header: {
    marginLeft: theme.spacing(2) + 'px'
  },
  storePriceComparisonHeader: {
    marginTop: theme.spacing(2) + 'px',
    marginLeft: theme.spacing(2) + 'px'
  },
  colorPrimary: {
    backgroundColor: '#B2DFDB',
  },
  barColorPrimary: {
    backgroundColor: '#00695C',
  },
  buttonProgress: {
    ...globalStyles.buttonSpinnerProgress
  },
  comparisonContainer: {
    // width: '100%'
    minHeight: '0px',
    minWidth: '0px'
    // display: 'table-cell'
  },
  root: {
    // display: 'flex'
    width: '100%'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title:{
    fontWeight: 'bold',    
    fontSize: '28px',    
    fontFamily: 'Montserrat',            
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    },
    paddingBottom: '20px'
  },
  content: {
    // backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
   // [theme.breakpoints.up('md')]: {
   //   marginLeft: drawerWidth,
   //   width: `calc(100% - ${drawerWidth}px)`,
   // }
  },
  sliderContainer: {
    padding: 0
  },
  yourListActionContainer: {
    padding: theme.spacing(2)
  },
  priceComparisonGrid: {

  },
  cartIcon: {
    width: '45px'
  },
  productCount: {
    marginBottom: '13px'
  },
  comparisonSlider: {
    backgroundColor: "#ff0000"
  },
  slideNavBar: {
    top: 'auto',
    bottom: 0,
  },
  nextArrow: {
    marginLeft: 'auto',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    width:'30px',
    minWidth: '30px',
    height: '30px',
    minHeight: '30px',
    padding: '0 0 0 3px'
  },
    previousArrow: {
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    width:'30px',
    minWidth: '30px',
    height: '30px',
    minHeight: '30px',
    padding: '0 0 0 8px'
  }
  // actionButton: {
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  //   // display: "flex",
  //   // alignItems: "center",
  //   // justifyContent: "center",
  //   // width: '80%'
  // }
});

const sliderSettings = {
  // lazyLoad: 'ondemand',
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 3,
  className: 'comparisonSlider',
  // centerMode: true,
  // autoplay: true,
  // autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

const drawerWidth = 350;

class PriceComparisonDialog extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isLoginModalOpen: false,
      isCartNameModalOpen: false,
      cartName: '',
      priceComparisonResult: undefined,
      errors: {},
      showSavedCartSuccess: false,
      bestDeals: []
    }

    this.onNexSlide = this.onNexSlide.bind(this);
    this.onPrevSlide = this.onPrevSlide.bind(this);

    ReactGA.pageview('/PriceComparison');

  }

  componentWillReceiveProps(nextProps) {
    this.setState({isCartDrawerOpen: nextProps.isCartDrawerOpen});
  }

  componentDidMount () {    
    this.loadPage();
  }

  loadPage = () => {    
    let producComparison = [];
    const params = {
      latitude: this.props.location.latitude,
      longitude: this.props.location.longitude,
      products: []
    };

    // if (localStorage.getItem('cart')  && !this.props.cart.items ){                 
    //   this.props.loadSavedCart(JSON.parse(localStorage.getItem('cart')));
    //   this.props.setProducts(JSON.parse(localStorage.getItem('cart')));
    // }
    
    if (localStorage.getItem('stores')) {      
      const localSavedStores = JSON.parse(localStorage.getItem('stores'));              
      this.props.setFilteredStores(localSavedStores);      
    }  

    if (this.props.cart && this.props.cart.length>0){
      producComparison = this.props.cart;
    } else {
      producComparison = JSON.parse(localStorage.getItem('cart')).items;
    }
    
    for (let product of producComparison) {
      //console.log(product);
      params.products.push({
        productID: product.productID,
        name: product.name,
        brand: product.brand,
        size: product.size,
        brandKeywords: product.brandKeywords,
        nounKeywords: product.nounKeywords,
        adjectivesKeywords: product.adjectivesKeywords,
        sizeKeywords: product.sizeKeywords,
        categoryType: product.categoryType,
        immediateAdjective: product.immediateAdjective
      })
    }     

    let priceComparisonResult =undefined; 
    if (localStorage.getItem('pricecomparison') ) {      
      const localPriceComparison = JSON.parse(localStorage.getItem('pricecomparison'));   
      priceComparisonResult = localPriceComparison.priceComparisonResult;
      this.setState({ priceComparisonResult: priceComparisonResult });    

      // console.log(localPriceComparison); 
      this.props.loadPriceComparisonResultFromLocal(localPriceComparison);   
    } 
    
    if (!priceComparisonResult) {
      this.props.startPriceCompare(params);    
    }            
    
  }

  openLoginPageModal = () => {
    this.setState({ isLoginModalOpen: true });
  }

  closeLoginPageModal = () => {
    this.setState({ isLoginModalOpen: false });
  }

  openCartNameModal = () => {
    this.setState({ isCartNameModalOpen: true });
  }

  closeCartNameModal = () => {
    this.setState({ isCartNameModalOpen: false });
  }

  onNexSlide = () => {
    this.slider.slickNext();
  }

  onPrevSlide = () => {
    this.slider.slickPrev();
  }

  onLoginSuccessHandler = () => {
    this.setState({
      isLoginModalOpen: false,
      showUserLoginWelcome: true
    });
  }

  onCartNameChange = (e) => {
    this.setState({
      cartName: e.target.value
    })
  }

  hideCart = () => {
    this.props.toggleList();
  }

  onHideSavedCartSuccess = () => {
    this.setState({
      isCartNameModalOpen: false
    })
    this.props.setSavedCartSuccess(false)
  }

  onBack = () => {
    this.props.history.push('/home');
  }

  onSelectStoreCart =(store) =>{
    this.props.setSelectedStoreCart(store);    
    this.props.showSelectedStore();
  }

  renderCartItems = () => {
    const { classes } = this.props;
  
    return (
      <div>
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <Typography variant="title" color="inherit">Your list</Typography>
            <IconButton color="primary" variant="contained" style={{marginLeft: 'auto'}} onClick={ this.hideCart }><CloseIcon /></IconButton>
          </Toolbar>
        </AppBar>

        <Grid container alignItems="center" alignContent="center" justifyContent="center" spacing={16} className={ classes.yourListActionContainer }>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Button 
              size="small"
              fullWidth={true}
              className={ classes.actionButton }
              onClick={this.onSaveCartHandler} 
              variant="outlined">Save</Button>
          </Grid>
          <Grid item  xs={4} sm={4} md={4} lg={4} xl={4}>
            <ReactToPrint
              trigger={() => <Button className={ classes.actionButton } size="small" fullWidth={true} onClick={this.onPrint} variant="outlined">Print</Button>}
              content={() => this.componentRef}
            />
          </Grid>
          <Grid item  xs={4} sm={4} md={4} lg={4} xl={4}>
            <CartButton
              customSeeMySavingsHandlerFn={ this.loadPage }
              anchor="right"
              className={ classes.actionButton }
              editButton={true}
             />
          </Grid>
        </Grid>
       
      </div>
    );
  }

  

  renderEmptyCartState = () => {
    return (
      <div>
        Empty Cart
      </div>
      );
  }

  renderLoadingState = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <main>
           <Slider {...sliderSettings}>
              {
                this.props.location.storesNearby.map((store) => {
                  return (
                   <StoreBestProductMatch
                    isLoading={ true }
                    store={ store }
                    key={ store._id }
                    selectStoreCart = {this.onSelectStoreCart}  
                    matches={ [...this.props.pricecomparison.products] }
                    />
                  )
                })
              }
            </Slider>
        </main>
      </div>
    ); 
  }

  onSaveCartHandler = () => {
    const { session } = this.props;

    if (!Helpers.isLoggedIn(session.user)) {
      this.openLoginPageModal();
      return;
    }

    this.openCartNameModal();
  }

  startCartSaving = () => {
    const { session, cart } = this.props;
    
    if (!this.validateForm()) {
      return;
    }

    const cartForSaving = [];
    for (let product of cart) {
      const p = {...product};
      p.sourceProductID = product._id;
      cartForSaving.push(p);
    }

    this.props.addSavedCart(this.state.cartName, cartForSaving);
  }

  onProductPick = (product, store, row, col) => {
    //  console.log('onProductPick');
    //  console.log(product);
    //  console.log(row);
    //  console.log(col);
    this.props.updateProductPick(product, store, row, col);
  }

  validateForm = () => {
    let isFormValid = true;
    let errors = {};

    if (!Utility.isSet(this.state.cartName) || !this.state.cartName || this.state.cartName.length === 0) {
      errors['cartName'] = `Cart Name is required`;
      isFormValid = false;
    }

    this.setState({
      errors: errors
    });

    return isFormValid;
  }

  // renderBody = () => {
  //   const { classes } = this.props;
  
  //   return (
  //     <div className={classes.root}>
  //       <Drawer
  //         className={classes.drawer}
  //         variant="permanent"
  //         classes={{
  //           paper: classes.drawerPaper,
  //         }}
  //         anchor="left"
  //         >
  //         {
  //           this.renderCartItems()
  //         }
  //       </Drawer>
  //       <main>
  //          <div>
  //            <Typography variant="display1" className={ classes.header }>
  //              Single store prices for your list
  //            </Typography>
  //          </div>
  //          <Slider {...sliderSettings} className={classes.sliderContainer}>
  //             {
  //               this.renderResultRow(0)
  //             }
  //           </Slider>
  //         </main>
  //     </div>
  //   );
  // }

  // renderResultRow = (row) => {
  //   const { classes } = this.props;

  //   return this.props.pricecomparison.priceComparisonResult.map((result, index) => {
  //     return (
  //       <ProductMatch 
  //         className={classes.product}
  //         showPickButton={true}
  //         onProductPick={ (product) => { this.onProductPick(product) }}
  //         isLoading={ this.props.pricecomparison.isLoading }
  //         // products={ result.matches[row].moreMatches }
  //         productMatchData={result.matches[row]}
  //       />
  //     );
  //   });
  
  // }

  renderBodyHorizontal = () => {
    const { classes } = this.props;
    let isLoading =  false; 
    let priceComparisonResult = this.state.priceComparisonResult;
    if (this.props.pricecomparison.priceComparisonResult && this.props.pricecomparison.priceComparisonResult.length>0){
      priceComparisonResult = this.props.pricecomparison.priceComparisonResult; 
      isLoading =  this.props.pricecomparison.isLoading; 
    }
  
    // console.log('priceComparisonResult'+ priceComparisonResult);
    return (
      <div className={classes.root}>

        <Hidden smDown>
          <Drawer
            open={this.props.isCartDrawerOpen}
            className={classes.drawer}
            variant="persistent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
            >
            {
              this.renderCartItems()
            }
          </Drawer>
        </Hidden>
        <main>
          <Typography className={classes.title} color="primary" align="center">Price comparisons for your shopping cart</Typography>

           <Slider ref={c => (this.slider = c)} {...sliderSettings} className={classes.sliderContainer}>
              {
                priceComparisonResult.map((result, index) => {
                  let i = index;
                  return (
                    <StoreBestProductMatch
                      className={classes.priceComparisonGrid}
                      key={result.store._id}
                      isLoading={ isLoading }
                      showPickButton={false}
                      selectStoreCart = {this.onSelectStoreCart}  
                      onProductPick={(p, row) => this.onProductPick(p, result.store, row, index) }
                      store={ result.store }
                      matches={ result.matches }
                      />
                  );
                })
              }
            </Slider>
          </main>
      <AppBar position="sticky" color="#fff" className={ classes.slideNavBar } >
        <Toolbar><Button onClick={this.onPrevSlide}  className={ classes.previousArrow }><ArrowBack /></Button><Button onClick={this.onNexSlide} variant="contained" color="primary" className={ classes.nextArrow }><ArrowForward /></Button></Toolbar>
      </AppBar>          
      </div>
    );
  }

  renderBodyVertical = () => {
    const { classes } = this.props;    
    let isLoading =  false; 
    let priceComparisonResult = this.state.priceComparisonResult;    
    let totalCartProducts = this.props.cart.length;

    if (this.props.pricecomparison.priceComparisonResult && this.props.pricecomparison.priceComparisonResult.length>0){
      priceComparisonResult = this.props.pricecomparison.priceComparisonResult; 
      isLoading =  this.props.pricecomparison.isLoading; 
    }
    // console.log('priceComparisonResult'+ JSON.stringify(priceComparisonResult));
    // console.log();
    return (
      <div className={classes.root}>

        <Hidden smDown>
          <Drawer
            open={this.props.isCartDrawerOpen}
            className={classes.drawer}
            variant="persistent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
            >
            {
              this.renderCartItems()
            }
          </Drawer>
        </Hidden>
        <main>
          <Typography className={classes.title} color="primary" align="center">Price comparisons for your shopping cart</Typography>           
              {
                priceComparisonResult && priceComparisonResult.map((result, index) => {
                  let i = index;
                  return (
                    <StoreBestProductMatch
                      className={classes.priceComparisonGrid}
                      key={result.store._id}
                      isLoading={ isLoading }
                      showPickButton={false}
                      selectStoreCart = {this.onSelectStoreCart}  
                      onProductPick={(p, row) => this.onProductPick(p, result.store, row, index) }
                      store={ result.store }
                      matches={ result.matches }
                      totalCartProducts={totalCartProducts}

                      />
                  );
                })
              }            
          </main>              
      </div>
    );
  }

  renderBody = () => { 
   return (
    //this.renderBodyHorizontal()
    this.renderBodyVertical()
   );
  }

  renderStoreBestMatches = () => {
    const { classes } = this.props;
    return (
      <div>
        {
          this.renderBody()
        }
        <LoginPage 
          open={ this.state.isLoginModalOpen }
          onClose={this.closeLoginPageModal}
          onLoginSuccess={this.onLoginSuccessHandler}
        />

        <Dialog
          open={this.state.isCartNameModalOpen && this.props.user.showSavedCartSuccess === false}
          onClose={this.closeCartNameModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Save List</DialogTitle>
          <DialogContent>

            {
              this.props.user.savedCaartError && (
                <ErrorMessage />
              )
            }

            <DialogContentText>
              Please enter your list name.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Cart Name"
              type="text"
              fullWidth
              onChange= { this.onCartNameChange }
              InputLabelProps={{
                shrink: true,
              }}
            />
            {
              this.state.errors['cartName'] && (
                <Typography
                  variant="caption"
                  color="error"
                >
                  { this.state.errors['cartName'] }
                </Typography>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCartNameModal} color="primary">
              Cancel
            </Button>
            <Button 
              onClick={this.startCartSaving} 
              color="primary" 
              variant="contained"
              disabled={ this.props.user.savedCartLoader }
              >
              Save
               {this.props.user.savedCartLoader && <CircularProgress size={24} className={classes.buttonProgress} /> }
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.props.user.showSavedCartSuccess}
          autoHideDuration={3000}
          onClose={this.onHideSavedCartSuccess}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">Your list has been saved</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.onHideSavedCartSuccess}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }

  //   savedCartLoader: false,
  // savedCaartError: null,
  // showSavedCartSuccess: false

  renderResults = () => {
    const { errorOccurred } = this.props.pricecomparison;
    let isLoading = this.props.pricecomparison.isLoading;
    if (this.state.priceComparisonResult){
      isLoading = false;
    }

    return (
      <div>
        {
          isLoading 
          ?
          <div>
            <br/>
            <LinearProgress />
            <br/>
            <Typography align="center" variant="display1" gutterBottom={true}>Please wait while we find the best deals for you 🛒 </Typography>
            { this.renderLoadingState() }
          </div>
           : '' 
         }
        
        {
          (!isLoading && !errorOccurred) && this.renderStoreBestMatches()
        }
      
        {
          errorOccurred && (
            <ErrorMessage />
          )
        }

      </div>
    );
  }

  render () {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        {this.renderResults()}
        {/* {this.props.pricecomparison.products.length > 0 ? this.renderResults() : this.renderEmptyCartState()} */}
      </div>
    );
  }

}

const mapStateToProps = (state, props) => ({
  session: state.session,
  pricecomparison: state.pricecomparison,
  location: state.location,
  cart: state.cart.items,
  user: state.user
});
const mapDispatchToProps = (dispatch, props) => ({
  startPriceCompare: (params) => dispatch(startPriceCompare(params)),
  loadPriceComparisonResultFromLocal: (data) => dispatch(loadPriceComparisonResultFromLocal(data)),
  setSelectedStoreCart: (store) => dispatch(setSelectedStoreCart(store)),
  addSavedCart: (cartName, products) => dispatch(addSavedCart(cartName, products)),
  setSavedCartSuccess: (showSavedCartSuccess) => dispatch(setSavedCartSuccess(showSavedCartSuccess)),
  updateProductPick: (product, store, row, col) => dispatch(updateProductPick(product, store, row, col)),
  setProducts: (products) => dispatch(setProducts(products)),  
  loadSavedCart: (cart) => dispatch(loadSavedCart(cart)),
  setFilteredStores: (filteredStores) => dispatch(setFilteredStores(filteredStores)),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PriceComparisonDialog)));
