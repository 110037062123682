import React, {
  useState,
  useEffect
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import VizSensor from "react-visibility-sensor";
import Chip from "@material-ui/core/Chip";
import Badge from "@material-ui/core/Badge";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";
const useStyles = makeStyles((theme) => ({}));

export default function PotentialLeft({simSource,simValue, groupId, matched}){
  const classes = useStyles();
  const [data, setData] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const loading = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/potential-left`;
    axios
      .post(url,
        {
          simSource: simSource,
          simValue: simValue,
          groupId: groupId,
        },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
      })
      .then((result) => {
        setData(result.data.data);
        setLoaded(true);
      })
      .catch((err) => {});
  };
  const onChange = (isVisible) => {
    // setShow(isVisible);
    if (isVisible && !loaded) {
      loading();
      // console.log(props);
    }
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     // setIsLoading(true);
  //     const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/potential-left`;
  //     const result = await axios.post(
  //       url,
  //       {
  //         simSource: simSource,
  //         simValue: simValue,
  //         groupId: groupId,
  //       },
  //       {
  //         headers: {
  //           Authorization: JSON.stringify(store.getState().session.token),
  //         },
  //       }
  //     );
  //     // console.log(result.data.data)
  //     setData(result.data.data);
  //     // setIsLoading(false);
  //   };
  //   fetchData();
  // }, [simSource,simValue]);  
  return (
    <VizSensor onChange={onChange}>
      <Badge color="primary" badgeContent={data}>
        <Chip label={matched} color="secondary" />
      </Badge>
    </VizSensor>
  );
};
