import { updateSearchHistory, updateTodaysDeals, makeAllProductsAvailable, setProductBeingViewed } from './products';

const addProductToCart = (product) => ({
  type: 'ADD_PRODUCT',
  product
});



const removeProduct = (product) => ({
  type: 'REMOVE_PRODUCT',
  product
});

const clearCart = () => ({
  type: 'CLEAR_CART'
});

export const updateProduct = (product) => ({
  type: 'UPDATE_PRODUCT',
  product
});

export const loadSavedCart = (cart) => ({
  type: 'LOAD_SAVED_CART',
  cart
});

export const startAddProductToCart = (product, productSource = 'search') => {
  return (dispatch, getState) => {
    // dispatch(setAddProductSuccess(true));
    dispatch(addProductToCart(product));

    // product.inTheCart = true;

    // if (productSource === 'search') {
    //   dispatch(updateSearchHistory(product)); 
    // } else if (productSource === 'todaysDeals') {
    //   dispatch(updateTodaysDeals(product)); 
    // }

    // const state = getState();
    // if (state.products && state.products.productBeingViewed) {
    //   const productBeingViewed = {...state.products.productBeingViewed};

    //   if (productBeingViewed._id === product._id) {
    //     productBeingViewed.inTheCart = true; 
    //     dispatch(setProductBeingViewed(productBeingViewed));
    //   }
    // }
  }
}

export const startRemoveProductToCart = (product) => {
  return (dispatch) => {
    dispatch(removeProduct(product));

    product.inTheCart = undefined;
    dispatch(updateSearchHistory(product));
  }
}

export const startClearCart = () => {
  return (dispatch) => {
    dispatch(clearCart());
    dispatch(makeAllProductsAvailable());
  }
}