import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

export default function ProjectCustomerProductMappingInfo({open,project}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSummary, setDataSummary] = useState({
          total_products: 0,
          total_product_not_have_pmg_id: 0,
          total_product_matching_group_ids: 0}
  );
  useEffect(() => {
    
    const getData = async () => {
      setIsLoading(true);
      let putData = {
         project_id:project.id,
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-summary`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setDataSummary(result.data.data);
      setIsLoading(false);
    };
    project&&open&&getData();
  }, [project,open]);
  return (
    <>
    {isLoading ? `Loading ...` : (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Total products:</TableCell><TableCell>{dataSummary.total_products}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Total need to add product_matching_group_id:</TableCell><TableCell>{dataSummary.total_product_not_have_pmg_id}/{dataSummary.total_products}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Total product_matching_group_ids:</TableCell><TableCell>{dataSummary.total_product_matching_group_ids}</TableCell>
          </TableRow>
        </TableBody>
        </Table>
    )}
  </>  
  );
}
