import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import VizSensor from "react-visibility-sensor";

const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.secondary.main,
  },
}));

export default function ProductMatchingGroupListItemLoading({ doLoadMore }) {
  const classes = useStyles();

  return (
    <VizSensor onChange={doLoadMore}>
      <TableRow>
        <TableCell colSpan="3" align="center">
          <CircularProgress className={classes.loading} />
        </TableCell>
        <Hidden mdDown>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
        </Hidden>
      </TableRow>
    </VizSensor>
  );
}
