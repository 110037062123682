const categoriesDefaultState = {
  categories: []
};

export default (state=categoriesDefaultState, action) => {
  //console.log(action);
  // console.log(state);
  switch (action.type) {
    case 'SET_CATEGORIES' :
      return {
        ...state,
        categories: action.categories
      };
    // case 'CLEAR_CATEGORIES' :
    default:
      return state;
  }
};
