import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import StoreOnMapGrid from "./StoreOnMapGrid";
import AppBarBackButton from "./AppBarBackButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogFull(props) {
  const classes = useStyles();
  const { open, onClose, store } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBarBackButton onClose={onClose} />
      <StoreOnMapGrid store={store} />
    </Dialog>
  );
}
