import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from "axios";
import envConfig from "../../bin/env.config";
import ParserInfoBlock from "./ParserInfoBlock"
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
const drawerWidth = 240;
export default function ParserManagePage() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);  
  const [dataBlockSource, setDataBlockSource] = useState([]);  
  const token = useSelector((state) => state.session.token);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/parser-manage/machine`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    const fetchDataBlock = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/parser-manage/block`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setDataBlockSource(result.data.data);
      setIsLoading(false);
    };
    fetchData();
    fetchDataBlock();
  }, []);   
  
  return (
    <>
     <CssBaseline />
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Parser's log
          </Typography> 
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          {dataBlockSource&&dataBlockSource.map((row, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3}  >
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <ParserInfoBlock item={row}/>
                </Paper>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Table className={classes.table}  aria-label="simple table">
                    <TableHead >
                        <TableRow>
                          <TableCell align="left" >Id</TableCell>
                          <TableCell align="left" >Hostname</TableCell>
                          {/* <TableCell align="left" >ParserId</TableCell> */}
                          <TableCell align="left" >Status</TableCell>
                          <TableCell align="left" >Error</TableCell>
                          <TableCell align="left" >Edited at</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataSource&&dataSource.map((row, i) => (
                        <>
                          <TableRow> 
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="left">{row.hostname}</TableCell>
                            {/* <TableCell align="left">{row.parser_id}</TableCell> */}
                            <TableCell align="left">{row.status}</TableCell>
                            <TableCell align="left">{row.error}</TableCell>
                            <TableCell align="left">{row.edited_at}</TableCell>
                          </TableRow>              
                        </>
                      ))}
                    </TableBody>
                  </Table>              
            </Paper>
          </Grid> 
        </Grid>
      )}
    </>
  );
}
