import React from "react";
// import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "100%",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.secondary.main,
    //background: '#12C50D',
    paddingTop: "9px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {},
  },
  title: {
    fontSize: "40px",
    fontWeight: "bold",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    //lineHeight: "28px",
    //paddingRight: "20px",
    paddingBottom: "4px",
    color: "#fff",
    textShadow: "0 0 10px rgba(0,0,0,0.6)",
    [theme.breakpoints.down("sm")]: {
      // paddingBottom: '10px',
      fontSize: "23px",
      paddingRight: "0px",
    },
  },
  subTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //lineHeight:'23px',
    textShadow: "0 0 10px rgba(0,0,0,0.6)",
    [theme.breakpoints.down("sm")]: {
      // lineHeight:'23px',
      flexDirection: "column",
    },
  },
  subTitleWithLogo: {
    textAlign: "center",
  },
  subTitleText: {
    fontSize: "25px",
    fontFamily: "Montserrat",
    display:"inline",
    paddingLeft: "4px",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      paddingLeft: "0px",
    },
  },
  br:{
    [theme.breakpoints.up("sm")]: {
     display:"none"
    },
  },
  logo: {
    width: "auto",
    height: "30px",
    marginBottom: "-6px",
    marginRight: "-36px",
    // boxShadow: "0 0 10px rgba(0,0,0,0.6)",
    [theme.breakpoints.down("sm")]: {
      height: "23px",
      marginBottom: "-4px",
      marginRight: "-24px",
    },
  },
}));
export default function WhatInStockBanner() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>what's in stock?</Typography>
      <div className={classes.subTitle}>
        <div className={classes.subTitleWithLogo}>
          <Typography className={classes.subTitleText}>
            Crowdsourced by<br className={classes.br}/>
            <img
              src="./resources/images/Better-Cart-Logo-horizontal-no-tag-white@3x.png"
              className={classes.logo}
            />{" "}
            and YOU!
          </Typography>
        </div>
      </div>
    </div>
  );
}
