import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";
import ProductViewDetail from "./ProductViewDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));
export default function ProductPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState(undefined);
  const productId = props.match.params.id;

  const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product/${productId}`;
  // const { productShare, productSelect, productView, productLike, productAlert, showAlert } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(url);
      console.log(result);
      setProduct(result.data.data);
      setIsLoading(false);
    };

    fetchData();
    // console.log(productMatchings);
  }, []);
 

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Product #{productId}
          </Typography>
          <div className={classes.grow} />          
        </Toolbar>
      </AppBar>

      {isLoading ? (
        <Grid className={classes.root} container>
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid className={classes.root} container>
          {product&& (
            <ProductViewDetail
              productMatch={product}
            />
          )}
         </Grid>
      )}
    </>
  );
}