import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import envConfig from "../../bin/env.config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    // background: '#12C50D',
    paddingTop: '9px',
    paddingBottom: '26px',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: "column",
    }
  },
  title: {
    fontSize: '2.4em',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    textAlign: 'center',
    // lineHeight: "28px",
    // paddingRight: "20px",
    paddingBottom: '4px',
    textShadow: '0 0 10px rgba(0,0,0,0.6)',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      // width:
      // fontSize: "18px",
      fontSize: '23px'
      // paddingRight: "0pt",
    }
  },
  button: {
    background: '#fff',
    marginTop: '2px',
    display: 'block',
    boxShadow: '0 0 10px rgba(0,0,0,0.6)',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: '#fff'
    },
    // lineHeight: "42px",
    height: '56px',
    padding: '10px 40px',
    [theme.breakpoints.down('xs')]: {
      height: '38px',
      padding: '4px 20px'
    }
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '25px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
      // paddingBottom: '10pt',
      // fontSize: "12px",

    }
  }
}))
export default function AdvertisingBanner (props) {
  const history = useHistory()
  const classes = useStyles()
  const onButtonClick = () => {
    // alert(props.postalCode);
    if (props.postalCode) {
      history.push('product-status')
    } else {
      history.push('location-picker')
    }
  }
  // console.log(process.env)
  return (
    <>        
    {envConfig.TURN_ON_ADS&&(
    <div className={classes.container}>
      {/* Slack feedback from Melanie: Remove COVID-19 HELP.  Instead, put "CHECK PRODUCT AVAILABILITY" in white writing with green background. */}
      <Typography variant='h5' className={classes.title}>
        CHECK PRODUCT AVAILABILITY
      </Typography>
      <Button
        className={classes.button}
        onClick={() => onButtonClick()}
        // disabled={ !this.state.searchValue || this.state.searchValue.length < 6 }
        variant='contained'
        color='secondary'
        disableElevation
      >
        <Typography className={classes.buttonText} noWrap>
          What's in stock near you?
        </Typography>
      </Button>
    </div>
    )}
    </>
  )
}
