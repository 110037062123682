import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";
import  ProjectProductMatchingAssignedDoingStatusInfo  from "./ProjectProductMatchingAssignedDoingStatusInfo";
import  ProjectProductMatchingAssignedMatchedStatusInfo  from "./ProjectProductMatchingAssignedMatchedStatusInfo";

export default function ProjectProductMatchingAssignedInfo({open,project}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([])  
  useEffect(() => {
    
    const getData = async () => {
      setIsLoading(true);
      let putData = {
         project_id:project.id,
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-assigned-summary`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    project&&open&&getData();
  }, [project,open]);
  return (
    <>
    {isLoading ? `Loading ...` : (
      <>
     <Table>
      <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>email</TableCell>
              <TableCell>assigned</TableCell>
              <TableCell>doing status</TableCell>
              <TableCell>matched status</TableCell>
            </TableRow>
      </TableHead>
        <TableBody>
          {dataSource&&dataSource.map((item,i)=>(
            <TableRow key={i}>
            <TableCell>{item.user_id}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>{item.cnt}</TableCell>
            <TableCell><ProjectProductMatchingAssignedDoingStatusInfo open={open} project={project} user_id={item.user_id}/></TableCell>
            <TableCell><ProjectProductMatchingAssignedMatchedStatusInfo open={open} project={project} user_id={item.user_id}/></TableCell>
            </TableRow>
          ))}          
           
        </TableBody>
      </Table>
      </>
    )}
  </>  
  );
}
