import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../bin/env.config";

import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogConfirm({open, onClose, session_id, item_id, reloadData}) {
  const token = useSelector((state) => state.session.token);
  const [serverUpdating, setServerUpdating] = useState(false);
  const onSave = () => {
    setServerUpdating(true)
  };
  useEffect(() => {
    const serverUpdate = async () => {     
      const url = session_id?`${envConfig.API_ROOT}${envConfig.API_VERSION}/link-manage/delete-session/${session_id}`
                  :`${envConfig.API_ROOT}${envConfig.API_VERSION}/link-manage/delete-item/${item_id}`;
      const result = await axios.delete(url, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setServerUpdating(false)
      reloadData()     
    };
    serverUpdating&&serverUpdate();
  }, [serverUpdating]);
  return (
      <Dialog
        //fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this {session_id?'Session':'Item'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={onSave} >Yes</Button>
          <Button onClick={onClose}>No</Button>
        </DialogActions>
      </Dialog>
  );
}
