import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogFindGroupId from "../DialogFindGroupId";
import AddIcon from "@material-ui/icons/Add";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonAdd({product, reloadData }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  };
  const reloadDataLocal = () =>{
    reloadData&&reloadData()
    setIsDialogOpen(false)
  } 
  return (
    <>         
      <IconButton size='small' onClick={handleOnOpen}>
        <AddIcon/>
      </IconButton> 
      <DialogFindGroupId
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        product={product}
        reloadData={reloadDataLocal}
        isCompetitor={false}
      />
    </>
  );
}
