import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";
const useStyles = makeStyles((theme) => ({}));

const TotalBlock = forwardRef((props, ref) => {
  const classes = useStyles();
  const [data, setData] = useState({ total: 0, matched: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const fetchTotalData = async (simSource,selectedList2MatchingStoreId) => {
    setIsLoading(true);
    // console.log('here: fetchTotalData');
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/total`;
    const result = await axios.post(
      url,
      {
        simSource: simSource,
        list2MatchingStoreId: selectedList2MatchingStoreId,
      },
      {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      }
    );
    setData(result.data.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchTotalData('_ve','0');
  }, []);
  useImperativeHandle(ref, () => ({
    fetchTotalData: fetchTotalData,
  }));
  return (
    <Typography className={classes.title} variant="h6" noWrap>
      {isLoading ? `Loading ...` : `${data.matched}/${data.total}`}
    </Typography>
  );
});
export default TotalBlock;
