import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SyncIcon from "@material-ui/icons/Sync";

import {
  IconButton
} from "@material-ui/core";
import DialogSyncResult from "./DialogSyncResult";

const useStyles = makeStyles((theme) => ({
 
}));
 


export default function ButtonSync({project}) {
  const classes = useStyles(); 
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
  
  return (
    <> 
      {project&&project.user_type_id>0&&(     
        <IconButton onClick={handleOnOpen}>
            <SyncIcon/>
        </IconButton> 
      )}
      {project&&project.user_type_id>0&&(     
            
        <DialogSyncResult
          //fullScreen
          open={isDialogOpen}
          onClose={handleOnClose}       
          project_id={project.id}
        
        />
      )}  
    </>
  );
}
