import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import SearchBox from './SearchBox'
import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogSelectCities({open, onClose, project}) {
  const token = useSelector((state) => state.session.token);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceSearch, setDataSourceSearch] = useState([]);
  const [dataSourceProvince, setDataSourceProvince] = useState([]);
  const [serverUpdating, setServerUpdating] = useState(false);
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      let putData = {
         project_id:project.id,
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-cities`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setDataSource(result.data.data);
      var provinces = Object.values(result.data.data.reduce((r, e) => (r[e.province] = {group: e.group, age: e.age}, r), {}))
      console.log(result)
      setIsLoading(false)
    };
    project&&open&&getData();
  }, [project,open]);
  useEffect(() => {
    const serverUpdate = async () => {
      
      let postData = {
        project_id:project.id,
        cities:[],
      }; 
      dataSource.map(item => {
        if(item.company_report_tool_project_id === project.id ){
          postData.cities.push({id:item.id,order_no:item.order_no})
        }
      })
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/project-cities-update`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setServerUpdating(false)   
      onClose&&onClose()   
    };
    serverUpdating&&serverUpdate();
  }, [serverUpdating]);
  useEffect(() => {
    if (searchText.length > 0) {
      let dataSourceFilterTemp = dataSource.filter(function(city) {
        if (city.city.toLowerCase().includes(searchText.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      setDataSourceSearch([...dataSourceFilterTemp]);
    } else {
      setDataSourceSearch([...dataSource]);
    }
  }, [searchText,dataSource]);
  const handleChange = (isChecked, selectedId) => {    
      let dataSourceTemp = dataSource.map(item=>{
        let itemTemp = item
        if(itemTemp.id===selectedId){
          // console.log(itemTemp)
          itemTemp.company_report_tool_project_id = !isChecked?project.id:0;
        }
        
        return itemTemp
      })
      setDataSource(dataSourceTemp)
  };

  const handleSave = () => { 
    setServerUpdating(true)
  };
  const doSearch = (searchValue) => {
    setSearchText(searchValue);
  };
  return (
      <Dialog
        //fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="Project Banners"
      >
        <DialogTitle>{project&&`Select banners for ${project.name}`}</DialogTitle>
        <DialogContent>
              <Table>
                  <TableHead>
                  <TableRow>
                    <TableCell align="right">#</TableCell>
                    <TableCell align="center">
                      {/* <Checkbox
                              id={`chk_banner_all`}
                              checked={selected.length ===store.banners.length}
                              onChange={() => handleChangeAll(selected.length ===store.banners.length)}
                      /> */}
                    </TableCell>
                    <TableCell align="center">Province</TableCell> 
                    <TableCell align="left">City</TableCell> 
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={4} align="right"><SearchBox value={searchText} doSearch={doSearch} searchOnChange={true} placeholder="Search"/></TableCell> 
                    </TableRow>
                  </TableHead>
                  <TableBody>
                 {isLoading&&
                 <TableRow>
                  <TableCell colSpan={4}> Loading...</TableCell>
                  </TableRow>}
                 
                 {!isLoading&&(
                  <>
                    {dataSourceSearch.map((row, i) => {
                        let isChecked = Boolean(row.company_report_tool_project_id);
                        return (
                          <TableRow key={i} 
                             onClick={() => handleChange(isChecked, row.id)}>
                            <TableCell align="right">{i + 1}</TableCell>
                            <TableCell align="center">
                              <Checkbox
                                id={`chk_banner_${row.id}`}
                                checked={isChecked}
                                onChange={() => handleChange(isChecked, row.id)}
                              />
                            </TableCell>      
                            <TableCell>{row.province}</TableCell>                                
                            <TableCell>{row.city}</TableCell>                                
                            {/* <TableCell>{row.order_no}</TableCell>                                 */}
                          </TableRow>
                        );
                      })}
                  </>
                 )}
                 </TableBody>
                </Table>
        </DialogContent>      
        <DialogActions>
          <Button id="btn_banner_cancel" onClick={onClose} color="default">
            Cancel
          </Button>
          <Button id="btn_banner_save" autoFocus onClick={handleSave} color="primary">
            {serverUpdating?'Save ...':'Save'}
          </Button>
        </DialogActions>  
      </Dialog>
  );
}
