import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import VizSensor from "react-visibility-sensor";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import {
  Link
} from "@material-ui/core";

import { useSelector } from "react-redux";
export default function CountCompetitorProducts({companyReportToolProductId}) {
  const history = useHistory();
  const token = useSelector((state) => state.session.token);
  const [countValue, setCountValue] = useState('-');
  const [isVisible, setIsVisible] = useState(false);
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/count/competitor?id=${companyReportToolProductId}`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setCountValue(result.data.data);
    };    
    companyReportToolProductId>0&&isVisible&& fetchData();    
  }, [isVisible, companyReportToolProductId]);
  const handleOnOpen = ()=>{
    history.push(`/company-report-tool-competitor-product/${companyReportToolProductId}`);
    // window.open(`/matching-group/${productMatchingGroupID}`);
  } 
  return (    
        <VizSensor onChange={doLoadData}>
          <div>
            {countValue==='-'?(
              <CircularProgress size={16} />
            ):(
              <>#Competitors: <Link  component="button" onClick={handleOnOpen}>
                  {countValue}
                </Link> 
              </>
            )}
          </div>
        </VizSensor>      
  );
}
