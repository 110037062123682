const loginMessages= {
  0:{
    // "title": "Oh that looks like a good item!",
    // "subTitle": "That's a great item. To add this product to your favorites please log in below:"   
    //Trello #200521
    "title": "That's a great item!",
    "subTitle": "You can add this product to your favorites by logging in below:"   
  },
  1:{
    "title": "Oh that looks like a good item!",
    "subTitle": "To start saving your grocery lists and your favorite products with BetterCart, sign up below:"   
  },
  2:{
    // #todo message require log in to able do more compare product item on this website:
    "title": "Oh that looks like a good item!",
    "subTitle": "To start saving your grocery lists and your favorite products with BetterCart, sign up below:" 
  },
};
export default loginMessages;
