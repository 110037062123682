import React, { useState, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import ScoreFilter from "./ScoreFilter";
import PageNavigator from "./PageNavigator";

import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";
import { TableFooter } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function MLResultReviewPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [score, setScore]=useState({nameScore:1, brandScore:1,sizeScore:1,unitScore:1})
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const token = useSelector((state) => state.session.token);

  const loader = document.querySelector(".loader");
  // const updateContainer = document.querySelector(".updateContainer");

  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  }
  const go2Page = (page)=>{
    setCurrentPage(page)
    setSkip((page-1)*limit)
  }

  useEffect(() => {
    const fetchData = async () => {
        showLoader();
        setIsLoading(true);
      // console.log('here'+searchText);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/ml-review?limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setDataSource(result.data.data);
      if (result.data.data.length>0) {
        setTotalPage(currentPage+1)
      }
        setIsLoading(false);
        hideLoader();
     
    };
    fetchData();
  }, [skip,score]); 


  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
           ML Result Review
          </Typography>
          <ScoreFilter doSearch={setScore}/>
          {/* {totalPage>currentPage && (
            <PageNavigator currentPage = {currentPage} totalPage={totalPage} go2Page={go2Page} />
          )} */}
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
         
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
              <TableRow>
                    <TableCell align="center">
                {totalPage>currentPage && (
                  <PageNavigator currentPage = {currentPage} totalPage={totalPage} go2Page={go2Page} />
                )}
                </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((row, i) => (
                  <>
                  <TableRow style={{backgroundColor:"#009900"}}>
                  <TableCell align="center" colSpan="2">{row.id}</TableCell>
                  <TableCell align="center" >{row.combined_score}</TableCell>
                    </TableRow>  
                  <TableRow>
                    <TableCell align="center">
                      <img
                        className={classes.productImage}
                        src={row.a_image_url}
                        // onClick={() => handGroupView(productMatching.id)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <img
                        className={classes.productImage}
                        src={row.b_image_url}
                        // onClick={() => handGroupView(productMatching.id)}
                      />
                    </TableCell>
                    <TableCell align="center">{row.image_score}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {row.a_name}
                    </TableCell>
                    <TableCell align="center">
                      {row.b_name}
                    </TableCell>
                    <TableCell align="center">{row.name_score}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {row.a_brand}
                    </TableCell>
                    <TableCell align="center">
                      {row.b_brand}
                    </TableCell>
                    <TableCell align="center">{row.brand_score}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {row.a_size}
                    </TableCell>
                    <TableCell align="center">
                      {row.b_size}
                    </TableCell>
                    <TableCell align="center">{row.size_score}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {row.a_unit}
                    </TableCell>
                    <TableCell align="center">
                      {row.b_unit}
                    </TableCell>
                    <TableCell align="center">{row.unit_score}</TableCell>
                  </TableRow>
                  </>
                   ))}
                
              </TableBody>
              <TableFooter>
              <TableRow>
                    <TableCell align="center">
                {totalPage>currentPage && (
                  <PageNavigator currentPage = {currentPage} totalPage={totalPage} go2Page={go2Page} />
                )}
                      </TableCell>
                      </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      )}
     </>
  );
}
