import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DialogEdit from "./DialogEdit";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonEdit({ project, reloadData }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
 
  return (
    <>     
      
        {project?(
          <IconButton onClick={handleOnOpen}>
            <EditIcon/>
          </IconButton>      
        ):(
          <Button  variant="contained" startIcon={<AddIcon />} onClick={handleOnOpen}>
            New Company/Project
          </Button>
        )}
      
      <DialogEdit
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        project={project}
        reloadData={reloadData}
      />
    </>
  );
}
