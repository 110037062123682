import axios from "axios";
import envConfig from "../bin/env.config";

export const setCompanies = (companies = []) => ({
  type: "SET_COMPANIES",
  companies,
});

// export const clearCategories = () => ({
//   type: "CLEAR_CATEGORIES",
// });

export const fetchCompanies = () => {
  return (dispatch) => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/companies`;
    axios.get(url).then((result) => {
      let companies = [...result.data.data];
      companies.forEach(function(element) {
        element.selected = false;
      });
      dispatch(setCompanies(companies));
    });
  };
};

