import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import envConfig from '../../bin/env.config';

import LinearProgress from '@material-ui/core/LinearProgress';

import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';

import { findProduct } from '../../actions/products';

import { loadSavedCart } from '../../actions/cart';

import ErrorMessage from '../Error/ErrorMessage';

import Skeleton from 'react-loading-skeleton';

import { Helpers } from '../../utility/helpers';

import PriceComparisonSummary from './PriceComparisonSummary';

import { startPriceCompare, setSelectedStoreCart,loadPriceComparisonResultFromLocal } from '../../actions/pricecomparison';
import { addFavoriteProduct, removeFavoriteProduct } from '../../actions/session';
import { setFavoriteProductError, setFavoriteProductSuccess } from '../../actions/user';
import { setFilteredStores } from '../../actions/searchfilters';

import ReactGA from 'react-ga';
import grey from '@material-ui/core/colors/grey';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  body: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100vh'
  },
  ShoppingCart: {
    flex: '0 0 300px',
    alignItems: 'center'
  },
  advertising: {
    flex: '0 0 300px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  list: {
    //background: theme.palette.primary.main,    
    textAlign: 'center',
    alignItems:'center',
    padding: theme.spacing(5),
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
    }
    ///boxShadow: 'inset 0 0 30px #022974'    
  },
  itemList: {
    maxWidth: '250px',
    textAlign: 'left',   
    borderColor: '#cdd1ce'   ,
    borderWidth: '1px',
    borderStyle: 'solid',          
  },
  storeLogo: {
    width: '250px',
    height: 'auto',    
  },
  productItem: {
    margin: theme.spacing(1),       
  },
  grow: {
    flexGrow: 1,
  },
  storeMatchContainer: {
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(5)
  },
  storeColumn: {
  },
  otherStoresTitle: {
    paddingTop: 0,
    marginTop: 0
  },
  slider: {
    paddingLeft: '55px',
    paddingRight: '55px',
    '& .slick-dots': {      
      display: 'none !important'
    }
  },
  shareButton: {
    display: 'inline-block',
    marginRight: "10px",
    '&:hover': {
      opacity: '0.5'
    }
  },
  snackbarRoot:{
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
    backgroundColor: "#fff",
    fontWeight: 'bold',
    marginTop: '60px',
    marginRight: '-10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    }
  },
  snackbarRootError:{
    color: '#ff0000',
    borderColor: '#ff0000',
    borderWidth: '2px',
    borderStyle: 'solid',
    backgroundColor: "#fff",
    fontWeight: 'bold',
    marginTop: '60px',
    marginRight: '-10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    }
  },
  noMatchRoot: {
    background: grey[100],
    boxShadow: 'none',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '210px'
    // ,
    // width: '100%'
  },

});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class PriceComparisonSummaryPage extends Component {

  productLoaded = false;

  state = {
  }

  constructor (props) {
    super(props);
    this.state = {  
      isLoading: true,    
      isTellAFriendOpen: false,
      isLoginModalOpen: false,
      isAddFavoriteProductSuccess: false,
      isRemoveFavoriteProductSuccess: false,
      filteredStores: undefined,      
      priceComparisonResult: undefined,      
      productUrl: '',      
    };
    this.state.filteredStores = this.props.searchfilters.filteredStores;
    this.state.pricecomparison = this.props.pricecomparison;
    ReactGA.pageview("PriceComparisonSummary"); 
  }

  componentDidMount () {
    this.loadPage();
  }  

  loadPage = () => {
    let producComparison = [];
    const params = {
      latitude: this.props.location.latitude,
      longitude: this.props.location.longitude,
      products: []
    };

    if (localStorage.getItem('cart')  && !this.props.cart.items ){                 
      this.props.loadSavedCart(JSON.parse(localStorage.getItem('cart')));      
    }

    if (localStorage.getItem('stores')) {      
      const localSavedStores = JSON.parse(localStorage.getItem('stores'));              
      this.props.setFilteredStores(localSavedStores);      
    }  

    if (this.props.cart && this.props.cart.length>0){
      producComparison = this.props.cart;
    } else {
      producComparison = JSON.parse(localStorage.getItem('cart')).items;
    }
    
    for (let product of producComparison) {
      console.log(product);
      params.products.push({
        productID: product.productID,
        name: product.name,
        brand: product.brand,
        size: product.size,
        brandKeywords: product.brandKeywords,
        nounKeywords: product.nounKeywords,
        adjectivesKeywords: product.adjectivesKeywords,
        sizeKeywords: product.sizeKeywords,
        categoryType: product.categoryType,
        immediateAdjective: product.immediateAdjective
      })
    }     

    let priceComparisonResult =undefined; 
    // if (localStorage.getItem('pricecomparison') ) {      
    //   const localPriceComparison = JSON.parse(localStorage.getItem('pricecomparison'));   
    //   priceComparisonResult = localPriceComparison.priceComparisonResult;
    //   this.setState({ 
    //     priceComparisonResult: priceComparisonResult,
    //     isLoading: localPriceComparison.isLoading
    //   });   

    // } 
        
    if (!priceComparisonResult) {
      this.props.startPriceCompare(params);          
    } 
    
  }

  onBack = () => {
    this.props.history.push('/cart');
  }

  onSelect =() =>{

  }

  onSelectStoreCart =(store) =>{
    this.props.setSelectedStoreCart(store);
    this.props.history.push('/cartstore');
  }

  onProductShareModalView = (product) => {
    this.setState({
      isTellAFriendOpen: !this.state.isTellAFriendOpen,  
      productUrl: `${envConfig.PUBLIC_DOMAIN}/product/${product._id}`
     });
    
     if(this.state.isTellAFriendOpen){
      ReactGA.pageview("TellAFriendAProduct");      
    }     
  }

  onProductLiked = (product, isFavoriteProduct) => {     
    //console.log(product);    
    if (!Helpers.isLoggedIn(this.props.session.user)){
      this.setState({
        isLoginModalOpen: true
      });
    } else {
      if (isFavoriteProduct){
        this.props.removeFavoriteProduct(product);
        this.setState({
          isRemoveFavoriteProductSuccess: true
        });
      } else {
        this.props.addFavoriteProduct(product);
        this.setState({
          isAddFavoriteProductSuccess: true
        });
      }      
    }
  }

  hideAddFavoriteProductSuccess = () => {        
    this.setState({ 
      isAddFavoriteProductSuccess: false,    
    });
  }
  hideRemoveFavoriteProductSuccess = () => {        
    this.setState({ 
      isRemoveFavoriteProductSuccess: false,    
    });
  }

  onLoginSuccessHandler = () => {
    this.setState({
      isLoginModalOpen: false,      
    });    
  }

  closeLoginPageModal = () => {        
    this.setState({ 
      isLoginModalOpen: false,    
    });
  }

  

  renderLeftAdvertising =() => {    
    const { classes, session } = this.props;         
    const stores = JSON.parse(localStorage.getItem('stores'));       
    return (        
        <div className={ classes.list }>                   
        {
          stores.map((store) => {                                                                            
            //let company = <img src={store.companyData.companyLogoLink} className={ classes.storeLogo }/>;                                            
            return (                   
              <Card className={classes.noMatchRoot}>
                  <Typography align="center" variant="overline" noWrap={true}>Ad</Typography>
              </Card>
            );
          })
        }            
      </div> 
    )                  
  }
  renderRightAdvertising =() => {
    return this.renderLeftAdvertising();
  }
  
  renderLoadingState = () => {
    const { classes } = this.props;
    return (
      <div>
          <Skeleton width={100} />
          <br/>
          <Skeleton width={75} />
          <br/>
          <Skeleton width={150} />
          <br/>
          <Skeleton width={150} />
      </div>
    );
  }

  render () {
    const { classes } = this.props;    
    const errorOccurred =   this.props.pricecomparison.errorOccurred;
    let isLoading =  this.props.pricecomparison.isLoading; 
     
    let priceComparisonResult = this.state.priceComparisonResult;

    // if (this.props.pricecomparison.priceComparisonResult && this.props.pricecomparison.priceComparisonResult.length>0){
    //   priceComparisonResult = this.props.pricecomparison.priceComparisonResult; 
    //   isLoading =  this.props.pricecomparison.isLoading; 
    // }

    if (priceComparisonResult){
      isLoading= false;
    } else {
      priceComparisonResult = this.props.pricecomparison.priceComparisonResult;
      // console.log(priceComparisonResult);
    }
    // console.log("priceComparisonResult:"+ priceComparisonResult);
    // console.log("pricecomparison" + this.props.pricecomparison);

    return (
      <div>             
          <AppBar position="sticky" color="inherit">
            <Toolbar>
              <IconButton color="inherit" onClick={this.onBack} aria-label="Close">
                <ArrowBackIcon />
              </IconButton>              
              <div className={classes.grow} />                          
            </Toolbar>
          </AppBar>
          <div className={classes.body}>
            <div className={classes.advertising}>
              {
                //this.renderLeftAdvertising()
              }
            </div>
            
            <div className={classes.ShoppingCart}>
            {
              isLoading ?
                <div>
                  <br/>
                  <LinearProgress />
                  <br/>
                  <Typography align="center" variant="body1" gutterBottom={true}>Please wait while we find the best deals for you 🛒 </Typography>
                  { this.renderLoadingState() }
                </div>
              : '' 
            }
              
            {
              (!isLoading && !errorOccurred) && 
                <PriceComparisonSummary     
                  isLoading={ isLoading }     
                  selectStoreCart = {this.onSelectStoreCart}       
                  matchedData={ priceComparisonResult }
                />
            }  
            
            {
              errorOccurred && (
                <ErrorMessage />
              )
            }
                           
            </div>
            
            <div className={classes.advertising}>
              {
                //this.renderRightAdvertising()
              }
            </div>
          </div>
                 
          

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.isRemoveFavoriteProductSuccess}
            autoHideDuration={3000}
            onClose={this.hideRemoveFavoriteProductSuccess}
            ContentProps={{
              'aria-describedby': 'message-id',
              classes: {
                root: classes.snackbarRootError
              }
            }}
            message={<span id="message-id">Product has been removed to your favorites</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.hideRemoveFavoriteProductSuccess}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />     

      </div>
    );

  }
}

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  products: state.products,
  searchfilters: state.searchfilters,
  session: state.session,
  location: state.location,
  cart: state.cart.items,
  pricecomparison: state.pricecomparison,
  isLoadingProduct: state.products.isLoadingProduct,
  errorOccurredLoadingProduct: state.products.errorOccurredLoadingProduct,
  productBeingViewed: state.products.productBeingViewed,
  errorOccurredLoadingProduct: state.products.errorOccurredLoadingProduct
});

const mapDispatchToProps = (dispatch, props) => ({
  startPriceCompare: (params) => dispatch(startPriceCompare(params)),
  setSelectedStoreCart: (store) => dispatch(setSelectedStoreCart(store)),
  loadPriceComparisonResultFromLocal: (data) => dispatch(loadPriceComparisonResultFromLocal(data)),
  findProduct: (product) => dispatch(findProduct(product)),
  setFilteredStores: (filteredStores) => dispatch(setFilteredStores(filteredStores)),
  loadSavedCart: (cart) => dispatch(loadSavedCart(cart)),
  addFavoriteProduct: (user, product) => dispatch(addFavoriteProduct(user, product)),
  removeFavoriteProduct: (product) => dispatch(removeFavoriteProduct(product)),
  setFavoriteProductError: (value) => dispatch(setFavoriteProductError(value)),
  setFavoriteProductSuccess: (value) => dispatch(setFavoriteProductSuccess(value))
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PriceComparisonSummaryPage)));
