import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import DialogSummary from "./DialogSummary";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonSummary({ project }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
 
  return (
    <>     
      
        {project&&(
          <IconButton onClick={handleOnOpen}>
            <InfoIcon/>
          </IconButton>      
        )}
      
      <DialogSummary
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        project={project}
      />
    </>
  );
}
