import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";

import SyncIcon from "@material-ui/icons/Sync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  TextField
} from "@material-ui/core";
import axios from "axios";
import envConfig from "../../../bin/env.config";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogSyncResult({open, onClose, project_id}) {
  const token = useSelector((state) => state.session.token); 
  const [inSync, setInSync] = useState(false)
  const [isSyncCompleted, setIsSyncCompleted] = useState(false)
  useEffect(() => {
    const doSync = async () => {     
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/do-sync/${project_id}`;
      const result = await axios.get(url, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setInSync(false)
      setIsSyncCompleted(true)
    };
    inSync&&doSync();
  }, [inSync]);
  const handleClick = ()=>{
    setInSync(true)
  }
  
  return (
      <Dialog
        //fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="Calculate"
      >
        <DialogTitle>Sync Project 2 Analytics</DialogTitle>
        <DialogContent>        
            <>
              <p>Sync this project to the analytics platform </p>
              {inSync&& (
                <LinearProgress color="secondary" />
              )}
              
            </>         
        </DialogContent>   
        <DialogActions>
          {!isSyncCompleted && (
                <Button  variant="contained" startIcon={<SyncIcon />} onClick={handleClick}>
                  Click to sync
                </Button>
              )}
              {isSyncCompleted && (
                <>
                  <Button  variant="contained" startIcon={<SyncIcon />} onClick={handleClick}>
                    Click to Re-Sync
                  </Button>
                  <Button  variant="contained"  onClick={onClose}>
                    Close
                  </Button>
                </>                
              )}
        </DialogActions>     
      </Dialog>
  );
}
