import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import DialogConfirm from "../DialogConfirm";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonDelete({ item, reloadData }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
 
  return (
    <>
      {item&&(
        <IconButton onClick={handleOnOpen}>
          <ClearIcon/>
        </IconButton>      
      )}      
      <DialogConfirm
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        item_id = {item.id}
        reloadData={reloadData}
      />
    </>
  );
}
