import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import envConfig from "../../../bin/env.config";
import {  useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  
}));

export default function StatusDropDownBox({dataStatus, onChange}) {
  const classes = useStyles();
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelected = (statusId) => {
    setSelectedStatus(statusId)
    setAnchorEl(null);
    onChange('status',statusId)
  };  
  return (
    <>
    <Button color="primary" className={classes.buttonCategoryMenu} onClick={handleOpenMenu}
           endIcon={<ExpandMoreIcon/>} 
          >{selectedStatus?dataStatus.dict[selectedStatus].name:'All status'}</Button>
    <Menu
        id="cities-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={()=>setAnchorEl(null)}
      >
        <MenuItem selected={selectedStatus==0} onClick={()=>handleSelected(0)}>All status</MenuItem>
        {dataStatus.list.length&&dataStatus.list.map((status)=>
          
            <MenuItem selected={status.id==selectedStatus} onClick={()=>handleSelected(status.id)}>
              <span style={{color:status.color}}  title={status.description}>
              {status.name}
              </span>
            </MenuItem>
          
        )}
    </Menu>
    </>    
  );
}
