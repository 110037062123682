import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import envConfig from "../../../bin/env.config";
import {  useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  
}));

export default function ChangeDropDownBox({onChange}) {
  const classes = useStyles();
  const [companies, setCompanies] = useState({list:[],dict:{}});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = useSelector((state) => state.session.token);
 const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSelected = (company) => {
    setSelectedCompany(company)
    setAnchorEl(null);
    onChange('company_id',company)
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/companies`;      
      const result = await axios.get(url,{
        headers: {
          Authorization: token,
        },
      });
      let list = result.data.data
      let dict = Object.assign({}, ...list.map((x) => ({[x.id]: x})));
      setCompanies({list:list,dict:dict});
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return (
    <>
    <Button color="primary" className={classes.buttonCategoryMenu} onClick={handleOpenMenu}
           endIcon={<ExpandMoreIcon/>} disabled={isLoading}
          >{selectedCompany?companies.dict[selectedCompany].name:'All Company'}</Button>
    <Menu
        id="company-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={()=>setAnchorEl(null)}
      >
        <MenuItem selected={selectedCompany==0} onClick={()=>handleSelected(0)}>All Cities</MenuItem>
        {companies.list.length&&companies.list.map((company)=>
          <MenuItem selected={company.id==selectedCompany} onClick={()=>handleSelected(company.id)}>{company.name}</MenuItem>
        )}
    </Menu>
    </>    
  );
}
