import React, { Component } from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import StoresPicker from "./StoresPicker";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

//import { setLocationAndUpdateStoresNearby } from '../../actions/location';
import AdvertisingBanner from "../WhatInStock/AdvertisingBanner";
import AppBarBackToLandingPage from "./AppBarBackToLandingPage";
import { Helpers } from "../../utility/helpers";
import { setLocation } from "../../actions/location";
import ReactGA from "react-ga";

let drawerWidth = 0;

const styles = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //alignItems:"center"
  },
  grow: {
    flexGrow: 1,
  },
  hero: {
    //position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "80px",
    },
  },
  logoText: {
    color: "#fff",
    fontWeight: "600",
    display: "inline",
    textAlign: "center",
    fontSize: "36px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    //padding: theme.spacing(2)  + 'px',
  },
  logo: {
    height: "60px",
    padding: theme.spacing(2) + "px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
    //cursor: 'pointer'
  },
  homeToolbar: {
    paddingLeft: "12px",
  },
  toolbar: theme.mixins.toolbar,
  
  map: {
    //border: '1px solid #ffffaa',
    display: "flex",
    width: "100%",
    padding: "50px",
    height: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      width: "250px",
      height: theme.spacing(6),
    },
    "& .leaflet-container": {
      height: "600px",
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    flex: "1 300px",
    minWidth: 0,
  },
  button: {
    padding: "2px 4px",
    display: "block",
    alignItems: "center",
    width: "258px",
    height: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: theme.spacing(7),
    },
  },
  buttonText: {
    color: "#fff",
    //fontWeight: 'bold',
    fontSize: "20px",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  controlBox: {
    background: theme.palette.primary.main,
    height: 90,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      //height: '50px',
      display: "none",
    },
  },
});

class StorePage extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    // console.log(this.props.location);
    if(!this.props.location.postal) this.goLandingPage();
  }
  goHomePage = () => {
    this.props.history.push("home");
  };

  goLandingPage = () => {
    this.props.history.push("/");
  };

  onLocationPick = () => {
    this.setState({
      isLocationPickerOpen: false,
    });
  };

  
  renderControlBox = () => {
    const { classes } = this.props;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return (
      <div className={classes.controlBox}>
        <Hidden smUp>
          <Typography variant="h4" className={classes.footerHeading}>
            Helping you save money every day
          </Typography>
          <Typography className={classes.footerCopyright}>
            © {currentYear} BetterCart&trade;
          </Typography>
        </Hidden>
      </div>
    );
  };

  render() {
    const { classes, location } = this.props;

    return (
      <div className={classes.root}>
        <AppBarBackToLandingPage
          setLocation={this.props.setLocation}
          goLandingPage={this.goLandingPage}
        />
        <AdvertisingBanner postalCode={location.postal} />
        {/* <Paper className={classes.hero} elevation={0} square={true}>
          <AppBar
            color="inherit"
            position="relative"
            style={{ background: "transparent", boxShadow: "none" }}
          >
            <Toolbar className={classes.homeToolbar}>
              <img
                src="./resources/images/bettercart-logo-white-no-tag.png"
                className={classes.logo}
              />
            </Toolbar>
          </AppBar>
        </Paper> */}
        {
          <StoresPicker
            goNextPage={this.goHomePage}
            goLandingPage={this.goLandingPage}
          />
        }

        {//this.renderFooter()
        this.renderControlBox()}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  location: state.location,  
});
const mapDispatchToProps = (dispatch, props) => ({
  //setLocationAndUpdateStoresNearby: (postal, latitude, longitude) => dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude)),
  dispatch: (params) => dispatch(params),
  setLocation: (postal, latitude, longitude) =>
    dispatch(setLocation(postal, latitude, longitude)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(StorePage)
);
