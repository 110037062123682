import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";

import ReviewItem from "./ReviewItem";
import ReviewItemOriginal from "./ReviewItemOriginal";
import ReviewItemBlank from "./ReviewItemBlank";
import MergeConfirmDialog from "./MergeConfirmDialog";
import MoreButtonsMenu from "./MoreButtonsMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootLeft: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    backgroundColor: theme.palette.secondary.main,
    // paddingTop: "64px",
    // width:"50%"
  },
  rootRight: {
    display: "flex",
  },

  acceptContainer: {
    backgroundColor: "#DDD",
  },
  allWrongButton:{
    color: '#fff',
    backgroundColor: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:active': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:focus': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
  }
}));

export default function ReviewPanel({
  selectedGroup, 
  handleAccept,
  handleAllWrong,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [showMatched, setShowMatched] = useState(true);
  const [isMergeConfirmDialogOpen, setIsMergeConfirmDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (selectedGroup.id > 0) {
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/similar/review`;
        const result = await axios.post(
          url,
          {
            id: selectedGroup.id,           
          },
          {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          }
        );
        setProduct({
          original:selectedGroup,
          matchingData: [...result.data.data.matched,...result.data.data.predictions]
        });
      }
      setIsLoading(false);     
    };
    selectedGroup && fetchData();
  }, [selectedGroup]);
  const handleMatches = (productMatch) => {      
    //update: incase group
    let temp = product.matchingData.map((p) => {
      return  (p.product_id === productMatch.product_id)?{...p,type:'matched'}:p; 
    })    
    setProduct({...product, matchingData:temp});
    //add to product.matches        
  };
  const handleNotAMatch = (productMatch) => {
    let temp = product.matchingData.filter(p => p.product_id !== productMatch.product_id)
    // temp.potentials.push(productMatch);
    setProduct({...product, matchingData:temp});
    //add to product.potentials
  };  
  const handleIsADQ = (productMatch, isADQ) => {      
    //update: incase group
    let temp = product.matchingData.map((p) => {
      return  (p.product_id === productMatch.product_id)?{...p,is_adq:isADQ}:p; 
    })    
    setProduct({...product, matchingData:temp});
    //add to product.matches        
  };
  return (
    <>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <>
          {product && product.original && (
            <ReviewItemOriginal
              originalProduct={product.original}
            />
          )}
          <Grid
            className={classes.root}
            container
            spacing={0}
            // alignItems="center"
            // justifyContent="center"
          >
            { product &&
              product.matchingData &&
              product.matchingData.map((item, i) => (
                <ReviewItem
                  key={i}
                  productMatch={item}
                  groupId={selectedGroup.id}   
                  handleNotAMatch={handleNotAMatch}
                  handleMatches={handleMatches}   
                  handleIsADQ={handleIsADQ}                
                />                               
              ))}          
          </Grid>
        </>
      )}
    </>
  );
}
