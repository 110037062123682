import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    // justifyItems: 'stretch',
    alignItems: "center",
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    padding: "20px",
    // cursor: "pointer",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    // fontWeight:"bold"
  },

  productSize: {
    textTransform: "capitalize",
  },
  buttonCreateNewContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "20px",
  },
  button: {},
}));
// import StoreIcon from "@material-ui/icons/Store";

export default function ProductToMatch({ product }) {
  const history = useHistory();
  const classes = useStyles();
  const handleCreateNewMatchClick = () => {
    const postData = {
      productID: product._id,
    };
    // console.log(postData);
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/create`;
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        // setProductMatching(response.data.data.stores);
        if (response.data.data) {
          history.push(`/matching-potential/${product._id}`);
        } else {
          console.log("/matching/create");
        }
      })
      .catch((err) => {
        alert("error");
      });
  };
  return (
    <div className={classes.container}>
      <img
        className={classes.productImage}
        alt={product.name}
        src={product.image_url}
      />
      <div className={classes.titleContainer}>
        {/* <div className={classes.productName}>
          Brand: <b>{product.brandName}</b> 
        </div> */}
        <div className={classes.productName}>
          Name: <b>{product.fullname}</b>
        </div>
        <div className={classes.productSize}>
          Size: <b>{product.size}</b>
        </div>
      </div>
      <div className={classes.buttonCreateNewContainer}>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          onClick={handleCreateNewMatchClick}
        >
          Create a new match
        </Button>
      </div>
    </div>
  );
}
// export const MemoizedProductToMatch = React.memo(ProductToMatch);
