import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

import ListItem from "./ListItem";
import ProductMatchingGroupListLoading from "./ProductMatchingGroupListItemLoading";

import SearchBox from "./SearchBox";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({  
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function MergeGroupsSearch(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(10000);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // console.log('here'+searchText);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/groups?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        { q: searchText },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      
      
      if (skip === 0) {
        setGroups(result.data.data);
      } else {
        //   console.log(result.data.data);
        //   console.log('herer');
        setGroups([...groups, ...result.data.data]);
      }

      setLoadMore(result.data.data.length === limit);
      setIsLoading(false);
    };

    fetchData();
  }, [searchText, skip, order, orderBy]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  const handleSortClick = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSkip(0);
  };
  const {currentProductMatchingGroup}=props;
  const doSelect = (productMatchingGroup)=>{
    const r = window.confirm("Do you really want to merge #"+currentProductMatchingGroup.id+" into #"+productMatchingGroup.id+"?"); 
    if(r == true){
      doMerge(productMatchingGroup.id,currentProductMatchingGroup.id)
    }
  }
  const doMerge = (groupId1,groupId2) => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/merge`;
      // console.log(postData);
      axios
        .post(url, 
          {newId: groupId1, oldId: groupId2},
          {
              headers: {
                'Authorization': JSON.stringify(store.getState().session.token)
              }
          })
        .then((response) => {
          // console.log(response);
          alert('success');
          window.location.reload(false);
          // props.doClose();
        })
        .catch((err) => {
          alert(err);
        });
        
  };
  return (
    <>      
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <SearchBox value={searchText} doSearch={doSearch} searchOnChange={false}/>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === "group_id"}
                        direction={orderBy === "group_id" ? order : "asc"}
                        onClick={() => handleSortClick("group_id")}
                      >
                        ID
                        {orderBy === "group_id" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>  
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={() => handleSortClick("name")}
                    >
                      Name eg.
                      {orderBy === "name" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "brand_name"}
                      direction={orderBy === "brand_name" ? order : "asc"}
                      onClick={() => handleSortClick("brand_name")}
                    >
                      Brand.
                      {orderBy === "brand_name" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === "size"}
                      direction={orderBy === "size" ? order : "asc"}
                      onClick={() => handleSortClick("size")}
                    >
                      Size eg.
                      {orderBy === "size" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((row, i) => (
                  // <span>{row.image_url}</span>
                  (row.id != currentProductMatchingGroup.id)&&
                  <ListItem key={i} productMatchingGroup={row} doSelect={doSelect} />
                ))}
                {loadMore && (
                  <ProductMatchingGroupListLoading doLoadMore={doLoadMore} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
