import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Clear from '@material-ui/icons/Clear';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LoginPage from '../Login/LoginPage';


import { Helpers } from '../../utility/helpers';
import { Utility } from '../../utility/utility';
import Grid from '@material-ui/core/Grid';
import ReactToPrint from "react-to-print";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import ErrorMessage from '../Error/ErrorMessage';
import ArrowDropDownSharp from '@material-ui/icons/ArrowDropDownSharp';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { Hidden } from '@material-ui/core';

import ReactGA from 'react-ga';

import ProductMatch from '../PriceComparison/ProductMatch';
import { addSavedCart } from '../../actions/session';
import { setSavedCartSuccess } from '../../actions/user';
import { startPriceCompare, updateProductPick, setSelectedStoreCart } from '../../actions/pricecomparison';

import { startRemoveProductToCart, startClearCart, updateProduct } from '../../actions/cart';

const ITEM_HEIGHT = 48;

const styles = theme => ({
  root: {
    width: '600px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]:{
      width: '350px',
      maxWidth: '95%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  productImage: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]:{
      width: 25,
      height: 25,
    }
  },
  card: {
    boxShadow: 'none'
  },
  small: {
    '& svg': {
        fontSize: 18
    }
  },
  listInstructions: {
    color: '#aaa',
    display: 'block',
    paddingTop: theme.spacing(2)
  },
  itemActions: {
    display: 'flex',
    alignItems: 'center',
    '@media(print)': {
      display: 'none'
    }
  },
  moneyBag: {
    fontSize: '1.4em',
  },
  seeComparisons: {
    padding: '0 22px',
    height: '40px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',  
  }, 
  header: {
    paddingTop: '20px',
    textAlign: 'center',
    paddingBottom: '20px'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',    
    fontSize: '28px',    
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }    
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',    
    fontSize: '14px',      
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    }    
  },
actionButton: {
    margin: '5px',
    padding: '5px',
    width: '90% !important',
    fontWeight: 'bold',  
    fontFamily: 'Montserrat',
  }, 
  
  companyLogo: {
    height: '60px',
    width: '180px',
    //margin: theme.spacing(1),
    cursor: 'pointer',
    display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '120px',
      // margin: 'auto',
    } 
  },
  storeName:{
    width:"180px",
    whiteSpace: "nowrap", 
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      width:"65px",
      fontSize: '14px',
    } 
  },
  matchImage: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1)
  },

  product: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    //justifyContent: 'center',
    alignItems: 'center', 
    
  },
  productImg: {
    flexBasis: '10%',
    
  },
  productInfo: {
    flexBasis: '70%',
  
    textAlign: 'left'
  },
  productPrice: {
    flexBasis: '20%',    
    textAlign: 'right'
  },
  moreMatches: {
    justifyContent: 'left !important',
    fontWeight: 'bold'
  },
  divider: {
    height: '2px',
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: '#ccc',    
  }, 
  totalCost: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '60px',
    alignItems: 'center',
  }, 
  totalCostText: {
    flexBasis: '50%',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    }  
  }, 
  totalCostValue: {
    flexBasis: '50%',    
    textAlign: 'right'
  }, 
  missingProductsMessage: {
    textAlign: 'center',    
    paddingTop: '10px'
  },
  missingProductsMessageText: {    
    fontWeight: 'bold'
  },
  listOtherStore:{
    paddingLeft:"0px",
    paddingRight:"0px"
  },
  otherStore: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '60px', 
    border: "1px solid #ccc",  
    borderRadius:'4px', 
    alignItems: 'center',
  }, 
  otherStoreInfo: {
    display:'flex',
    justifyContent:"flex-start",
    alignItems: 'center',
  }, 
  otherStoreTotalCostValue: {
    flexBasis: '50%',    
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',      
    } 
  }, 
  otherStoreTotalControl: {
    flexBasis: '20%',    
    textAlign: 'center',
    margin: 'auto',
    marginRight: '2px'
  },
  buttonText: {    
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    }    
  } 
});

class CartStoreNew extends Component {

  constructor (props) {
    super(props);

    this.state = {
      anchorEl: null,
      selectedProduct: null,
      showSavedCartSuccess: false,
      isCartNameModalOpen: false, 
      isMenuOpen: false,   
      cartName: '', 
      priceComparison: undefined,
      errors: {}
    };
    this.state.priceComparison = this.props.pricecomparison;
    ReactGA.pageview('/YourListAtStore');

  }

  refTop = React.createRef();
  
  componentDidMount () {    
    this.loadPage();    
  }

  

  loadPage = () => {         
    
    // if (localStorage.getItem('pricecomparison')) {      
    //   const localPriceComparison = JSON.parse(localStorage.getItem('pricecomparison'));       

    //   this.setState({
    //     priceComparison: localPriceComparison,        
    //   })    
    // }  
    
  }

  removeProduct = (product) => {
    this.props.startRemoveProductToCart(product);
  }

  onHideSavedCartSuccess = () => {
    this.setState({
      isCartNameModalOpen: false,
      cartName: ''
    })
    this.props.setSavedCartSuccess(false)
  }

  renderSubHeader = () => {
    const productCount = this.props.cart.length;
    return `${productCount} item${(productCount > 1 || productCount == 0) ? 's' : ''}`;
  }

  processProduct = () => {
    const { customSeeMySavingsHandlerFn } = this.props;

    // customSeeMySavingsHandlerFn={ () => { if (customSeeMySavingsHandlerFn) { customSeeMySavingsHandlerFn() } } }

    if (customSeeMySavingsHandlerFn) {
      customSeeMySavingsHandlerFn()
    } else {
      this.props.history.push("/pricecompare");      
    }

  }

  onCartNameChange = (e) => {
    this.setState({
      cartName: e.target.value
    })
  }

  closeCartDrawer = () => {
    this.setState({ isCartDrawerOpen: false });
  }

  openLoginPageModal = () => {
    this.setState({ isLoginModalOpen: true });
  }
  cloaseLoginPageModal = () => {
    this.setState({ isLoginModalOpen: false });
  }

  openCartNameModal = () => {
    this.setState({ isCartNameModalOpen: true });
    // console.log("setting to true");
  }

  closeCartNameModal = () => {
    this.setState({ isCartNameModalOpen: false });
    // console.log("setting to false");    
  }  

  increaseQuantity = (product) => {
    product.quantity++;
    this.props.updateProduct(product);
  }

  decreaseQuantity = (product) => {
    product.quantity--;
    this.props.updateProduct(product);
  }

  onSaveCartHandler = () => {
    const { session } = this.props;
    // console.log("in save cart handler");
    if (!Helpers.isLoggedIn(session.user)) {
      this.openLoginPageModal();
      return;
    }

    this.openCartNameModal();
  }

  startCartSaving = () => {
    const { session, cart } = this.props;
    
    if (!this.validateForm()) {
      return;
    }

    const cartForSaving = [];
    for (let product of cart) {
      const p = {...product};
      p.sourceProductID = product._id;
      cartForSaving.push(p);
    }

    this.props.addSavedCart(this.state.cartName, cartForSaving);
  }

  validateForm = () => {
    let isFormValid = true;
    let errors = {};

    if (!Utility.isSet(this.state.cartName) || !this.state.cartName || this.state.cartName.length === 0) {
      errors['cartName'] = `Cart Name is required`;
      isFormValid = false;
    }

    this.setState({
      errors: errors
    });

    return isFormValid;
  }

  onLoginSuccessHandler = () => {
    this.cloaseLoginPageModal();
    this.onSaveCartHandler();
  }  

  openProductMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  closeProductMenu = () => {
    this.setState({ anchorEl: null });
  };
  chooseProduct = (product) => {
    let newProduct = {...product};
    //newProduct.quantity = this.state.selectedProduct.quantity;    
    // console.log(newProduct);
    this.setState({ anchorEl: null, selectedProduct: newProduct });
    //this.props.onProductPick(newProduct);
  }

  isRegularPriceAndCurrentPriceDifferent = (product) => {    
    return product.pricing.currentPrice !== product.pricing.regularPrice;
  }

  pricingText = (product) => {
    const { classes } = this.props;            
    if (!product) {
      return '';
    }
    
    return (
      <div>
       <span className={ classes.productPrice } >{ product.priceDisplayText1 != 'Flyer Item' ? product.priceDisplayText1 : '' }</span> 
        &nbsp;&nbsp;
        {
          (product.isOnSale && (product.pricing.discountType != 5) && this.isRegularPriceAndCurrentPriceDifferent(product) ) && (
            <span className={ classes.regularPrice }>{ Utility.isEmpty(product.pricing.regularPrice) ? '$' + product.pricing.regularPrice : '' }</span>
          )
        }
      </div>
    );
  }

  onProductPick = (product, store, row, col) => {
    // console.log('onProductPick');
    // console.log(product);
    // console.log(row);
    // console.log(col);
   this.props.updateProductPick(product, store, row, col);
 }

  renderMatchedProductList = (store, products) =>{
    const { classes } = this.props;
    const { anchorEl, selectedProduct } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <div>
        {                    
          products && products.matches.map((product, index) => {                                 
            return (
              <div key={index}>
                <div >
                  <ProductMatch 
                    //showRadioButton={showRadioButton}
                    showPickButton={false}
                    onProductPick={ (product) => { this.onProductPick(product, store , index, 0) }}
                    //isLoading={ isLoading }
                    productMatchData={ product }
                    showQuantity={true}
                    layout ='list'
                  />                  
                </div>                                                                                             
              </div>              
            )                                          
          })
        }
      </div>
    )
  }

  renderTotalListCost = (products) =>{
    const { classes } = this.props;
    let totalCost = 0;   
    products.map(product =>{
      if (product.selectedProduct){        
        totalCost = totalCost + product.selectedProduct.pricing.currentPrice;             
      }
    })
    return (
      <div>
        <br />
        <Divider className={ classes.divider } />
        <div className={ classes.totalCost }>         
          <div className={ classes.totalCostText }> 
            <Typography className = { classes.totalCostText } color='primary'>
              Total list cost 
            </Typography>
          </div>
          <div className={ classes.totalCostValue }> 
            <Typography className = { classes.totalCostText } color='secondary'>
              ${this.getTotal(products)}            
            </Typography>
          </div>
        </div>  
      </div>
      
    )
  }

  renderMissingProductList = (store, products) =>{
    const { classes } = this.props;
    const { anchorEl, selectedProduct } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const cart = JSON.parse(localStorage.getItem('cart'));
    const missingProducts = [];
    
    if (!products){
      return ''
    }

    for (let product of products.missing){     
      for (let item of cart.items){
        if (item.name === product.name){
          missingProducts.push(item);
          break;
        }        
      }
    }    
    if (missingProducts.length==0){
      return ''
    }

    return (
      <div>
        <Divider className={ classes.divider } />
        <div className={ classes.missingProductsMessage}>
          <Typography color="primary" variant="body1" className={ classes.missingProductsMessageText}>
            We couldn’t perfectly match these products at {store.companyData.name}, 
            click more options to select a different product from this store:
          </Typography>
        </div> 
        <List component="nav"  ref={el => (this.componentRef = el)}>
          {                    
            missingProducts.map((product) => {   
              //console.log(product);                    
              return (
                <ListItem key={product._id} disableGutters={true}>
                  <div className={ classes.product }>
                    <div className={ classes.productImg }>
                      <img className={ classes.productImage } 
                          src={ product.imageURL.replace('http:','https:') } 
                      />
                    </div>
                    <div className={ classes.productInfo }>                              
                      <Typography variant="body1" style={{ flex: 1 }} gutterBottom={true}>
                        { Helpers.fullProductNameWithBrand(product) } { product.size }     
                        <Hidden smUp>
                          { 
                            Helpers.isSpecialDiscount(product) ? Helpers.dealText(product) : this.pricingText(product) 
                          }                          
                        </Hidden>                                                                                                                                         
                      </Typography>                         
                    </div>
                    <Hidden smDown>
                    <div className={ classes.productPrice }>                              
                      <Typography variant="body1" color={ product.isOnSale ? 'secondary' : 'secondary' }>
                        { 
                          Helpers.isSpecialDiscount(product) ? Helpers.dealText(product) : this.pricingText(product) 
                        }
                      </Typography>

                      <Typography variant="body1" color={ product.isOnSale ? 'secondary' : 'secondary' }>
                        { 
                          Helpers.isSpecialDiscount(product) ? this.pricingText(product) : Helpers.dealText(product)
                        }
                      </Typography>                      
                    </div>                                                                               
                    </Hidden>
                  </div>                                            
                </ListItem>
              )                                          
            })
          }
        </List>
      </div> 
    )
    
  }

  onSelectStoreCart =(store) =>{
    this.props.setSelectedStoreCart(store);    
    this.props.showSelectedStore();
  }

  
  getTotal = (matchedProducts) => {    
    let total = 0;
    if (!matchedProducts){
      return 0;
    }
    matchedProducts.map((matchedProduct, index) => {
      const product = matchedProduct.selectedProduct;
      const quantity = matchedProduct.quantity;

      if (product) {
        total += Helpers.calculateRealPrice(product) * quantity;
      }
    });
    return Helpers.asDecimalText(total);
  }

  countMissingItem = (matchedProducts) => {    
    let missingProducts = 0;
    if (!matchedProducts){
      return 0;
    }    
    if (matchedProducts && matchedProducts.matches){
      for (let productMatch of matchedProducts.matches) {      
        if (!productMatch.selectedProduct) {
          missingProducts++;
        }
      }
    }    
    return missingProducts;
  }

  renderOtherStoreList = (otherPriceComparisonResults) =>{
    const { classes } = this.props;
    
    return (
      <div>
        <div className={ classes.header }>  
          <Divider className={ classes.divider } /> 
          <br />
          <Typography className={ classes.title }>
            This list at other stores:
            </Typography>       
        </div>            
        <List>
          {
            otherPriceComparisonResults.map(otherPriceComparisonResult => {
              let countMissing = this.countMissingItem(otherPriceComparisonResult); 
              return (
                <ListItem key={otherPriceComparisonResult.store.storeID}
                  onClick={ () => this.onSelectStoreCart(otherPriceComparisonResult.store) }
                  className={classes.listOtherStore}
                >                                    
                  <div className={ classes.otherStore }> 
                    <div className={ classes.otherStoreInfo }> 
                      <img className={ classes.companyLogo } 
                          src={ otherPriceComparisonResult.store.companyData.companyLogoLink } 
                      />
                       <span className={classes.storeName}>
                        {otherPriceComparisonResult.store.name}
                      </span>
                    </div>
                    <div className={ classes.otherStoreTotalCostValue }> 
                      <Typography className = { classes.totalCostText } color='secondary'>
                        ${this.getTotal(otherPriceComparisonResult.matches)}
                      </Typography>     
                      {
                        (countMissing > 0) && (
                          <Typography variant="caption" align="center" color="error">*miss<Hidden smDown>ing</Hidden> {countMissing} <Hidden smDown>product{countMissing > 1 ? 's' : ''} </Hidden></Typography>
                        )
                      }                   
                    </div>
                    <div className={ classes.otherStoreTotalControl }> 
                      <Button 
                        onClick={ () => this.onSelectStoreCart(otherPriceComparisonResult.store) }
                        //className={ classes.viewAtStore }
                        color="primary"
                        variant="contained"                  
                        //fullWidth={ true } 
                      >
                        <Typography
                          color="inherit"
                          className = { classes.buttonText }
                        >
                          View 
                        </Typography>
                      </Button>
                    </div>                    
                  </div> 

                </ListItem>
              );
            })
          }                  
        </List>
      </div>
    )
  }

  render () {
    const { classes, hideRemoveButtons, priceComparison } = this.props;    
    //const localPriceComparison = JSON.parse(localStorage.getItem('pricecomparison'));    
    const store = JSON.parse(localStorage.getItem('selectedstorecart'));  
    const dispalyData = priceComparison; 
    //const localPriceComparison = priceComparison;   
    //console.log(localPriceComparison);
    // console.log(priceComparison);
    
    const companyLogoLink = store.companyData.companyLogoLink;    
    const matchedProducts =[];      
    const missingProducts = dispalyData.priceComparisonResult.filter(priceComparison => priceComparison.store._id ===store._id)[0];
    const priceComparisonProduct = dispalyData.priceComparisonResult.filter(priceComparison => priceComparison.store._id ===store._id)[0];    
    
    let countMissing = this.countMissingItem(priceComparisonProduct);    
    if (priceComparisonProduct){
      priceComparisonProduct.matches.map(product =>{
        if (product.selectedProduct){
          matchedProducts.push(product);     
        }
      })
    }
    
        
    const otherPriceComparisonResults = dispalyData.priceComparisonResult.filter(priceComparison => priceComparison.store._id !==store._id)   
    
    //console.log(this.props.pricecomparison.priceComparisonResult);
    
    return (
      <div className={ classes.root } >        
            <div className={ classes.header } >   
              <Typography className={ classes.title }>
                Your shopping cart at:
              </Typography>
              <img src={companyLogoLink} className={ classes.companyLogo } style={{margin:"auto"}}/> 
                <Typography variant="caption" align="center">{store.name}</Typography> <br/> 
              {
                (countMissing > 0) && (
                  <Typography variant="caption" align="center" color="error">* missing {countMissing} product{countMissing > 1 ? 's' : ''}</Typography>
                )
              }     
            </div>
                      
            {
              matchedProducts.length > 0 ? (                                                                           
                this.renderMatchedProductList(store, priceComparisonProduct)
              ) :
              (
                <Typography color="textSecondary" variant="caption" align="center">
                  Your list is empty
                </Typography>
              )
            }
                        
            {
              this.renderTotalListCost(matchedProducts)
            }          
            
           
            <div>
                                 
            {
              matchedProducts.length && (                                                                           
                this.renderMissingProductList(store, missingProducts) 
              )             
            }
            </div>
            {
              (otherPriceComparisonResults.length>0) && (
                this.renderOtherStoreList(otherPriceComparisonResults)
              )
            }
           
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,  
  cart: state.cart.items,
  pricecomparison: state.pricecomparison,
  user: state.user
});

const mapDispatchToProps = (dispatch, props) => ({
  startRemoveProductToCart: (product) => dispatch(startRemoveProductToCart(product)),
  startPriceCompare: (params) => dispatch(startPriceCompare(params)),
  startClearCart: () => dispatch(startClearCart()),
  updateProduct: (product) => dispatch(updateProduct(product)),
  updateProductPick: (product, store, row, col) => dispatch(updateProductPick(product, store, row, col)),
  addSavedCart: (cartName, products) => dispatch(addSavedCart(cartName, products)),
  setSelectedStoreCart: (store) => dispatch(setSelectedStoreCart(store)),
  setSavedCartSuccess: (showSavedCartSuccess) => dispatch(setSavedCartSuccess(showSavedCartSuccess))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(CartStoreNew)));