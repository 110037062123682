import React, { useState, useEffect } from "react";
import { useHistory, Link} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import ButtonAdd from "./CompetitorProducts/ButtonAdd";
import ButtonDelete from "./CompetitorProducts/ButtonDelete";
import SearchBox from "./Products/SearchBox";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  searchContainer: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export default function CompanyReportToolCompetitorProductPage(props) {
  const history = useHistory();
  const classes = useStyles();  
  const companyReportToolProductId = props.match.params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(1000);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name")
  const [filter, setFilter]=useState({q:''})
  
  const [companyReportToolProduct, setCompanyReportToolProduct] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceSearch, setDataSourceSearch] = useState([]);
  const [reloadData, setReloadData] = useState(0);
  const [searchText, setSearchText] = useState('');

  const token = useSelector((state) => state.session.token);
  
  const loader = document.querySelector(".loader");
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };
  const doReloadData =()=>{setReloadData(reloadData+1)}
  useEffect(() => {
    
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/competitor/products?id=${companyReportToolProductId}&orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(url,filter, {
        headers: {
          Authorization: token,
        },
      });
      setCompanyReportToolProduct(result.data.data.product);
      setDataSource(result.data.data.competitors);
      // setDataSourceSearch(result.data.data);
      setIsLoading(false);
      hideLoader();
    };
    fetchData();
  
}, [filter,skip, order, orderBy,reloadData]);
const handleSort=(column)=>{
  setOrder(order==='desc'?'asc':'desc');
  setOrderBy(column);
  setSkip(0);
}
useEffect(() => {
  if (searchText.length > 0) {
    let dataSourceFilterTemp = dataSource.filter(function(product) {
      if (product.name.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    });
    setDataSourceSearch([...dataSourceFilterTemp]);
  } else {
    setDataSourceSearch([...dataSource]);
  }
}, [searchText,dataSource]);

const doSearch = (searchValue) => {
  setSearchText(searchValue);
};
  return (
    <>
     {/* <CssBaseline /> */}
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Competitor/Similar Products 
          </Typography>   
          <ButtonAdd product={companyReportToolProduct} reloadData={doReloadData}/>           
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <>
        <Grid
          className={classes.searchContainer}
          container
          alignItems="center"
          justifyContent="center"
        >
          <SearchBox value={searchText} doSearch={doSearch} searchOnChange={true} placeholder="Search"/>
         </Grid>
        <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
      >
          
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader aria-label="simple table">
            <TableHead >
              <TableRow>
                {/* <TableCell align="center">data_year_week</TableCell> */}
                <TableCell align="left">
                  #                
                </TableCell>
                <TableCell align="left">
                  Command                
                </TableCell>
                <TableCell align="left">
                  Group Id                
                </TableCell>       
                <TableCell align="left" >                    
                  <SortLabel onClick={()=>handleSort('name')}>Name</SortLabel>
                </TableCell>                         
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSourceSearch.map((row, i) => (
                <>
                  <TableRow>
                    <TableCell align="left">
                      {i+1}
                    </TableCell>
                    <TableCell align="left">
                    <ButtonDelete record_id={row.id} reloadData={doReloadData}/> 
                    </TableCell>
                    <TableCell align="left">
                      {row.productMatchingGroup.id}
                    </TableCell>
                    <TableCell align="left">{row.productMatchingGroup.fullname}</TableCell>                    
                  </TableRow>                   
                </>
              ))}
            </TableBody>             
          </Table>
        </TableContainer>
      </Grid>
      </>
      )}
    </>
  );
}
