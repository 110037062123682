import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import StoreLogo from '../StoreLogo/StoreLogo';
import ProductMatch from './ProductMatch';

import { Helpers } from '../../utility/helpers';

import Slider from "react-slick";
import { Hidden } from '@material-ui/core';

// const SampleNextArrow=(props)=> {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", border: none }}
//       onClick={onClick}
//     />
//   );
// }
// const SamplePrevArrow=(props) =>{
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block"  }}
//       onClick={onClick}
//     />
//   );
// }

const sliderSettings = {
  // lazyLoad: 'ondemand',
  dots: true,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  //nextArrow: <SampleNextArrow />,
  //prevArrow: <SamplePrevArrow />,
  // centerMode: true,
  // autoplay: true,
  // autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        arrows: false,
      }
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        arrows: false,
      }
    }
  ]
};

const styles = theme => ({
  root: {
    width: '100%',
    // marginLeft: 'auto',
    // marginRight: 'auto'
    // margin: theme.spacing(2)
    boxSizing: 'border-box',
    //position: 'fixed',
    //top: '0',
  },
  rootVerticalLayout: {
    width: `calc(100% - 250px)`,
    boxSizing: 'border-box',
    display: 'inline-flex', 
    //border: '1px solid #eee',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },  
  productMissing: {
    color: '#eee'
  },
  header: {
    border: '1px solid #eee',
  },
  storeHeader: {
    height: '120px'
  }, 
  storeFooter: {
    backgroundColor: '#000',
    paddingBottom: 0,
    marginBottom: 0,
  },

  storeSummaryVertiacalLayout: {
    border: '1px solid #eee',
    height: '250px',
    display: 'flex', 
    flexDirection:'column',  
    alignItems: 'center'       
  },
  storeHeaderVertiacalLayout: {
    height: '70%',    
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      //height: '80%',
    }
  }, 
  storeControlVertiacalLayout: {
    height: '15%',   
  },
  storeFooterVertiacalLayout: {
    backgroundColor: theme.palette.primary.main,    
    height: '15%', 
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      //height: '20%', 
      display: 'block',
    //justifyContent: 'center',
    //alignItems: 'center',
    }             
  },
  storeFooterTextVertiacalLayout: {
    color: '#fff',
    fontWeight: 'bold',    
    fontSize: '18px',    
    fontFamily: 'Montserrat',            
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px'
    }
  },
  productMatch: {
    width: '100%',
  },
  totalPriceMobile: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main
  },
  
  slider: {
    paddingLeft: '0px',
    paddingRight: '0px',    
    '& .slick-dots': {      
      display: 'none !important'
    },
    '& .slick-prev, .slick-next': {       
      boxShadow: '0 0 0 !important',
      borderRadius:'0 !important',
      backgroundColor: 'transparent !important',
      //width: '0px'
    }
  },
  buttonText: {
    fontWeight: 'bold',    
    fontSize: '12px',    
    fontFamily: 'Montserrat',    
    textTransform: 'capitalize',        
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px'
    }
  },
  storeLogo: {
    width: '200px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      transform: 'rotate(-90deg)',
      marginLeft: '-50px',
      marginTop: '70px',
    }
  },
  storeName: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {      
      transform: 'rotate(-90deg)',
      marginLeft: '30px',
      marginTop: '1px',
    }
  },
});

class StoreBestProductMatch extends Component {

  constructor () {
    super();
  }

  // calculateRealPrice = (product) => {
  //   const productPricing = product.pricing;
  //   let price = productPricing.currentPrice;

  //   // 3 = X for $X discount
  //   if (productPricing.discountType === 3) {
  //     price = productPricing.discountAmount / (productPricing.discountCondition ? productPricing.discountCondition : 1);
  //   }

  //   // 4 = Buy X get X free
  //   if (productPricing.discountType === 4) {
  //     price = productPricing.currentPrice / productPricing.discountAmount + (productPricing.discountCondition ? productPricing.discountCondition : 0);
  //   }

  //   // 5 = Bulk Discount ($5 MIN 2)
  //   if (productPricing.discountType === 5) {
  //     price = productPricing.currentPrice;
  //   }

  //   return price;
  // }

  // roundTo2Decimal (num) {
  //   return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  // }

getTotal = () => {
    let total = 0;
    this.props.matches.map((productMatch, index) => {
      const product = productMatch.selectedProduct;
      const quantity = productMatch.quantity;

      if (product) {
        total += Helpers.calculateRealPrice(product) * quantity;
      }
    });
    return Helpers.asDecimalText(total);
}

renderHozirontalLayout =() => {
  const { classes, store, hideTotals, showQuantity, showRadioButton, showPickButton, isLoading, onProductPick } = this.props;
    let showNote = false;
    let missingProducts = 0;
    

    for (let productMatch of this.props.matches) {
      if (productMatch.selectedProduct && productMatch.selectedProduct.pricing.discountType >= 3) {
        showNote = true;
      }

      if (!productMatch.selectedProduct) {
        missingProducts++;
      } 
    }
    
    return (
      <div className={ classes.root }>
        <div className={ classes.header }>
        <StoreLogo store={store} company={store.companyData}></StoreLogo>
        {
          (!hideTotals && !isLoading) && (
            <div className={ classes.storeHeader }>
              <Typography variant="h6" align="center">Total cost</Typography>
              <Typography variant="h3" align="center" color='secondary'><span className={ (missingProducts > 0 )? classes.missingProducts : classes.allProducts}>${ this.getTotal() }</span></Typography>
              {
                showNote && (
                  <Typography variant="caption" align="center">* includes a 2 for 1 deal or similar</Typography>
                )
              }
              {
                (missingProducts > 0) && (
                  <Typography variant="caption" align="center" color="error">* missing {missingProducts} product{missingProducts > 1 ? 's' : ''}</Typography>
                )
              }              
            </div>
          )
        }    
        </div>    
        {
          this.props.matches.map((productMatch, index) => {
            let i = index;

            return (
              <div className={classes.product}>
                <ProductMatch 
                  showRadioButton={showRadioButton}
                  showPickButton={false}
                  onProductPick={ (product) => { onProductPick(product, i) }}
                  isLoading={ isLoading }
                  productMatchData={ productMatch }
                  showQuantity={true}
                />
                <br/>
              </div>
            )
          })
        }

      </div>
    );
}

renderVertiacalLayout =() => {
  const { classes, store, hideTotals, showQuantity, showRadioButton, showPickButton, isLoading, onProductPick, totalCartProducts } = this.props;
    let showNote = false;
    let missingProductCount = 0;    
    let matchedProductCount =0;
    const matchedProducts =[];
    for (let productMatch of this.props.matches) {
      if (productMatch.selectedProduct && productMatch.selectedProduct.pricing.discountType >= 3) {
        showNote = true;        
      }

      if (!productMatch.selectedProduct) {
        missingProductCount++;
      } else {
        matchedProductCount++;                
      }
      matchedProducts.push(productMatch);
      
    }
    
    return (
      <div className={ classes.rootVerticalLayout }>
        { (!hideTotals && !isLoading) && (
          <div className={ classes.storeSummaryVertiacalLayout }>                            
            <div className={ classes.storeHeaderVertiacalLayout }>
              <img className={ classes.storeLogo } src={ store.companyData.companyLogoLink } />
              <Typography className={ classes.storeName } variant="subtitle2" gutterBottom={true} noWrap={true}>
                { store.name }
              </Typography>

                <Hidden smDown>
                  <Typography variant="h6" align="center">Total cost</Typography>
                  <Typography variant="h4" align="center" color='secondary'>
                    <span className={ (missingProductCount > 0 )? classes.missingProducts : classes.allProducts}>${ this.getTotal() }</span>
                  </Typography>
                  {
                    showNote && (
                      <Typography variant="caption" align="center">* includes a 2 for 1 deal or similar</Typography>
                    )
                  }                               
                </Hidden>  
              </div>
              <div className={ classes.storeControlVertiacalLayout }>                                             
                <Button 
                    onClick={ () => this.props.selectStoreCart(store) }
                    //className={ classes.viewAtStore }
                    color="secondary"
                    variant="contained"                  
                    //fullWidth={ true } 
                  >
                    <Typography
                      color="inherit"
                      className = { classes.buttonText }
                    >
                      View <Hidden smDown>this store</Hidden>
                    </Typography>
                </Button> 
            </div>                       
            <div className={ classes.storeFooterVertiacalLayout }>              
              <Typography className={ classes.storeFooterTextVertiacalLayout } variant="caption" align="center" color="error">
                {matchedProductCount} of {totalCartProducts} <Hidden smDown>product{matchedProductCount > 1 ? 's' : ''}</Hidden>
              </Typography>
              <Hidden smUp>
                <div className={ classes.totalPriceMobile}>
                  <Typography align="center" className={ classes.storeFooterTextVertiacalLayout }>
                    <span className={ (missingProductCount > 0 )? classes.missingProducts : classes.allProducts}>${ this.getTotal() }</span>
                  </Typography>
                </div>
              </Hidden>    
            </div>                                                            
          </div>  
        )    
      }        
      <div className={ classes.productMatch }>
        <Slider {...sliderSettings} className={classes.slider}>
            {
              matchedProducts.map((productMatch, index) => {
                let i = index;            
                return (
                  <div key={i} className={classes.product}>
                    { <ProductMatch 
                      showRadioButton={showRadioButton}
                      showPickButton={false}
                      onProductPick={ (product) => { onProductPick(product, i) }}
                      isLoading={ isLoading }
                      productMatchData={ productMatch }
                      showQuantity={true}
                      layout='cart-hozirontal'
                    /> }
                    <br/>
                  </div>
                )
              })
            }
        </Slider> 
      </div>
    </div>    
  );                                                                       
}

  render () {
    return (
      //this.renderHozirontalLayout()
      this.renderVertiacalLayout()
    );      
  }
}


export default withRouter(withStyles(styles)(StoreBestProductMatch));
