import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import ButtonEdit from "./Item/ButtonEdit";
import ButtonDelete from "./Item/ButtonDelete";
const useStyles = makeStyles((theme) => ({
itemContainer:{
    height:'100px',
    
},
buttonLink: {
    width:"100%",
    height:"100%",
    // padding:".5rem",
    backgroundColor: "white",
    color: "#000",
    textAlign: "center",
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#DDD",
    //   color: "#FFF",
    },
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
  },  
}))
export default function SessionItem({ item, session, editMode , reloadData}) {
  const classes = useStyles();
  return (
    <div className={classes.itemContainer}>
     {!editMode && (
      <a href={item.url} target="_blank" className={classes.buttonLink}>
        <div style={{fontWeight:"bold"}}>{item.name}</div>
        {item.description&&<p>{item.description}</p>}
      </a>
     )}
      {editMode && (
        <>
            <div>
            <b>{item.name}</b><br/>
            {item.description&&<p><i>{item.description}</i></p>}
            </div>
            <ButtonEdit item={item} session={session} reloadData={reloadData}/>
            <ButtonDelete item={item} reloadData={reloadData}/>
        </>
      )}
    </div>
  );
}
