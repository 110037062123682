import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  TextField
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogEdit({open, onClose, project, reloadData}) {
  const token = useSelector((state) => state.session.token);
  const initState = {id:null, code:'',name:'',description:'',sort_order:0}
  const [inputState, setInputState] = useState(project||initState);
  const [serverUpdating, setServerUpdating] = useState(false);
  const handleChange = (event) => {
    const inputName = event.target.name;
    setInputState({
      ...inputState,
      [inputName]: event.target.value,
    });
  };
  const onSave = () => {
    setServerUpdating(true)
  };
  useEffect(() => {
    const serverUpdate = async () => {
      let putData = {
        ...inputState,
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/update-project`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setServerUpdating(false)
      setInputState(initState)
      reloadData()
      onClose()
    };
    serverUpdating&&serverUpdate();
  }, [serverUpdating]);
  return (
      <Dialog
        //fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="Update Company/Project"
      >
        <DialogTitle>{project?`Update - ${project.name}`:'Add Company/Project'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            label="Code"
            // placeholder="Who do you want to share this with?"
            fullWidth
            onChange={handleChange}
            value={inputState.code}   
            inputProps={{
              name: "code",
              id: "input-code",
              required: false,
            }}
          />  
          <TextField
            autoFocus
            required
            label="Name"
            // placeholder="Who do you want to share this with?"
            fullWidth
            onChange={handleChange}
            value={inputState.name}   
            inputProps={{
              name: "name",
              id: "input-name",
              required: true,
            }}
          />          
          <TextField
            label="Description"
            multiline
            rows={4}
            fullWidth
            onChange={handleChange}
            value={inputState.description}
            inputProps={{
              name: "description",
              id: "input-description",
              required: false,
            }}            
          />          
        </DialogContent>
        <DialogActions>
          <Button onClick={onSave}>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
