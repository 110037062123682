import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));


export default function ReviewPanelNoSelectedGroup({
}) {
  const classes = useStyles();
  
  return (    
        <Grid
          className={classes.root}
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <div>No Group is selected</div>
        </Grid>    
  );
}
