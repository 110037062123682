import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import ZoomInIcon from '@material-ui/icons/ZoomIn';

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
  toolbarRight: {
    minHeight: "64px",
  },
  toolbarTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  reviewPanel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  },

  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  coopItem: {
    fontWeight: "bold",
    fontSize: "1em",
    backgroundColor: "#DDD",
  },
  matchedItem: {
    backgroundColor: "rgba(100, 235, 147, 0.4)",
  },
  missingItem: {
    backgroundColor: "rgba(255, 90, 110, 0.4)",
  },
  extraItem: {
    backgroundColor: "rgba(255, 207, 89, 0.4)",
  },
  selectStore: {
    color: "white",
  },
}));

export default function StoreTestResult() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [storeMatchReport, setStoreMatchReport] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [storeSelected, setStoreSelected] = useState({ store_id: 0 });
  const loader = document.querySelector(".loader");
  // const updateContainer = document.querySelector(".updateContainer");

  // if you want to show the loader when React loads data again
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/test/store-match`;
      const result = await axios.post(
        url,
        {
          store_id: storeSelected.store_id,
        },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      setStoreMatchReport(result.data.data);
      setIsLoading(false);
      hideLoader();
      // dispatch(setCategories(categories));
    };
    (storeSelected.store_id>0) && fetchData();
  }, [storeSelected]);
  useEffect(() => {
    const fetchData = async () => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/test/store-list`;
      const result = await axios.get(url, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      });
      setStoreList(result.data.data);
      result.data.data[0] && setStoreSelected(result.data.data[0]);
      // dispatch(setCategories(categories));
    };
    fetchData();
  }, []);
  const handleSelectStore = (e) => {
    console.log(e.target.value)
    setStoreSelected(
      storeList.find((item) => item.store_id == e.target.value)
    );
  };
  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Store match report
          </Typography>
          <Select
            className={classes.selectStore}
            value={storeSelected.store_id}
            onChange={handleSelectStore}
          >
            {/* <MenuItem value="0">
              <em>None</em>
            </MenuItem>  */}
            {storeList &&
              storeList.map((store) => (
                <MenuItem value={store.store_id}>
                  <em>
                    {store.company_name}:{store.store_name}
                  </em>
                </MenuItem>
              ))}
          </Select>
        </Toolbar>
      </AppBar>
      {isLoading ?(
        <div>Loading...</div>
      ): (
      <main className={classes.content}>
        <div className={classes.toolbarRight} />
        {storeSelected.store_id && (
          <div className={classes.reviewPanel}>
            <div>
              <b>Total Matching List:</b>
              {storeMatchReport.length}
              <br />
              <b className={classes.matchedItem}>Total Store Matched:</b>{" "}
              {storeMatchReport.filter((d) => d.spp_record).length}
              <br />
              <b className={classes.missingItem}>Total Store Missing:</b>{" "}
              {storeMatchReport.filter((d) => !d.spp_record).length}
              <br />
            </div>
          </div>
        )}
        {storeSelected.store_id && (
          <div className={classes.reviewPanel}>
            <table>
              <tr className={classes.coopItem}>
                <th style={{ textAlign: "right" }}></th>
                <th style={{ textAlign: "center" }} colSpan={5}>
                  Matching List
                </th>
                <th style={{ textAlign: "center" }} colSpan={5}>
                  {storeSelected.company_name}:{storeSelected.store_name}
                </th>
              </tr>
              <tr className={classes.coopItem}>
                <th style={{ textAlign: "right" }}>#</th>
                <th style={{ textAlign: "center" }}>company_name</th>
                <th style={{ textAlign: "center" }}>group_id</th>
                <th style={{ textAlign: "center" }}>product_upc</th>
                <th style={{ textAlign: "center" }}>product_name</th>
                <th style={{ textAlign: "center" }}>brand</th>
                <th style={{ textAlign: "center" }}>name</th>
                <th style={{ textAlign: "center" }}>size</th>
                <th style={{ textAlign: "center" }}>price</th>
                <th style={{ textAlign: "center" }}></th>
              </tr>
              {storeMatchReport.slice(0,50).map((storeMatch, index) => {
                let rowSpan = storeMatch.spp_record
                  ? storeMatch.spp_record.length
                  : 0;
                if (rowSpan == 0) {
                  return (
                    <tr
                      className={
                        storeMatch.spp_record
                          ? classes.matchedItem
                          : classes.missingItem
                      }
                    >
                      <td>{index + 1}</td>
                      <td>{storeMatch.company_name}</td>
                      {/* <td>{storeMatch.fcl_title}</td>
                      <td>{storeMatch.fcl_size}</td> */}
                      <td>
                        {storeMatch.product_matching_group_id && (
                          <a
                            href={`/matching-group/${storeMatch.product_matching_group_id}`}
                            target="_blank"
                          >
                            {`#${storeMatch.product_matching_group_id}`}
                          </a>
                        )}
                      </td>
                      <td>{storeMatch.product_upc}</td>
                      <td>{storeMatch.product_name}</td>
                      <td colSpan={4}></td>
                      <td>
                        <IconButton
                          aria-label="search"
                          className={classes.margin}
                        >
                          <SearchIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                } else {
                  let [firstRow, ...otherRows] = storeMatch.spp_record;
                  return (
                    <>
                      <tr
                        className={
                          storeMatch.spp_record
                            ? classes.matchedItem
                            : classes.missingItem
                        }
                      >
                        <td rowSpan={rowSpan}>{index + 1}</td>
                        <td rowSpan={rowSpan}>{storeMatch.company_name}</td>
                        <td rowSpan={rowSpan}>
                          {storeMatch.product_matching_group_id && (
                            <a
                              href={`/matching-group/${storeMatch.product_matching_group_id}`}
                              target="_blank"
                            >
                              {`#${storeMatch.product_matching_group_id}`}
                            </a>
                          )}
                        </td>
                        <td rowSpan={rowSpan}>{storeMatch.product_upc}</td>
                        <td rowSpan={rowSpan}>{storeMatch.product_name}</td>
                        <td>{firstRow.product_brand}</td>
                        <td>{firstRow.product_name}</td>
                        <td style={{ textAlign: "center" }}>
                          {firstRow.product_size}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          {firstRow.current_price_ea}
                        </td>
                        <td>
                          <IconButton
                            aria-label="view"
                            className={classes.margin}
                          >
                            <ZoomInIcon />
                          </IconButton>
                        </td>
                      </tr>
                      {otherRows &&
                        otherRows.map((otherRow) => (
                          <tr>
                            <td>{otherRow.product_brand}</td>
                            <td>{otherRow.product_name}</td>
                            <td>{otherRow.product_size}</td>
                            <td style={{ textAlign: "right" }}>
                              {otherRow.current_price_ea}
                            </td>
                            <td>
                              <IconButton
                                aria-label="view"
                                className={classes.margin}
                              >
                                <ZoomInIcon />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                    </>
                  );
                }
              })}
            </table>
          </div>
        )}
      </main>
      )}
    </div>
  );
}
