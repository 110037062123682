import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box'
const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.secondary.main,
  },
  button: {    
    marginRight:'8px',
  },
  textbox: {
    marginRight:'20px',
    width:'80px'
  }, 
}));

export default function ScoreFilter({doSearch}) {
  const classes = useStyles();
  const [inputState, setInputState] = useState({
    nameScore: 1,
    brandScore:1,
    sizeScore:1,
    unitScore:1
  });
  const handleChange = (event) => {
    const inputName = event.target.name;
    setInputState({
      ...inputState,
      [inputName]: event.target.value,
    });
  };
  const onSearchClick = () => {
    doSearch(inputState)
  };
  return (
    <>
      <TextField
        label="Name"
        variant="outlined"
        size="small"
        onChange={handleChange}
        inputProps={{
          inputMode: "decimal",
          name: "nameScore"         
        }}
        value={inputState.nameScore}
        margin="normal"
        className={classes.textbox}
      />
      <TextField
        label="Brand"
        variant="outlined"
        size="small"
        onChange={handleChange}
        inputProps={{
          inputMode: "decimal",
          name: "brandScore"         
        }}
        value={inputState.brandScore}
        margin="normal"
        className={classes.textbox}
      />
       <TextField
        label="Size"
        variant="outlined"
        size="small"
        onChange={handleChange}
        inputProps={{
          inputMode: "decimal",
          name: "sizeScore"         
        }}
        value={inputState.sizeScore}
        margin="normal"
        className={classes.textbox}
      />
      <TextField
        label="Unit"
        variant="outlined"
        size="small"
        onChange={handleChange}
        inputProps={{
          inputMode: "decimal",
          name: "unitScore"         
        }}
        value={inputState.unitScore}
        margin="normal"
        className={classes.textbox}
      />
       <Button
          color="primary"
          variant="contained"
          onClick={onSearchClick}
        >
          Search
        </Button>               
    </>
  );
}
