import React, { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Input from "@material-ui/core/Input";

import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";

import { fetchSuggestions } from "../../actions/suggestions";
import ReactGA from "react-ga";

const debounce = require("lodash.debounce");

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <div>
      <Input
        placeholder="Search for products"
        disableUnderline
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputRef={(node) => {
          ref(node);
          inputRef(node);
        }}
        // InputProps={{
        //   inputRef: node => {
        //     ref(node);
        //     inputRef(node);
        //   },
        //   classes: {
        //     input: classes.input,
        //   },
        // }}
        {...other}
      />
    </div>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  let label = suggestion.label;
  if (suggestion.category) {
    label += " in " + suggestion.category;
  }
  const matches = match(label, query);
  const parts = parse(label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          let suggestionHighlighted = part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );

          return suggestionHighlighted;
        })}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : this.props.suggestions.suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  //return suggestion.label;
  let label = suggestion.label;
  if (suggestion.category) {
    label += " in " + suggestion.category;
  }
  return label;
}

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    // marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  divider: {
    height: theme.spacing(2),
  },
  // searchIcon: {
  //   width: theme.spacing(9),
  //   height: "100%",
  //   position: "absolute",
  //   pointerEvents: "none",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  searchIcon: {
    height: "100%",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1000",
    top: "0px",
    right: "0px",
    backgroundColor: "#2650a2",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    color: "#fff",
  },
  iconButton: {
    padding: 10,
  },
  searchButtonText: {
    fontSize: "1.8em",
    fontWeight: "bold",
    color: "#fff !important",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: "8px",
    paddingRight: theme.spacing(1),
    paddingBottom: "8px ",
    paddingLeft: "8px !important",
    transition: theme.transitions.create("width"),
    fontSize: "1.3rem",
    lineHeight: "1.5rem",
    backgroundColor: "#f6f6f6",
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: "8px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(3.5),
      fontSize: "1.0rem",
      paddingLeft: theme.spacing(7),
      "&:focus": {
        border: "2px solid " + theme.palette.primary.main,
        borderRadius: "8px",
      },
    },
  },
});

class ProductAutoComplete extends Component {
  state = {
    single: "",
    popper: "",
    // ,
    suggestions: [],
  };

  constructor(props) {
    super(props);
    // console.log(debounce);
    // this.debouncedLoadSuggestions = debounce(this.loadSuggestions, 200);
    this.debouncedLoadSuggestions = debounce(
      this.debouncedLoadSuggestions.bind(this),
      1000
    );
  }

  debouncedLoadSuggestions = (value) => {
    this.props.fetchSuggestions(value);
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    // this.props.fetchSuggestions(value);
    //console.log(value);
    this.debouncedLoadSuggestions(value);

    // this.setState({
    //   suggestions: getSuggestions(value),
    // });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (name) => (event, { newValue, method }) => {
    //console.log('event changed'+ newValue);
    let val = newValue;
    if (newValue.substring(0, newValue.lastIndexOf(" in ") > 0)) {
      val = newValue.substring(0, newValue.lastIndexOf(" in "));
    }
    this.setState({
      [name]: val,
    });
    
    this.props.onSuggestionValueChanged(newValue);
  };
  onClearClick=()=>{
    this.setState({
      single: "",
    });
  }
 

  render() {
    const { classes, isLoadingTodaysDeals } = this.props;
    //console.log(this.props.suggestions.suggestions);
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.props.suggestions.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      focusInputOnSuggestionClick: false,
      highlightFirstSuggestion:true
    };

    return (
      <React.Fragment>
        <div className={classes.searchIcon}>
          {this.state.single.length > 0 ? (
            <Button
              className={classes.iconButton}
              aria-label="locate"
              onClick={this.onClearClick}
            >
              <ClearIcon className={classes.searchButtonText} />
            </Button>
          ) : (
            <Button
              className={classes.iconButton}
              aria-label="locate"
              // onClick={this.props.onSuggestionSelected}
            >
              <SearchIcon className={classes.searchButtonText} />
            </Button>
          )}
        </div>
        <div className={classes.root}>
          <Autosuggest
            disabled={isLoadingTodaysDeals}
            {...autosuggestProps}
            inputProps={{
              classes,
              placeholder: "Search for a product",
              value: this.state.single,
              onChange: this.handleChange("single"),
              // onKeyDown: this.onEnter,
            }}
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={(options) => (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            )}
            onSuggestionSelected={this.props.onSuggestionSelected}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  suggestions: state.suggestions,
  searchTerm: state.products.searchTerm,
  isLoadingTodaysDeals: state.products.isLoadingTodaysDeals,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchSuggestions: (searchTerm) => {
    dispatch(fetchSuggestions(searchTerm));
  },
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductAutoComplete)
);
