import axios from 'axios';
import envConfig from '../bin/env.config';

const setSuggestionsSearchString = (searchString = '') => ({
  type: 'SET_SUGGESTIONS_SEARCH_STRING',
  searchString
});

const setSuggestions = (suggestions = []) => ({
  type: 'SET_SUGGESTIONS',
  suggestions
});

export const clearSuggestions = () => ({
  type: 'CLEAR_SUGGESTIONS'
});


const clearCart = () => ({
  type: 'CLEAR_CART'
});

export const fetchSuggestions = (searchString = '') => {
  return (dispatch, getState) => {

    // setTimeout(() => {
    //   dispatch(setSuggestions([
    //     { label: 'Anguilla' },
    //     { label: 'Antarctica' },
    //     { label: 'Antigua and Barbuda' },
    //     { label: 'Argentina' },
    //     { label: 'Armenia' },
    //     { label: 'Aruba' }
    //   ]));
    // }, 200);

    const state = getState();
    const proximity = state.searchfilters.proximity;
    const filteredStores = state.searchfilters.filteredStores;
    let postFilteredStores='';
    if (filteredStores && filteredStores.length > 0) {
      const stores = filteredStores.map((store) => store._id);
      postFilteredStores = `${stores.join(',')}`;
    }

    dispatch(setSuggestionsSearchString(searchString));
    // const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/suggestions`;
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/es-suggestions`;

    // console.log("#api post no5:"+url);
    axios.post(url,{
        term: searchString,
        proximity: proximity,
        stores:postFilteredStores
      })
      .then((response) => {
        const data = response.data.data;
        
        // setTimeout(() => {
          dispatch(setSuggestions(data));
        // }, 3000);
      })
      .catch((err) => {
        // dispatch(setLoadingTodaysDeals(false));
        // dispatch(setErrorStatusTodaysDeals(true));
      });
  }
}