import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography'
const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.secondary.main,
  },
  button: {
    marginTop: '2px',
    marginRight:'8px',
    // color: '#fff',
    // border:'solid 1px #fff',
    // '&:hover': {
    //   color: '#000',
    //   background:'#DDD'
    // },
  },
  textbox: {
    marginTop: '2px',
    marginRight:'8px',
    fontFamily: 'Montserrat',
    textTransform: "none",
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    width:'50px'
    // color: '#fff',
    // border:'solid 1px #fff',
    // '&:hover': {
    //   color: '#000',
    //   background:'#DDD'
    // },
  },
  buttonText: {
    fontFamily: 'Montserrat',
    textTransform: "none"   
  }
}));

export default function PageNavigator({currentPage,totalPage, go2Page}) {
  const classes = useStyles();
  return (
    <>
        <button
          className={classes.button}
          onClick={()=>go2Page(currentPage-1)}
          disabled={ currentPage==1}
          variant="outlined"
        >
          <Typography className={classes.buttonText} noWrap>
            Go Back
          </Typography>
        </button>
        <input  className={classes.textbox} type="number" min="1" value={currentPage} onChange={(e)=>go2Page(e.target.value)}/>
        
        <button
          className={classes.button}
          onClick={()=>go2Page(currentPage+1)}
          disabled={ currentPage>= totalPage}
          variant="outlined"
        >
          <Typography className={classes.buttonText} noWrap>
            Go Next
          </Typography>
        </button>
    </>
  );
}
