import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

import SimilarListItem from "./SimilarListItem";
import ProductMatchingGroupListLoading from "./ProductMatchingGroupListItemLoading";

// import MergeGroupDialog from "./MergeGroupDialog";
import SimilarGroupDialog from "./SimilarGroupDialog";

import SearchBox from "./SearchBox";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function SimilarGroupsListPage(props) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(10000);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [groups, setGroups] = useState([]);
  const [isManageSimilarGroupDialogOpen, setIsManageSimilarGroupDialogOpen] = useState(false);
  const [currentProductMatchingGroup, setCurrentProductMatchingGroup] = useState(undefined);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // console.log('here'+searchText);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/search-similar-groups?similar=true&orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(
        url,
        { q: searchText },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      // console.log('search')
      if (skip === 0) {
        //Group similar group
         let temp = [];
         let item = {
           id:0,
           groups:[],
           count:0
         };
         result.data.data.forEach(element =>{
            if(item.id!= element.similar_group_id){
              if(item.id>0){
                temp.push(item);
              }
              item = {
                id: element.similar_group_id,
                groups:[element],
                count:element.products_count
              };
            }else{
              item.groups.push(element);
              item.count += element.products_count;
            } 
         });
         if(item.id>0){
          temp.push(item);
         }
        //  console.log(temp);
         temp.map(x=>{
           console.log(x)
         })
         setGroups(temp);        
      }
      setLoadMore(result.data.data.length === limit);
      setIsLoading(false);
    };

    fetchData();
  }, [searchText, skip, order, orderBy]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  
  const handViewProductGroupsClick = ()=>{
    history.push("/matching-groups");
  }

  const openManageSimilarGroupDialog = (productMatchingGroup) => {
    setCurrentProductMatchingGroup(productMatchingGroup);
    setIsManageSimilarGroupDialogOpen(true);
  };
  const handleManageSimilarGroupDialogClose = () => {
    setIsManageSimilarGroupDialogOpen(false);
  };
  

  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Similar Product Groups
          </Typography>
          <SearchBox doSearch={doSearch} value={searchText}  searchOnChange={false} />&nbsp;
          <Button variant="contained" color="secondary" onClick={handViewProductGroupsClick}>
          <Hidden mdDown> View </Hidden> Products <Hidden mdDown>Groups</Hidden>
          </Button>
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
         
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    ID                    
                  </TableCell>                  
                  <TableCell align="left">
                    Group.                    
                  </TableCell>
                  <TableCell align="center">
                    #Products.                     
                  </TableCell>                 
                  <TableCell align="center">
                    #Groups.                     
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((row, i) => (
                  // <span>{row.image_url}</span>
                  <SimilarListItem key={i} similarGroup={row} openManageSimilarGroupDialog={openManageSimilarGroupDialog}/>
                ))}
                {loadMore && (
                  <ProductMatchingGroupListLoading doLoadMore={doLoadMore} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <SimilarGroupDialog isManage={true} open={isManageSimilarGroupDialogOpen} onClose={handleManageSimilarGroupDialogClose} currentProductMatchingGroup={currentProductMatchingGroup}/>
    </>
  );
}
