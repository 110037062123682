import React, { useState, useEffect } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from "react-redux";
import {
    setCategories
  } from "../../actions/categories";
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
      },
}));

export default function TodaysDealsCategoriesFilter(props) {
  const classes = useStyles();
  const categories = useSelector((state) => state.categories.categories);
  // console.log(categories);
  const dispatch = useDispatch()
  const handOnClick = (itemId) => {
    const categoriesTemp = categories.map(category=>{
        if(category.id===itemId){
            category.selected =!category.selected;
        }
        return category;
    });
    dispatch(setCategories(categoriesTemp));
  }; //   useEffect(() => {

  //   }, []);

  return (
    <Grid container alignItems="center" alignContent="center" justifyContent="center">
      <Grid item>
        <div>
          {categories &&
            categories.map((category) => (
              <Button
                size="small"
                className={classes.margin}
                color="secondary"
                variant={category.selected?"contained":"outlined"}
                onClick={()=>handOnClick(category.id)}
              >
                {category.description}
              </Button>
            ))}
        </div>
      </Grid>
    </Grid>
  );
}
