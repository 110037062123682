import React, { Component } from "react";
import { connect } from "react-redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { green, purple } from "@material-ui/core/colors";

import {
  // setLocation,
  setProximityAndUpdateStoresNearby,
} from "../../actions/location";
import { setProximity } from "../../actions/searchfilters";

import {
  geocodeByAddress,
  // geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const styles = (theme) => ({
  distancePicker: {
    padding: "15px 5px",
    display: "block",
    boxSizing: "border-box",
    textAlign: "center",
    background: "#fff",
    width: "456px",
    height: "215px",
    [theme.breakpoints.down("sm")]: {
      width: "256px",
      height: "170px",
    },
  },
  distanceText: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "1.3em",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    paddingBottom: "10px",
  },
  distance: {
    "& > *": {
      margin: "10px 25px",
      [theme.breakpoints.down("sm")]: {
        margin: "5px",
      },
    },
  },
  distanceButton: {
    size: "large",
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
      size: "small",
    },
    backgroundColor: "#fff",
  },
  distanceButtonActive: {
    size: "large",
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[500],
      //borderColor: '#0062cc',
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: green[500],
      //borderColor: '#005cbf',
    },
    "&:focus": {
      //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    [theme.breakpoints.down("sm")]: {
      size: "small",
      fontSize: "small",
    },
  },
});

class DistancePicker extends Component {
  state = {
    proximity: 35,
    distances: [10, 15, 20, 25, 35, 50],
  };

  constructor(props) {
    super(props);
    this.state.proximity = this.props.searchfilters.proximity||35;
  }

  componentDidMount = () => {
    
  };

  handleClick = (e) => {
    const proximity = e.currentTarget.value;
    this.setState({
      proximity: proximity,
    });

    this.props.setProximity(proximity);
  };

  render() {
    const { classes } = this.props;
    let proximity = this.state.proximity;
    //let distances = this.state.distances;
    let distances = [10, 15, 20, 25, 35, 50];
    //console.log(proximity);
    return (
      <div>
        <Paper component="form" className={classes.distancePicker}>
          <Typography color="primary" className={classes.distanceText}>
            How far are you willing to travel to buy your groceries?
          </Typography>
          <div className={classes.distance}>
            {distances.map((distance, index) => {
              return (
                <Button
                  key={index}
                  variant="outlined"
                  className={
                    proximity == distance
                      ? classes.distanceButtonActive
                      : classes.distanceButton
                  }
                  value={distance}
                  onClick={this.handleClick}
                >
                  {distance}km
                </Button>
              );
            })}
          </div>
        </Paper>
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  location: state.location,
  searchfilters: state.searchfilters,
});

const mapDispatchToProps = (dispatch, props) => ({
  setProximity: (proximity) => {
    dispatch(setProximity(proximity));
  },
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DistancePicker)
);
