import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import SummaryReportFilterBox from "./SummaryReportFilterBox";

import axios from "axios";
import envConfig from "../../../bin/env.config";
import { convertToViewDate } from "../../../utility/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function SummaryReportCity({dataStatus}) {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false); 
  const [dataSource, setDataSource] = useState([]);  
  const token = useSelector((state) => state.session.token);
   
  useEffect(() => {
    const fetchData = async () => {     
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/ood-summary-report-city`;
      const result = await axios.get(url, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
      setIsLoading(false);
    };   
    dataStatus.list.length>0&&fetchData();
  }, []);
  return (
    <>     
      {isLoading ? (        
          <div>Loading ...</div>        
      ) : (
          <Table className={classes.table}  aria-label="simple table">
            <TableHead >
            {dataStatus.list.length>0&&(
              <>
                <TableRow>
                  <TableCell align="left" rowSpan={2}>City</TableCell>                 
                  <TableCell align="center" colSpan={6}>This Week</TableCell>
                </TableRow>
                <TableRow>                                                    {/* <TableCell align="center">data_year_week</TableCell> */}                  
                <TableCell align="center" style={{color:dataStatus.dict[1].color}}>                      
                      {dataStatus.dict[1].name}                     
                  </TableCell>
                  {/* <TableCell align="center" style={{color:dataStatus.dict[2].color}}>                      
                      {dataStatus.dict[2].name}                      
                  </TableCell>
                  <TableCell align="center" style={{color:dataStatus.dict[3].color}}>                      
                      {dataStatus.dict[3].name}                      
                  </TableCell>
                  <TableCell align="center" style={{color:dataStatus.dict[4].color}}>                      
                      {dataStatus.dict[4].name}                      
                  </TableCell> */}
                  <TableCell align="center"  style={{color:dataStatus.dict[5].color}}>                      
                      {dataStatus.dict[5].name}                      
                  </TableCell>
                  {/* <TableCell align="center">
                    Total
                  </TableCell> */}
                </TableRow>
              </> 
            )}                      
            </TableHead>
            <TableBody>                      
              {dataSource&&dataSource.map((row, i) => (
                  <TableRow> 
                    <TableCell align="left">{row.city}</TableCell>
                    <TableCell align="center" style={{color:dataStatus.dict[1].color}}>{row.total_status_1}</TableCell>                            
                    {/* <TableCell align="center" style={{color:dataStatus.dict[2].color}}>{row.total_status_2}</TableCell>
                    <TableCell align="center" style={{color:dataStatus.dict[3].color}}>{row.total_status_3}</TableCell>
                    <TableCell align="center" style={{color:dataStatus.dict[4].color}}>{row.total_status_4}</TableCell> */}
                    <TableCell align="center" style={{color:dataStatus.dict[5].color}}>{row.total_status_5}</TableCell>
                    {/* <TableCell align="center">{row.total_ood_2}</TableCell>                            */}
                  </TableRow>  
              ))}
            </TableBody>                         
          </Table>
      )}
    </>
  );
}
