import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";
// import AccountCircle from "@material-ui/icons/Face";
import WarningIcon from "@material-ui/icons/Warning";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

import { Helpers } from "../../utility/helpers";

const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  matches: {},
  ingroup: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
}));

export default function ProductViewDetail(props) {
  const history = useHistory();
  const classes = useStyles();
  const [direction, setDirection] = useState("up");
  const [checked, setChecked] = useState(true);
  const { productMatch, showType, groupId } = props;
  //showType: 'original'||'potential'||'ingroup'

  let productImageUrl = productMatch.image_url;
 

  return (
    <Slide direction={direction} in={checked} mountOnEnter unmountOnExit>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={
          showType === "original"
            ? classes.original
            : showType === "potential"
            ? classes.potential
            : classes.ingroup
        }
      >
        <Card
          className={`${classes.card} ${
            showType === "original"
              ? classes.originalCard
              : showType === "potential"
              ? classes.potential
              : classes.ingroup
          }`}
        >
          <CardHeader
            title={
              <img
                alt={productMatch.company_name}
                style={{ height: "50px" }}
                src={Helpers.companyLogo(productMatch.company_name)}
              />
            }
          />
          <CardMedia
            className={classes.media}
            component="img"
            image={productImageUrl}
            title={productMatch.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "../resources/images/no-picture-available.png";
            }}
          />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.productName} variant="body2">
              {productMatch.fullname} / {productMatch.size}
            </Typography>
            <Typography className={classes.companyName} variant="body2">
              {/* {productMatch.company_name} */}
              {productMatch.size} #{productMatch.id}
              {productMatch.type === "variety" && (
                <Chip size="small" label={`Multi-variety`} />
              )}
            </Typography>
            <Typography className={classes.productName} variant="body2">
               <b>Code:</b>{productMatch.product_code}
            </Typography>    
            

            <Table className={classes.table}>
              <TableBody>
                {productMatch.storeProductPrices &&
                  productMatch.storeProductPrices.map((storeProductPrice) => (
                    <TableRow>
                      <TableCell className={classes.tableTd}>
                        {storeProductPrice.store_name}
                      </TableCell>
                      <TableCell className={classes.tableTd}>
                        ${storeProductPrice.current_price_ea}
                        {/* /Reg:${storeProductPrice.regular_price_ea} */}
                      </TableCell>
                      <TableCell className={classes.tableTd}>
                        {storeProductPrice.sold_by_type_name}
                      </TableCell>
                      <TableCell className={classes.tableTd}>
                        {storeProductPrice.sale_description}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </CardContent>        
         
        </Card>
      </Grid>
    </Slide>
  );
}
