import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForever from "@material-ui/icons/DeleteForever";
import DialogConfirm from "../DialogConfirm";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonDelete({ product, reloadData }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
 
  return (
    <>
      {product&&(
        <IconButton size='small' onClick={handleOnOpen}>
          <DeleteForever/>
        </IconButton>      
      )}      
      <DialogConfirm
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        product_id = {product.id}
        reloadData={reloadData}
      />
    </>
  );
}
