const local = {
  // API_ROOT: 'http://localhost:8081/api', 
  API_ROOT: 'https://gcp-api01.bettercart.ca/api', 
  // API_ROOT: 'https://api-dev.bettercart.ca/api', 
  // API_ROOT: 'https://api2.bettercart.ca/api',
};
const dev = {
  // API_ROOT: 'https://api2.bettercart.ca/api',  
  API_ROOT: 'https://gcp-api01.bettercart.ca/api', 
};

const prod = {
  API_ROOT: 'https://gcp-api01.bettercart.ca/api', 
  // API_ROOT: 'https://api.bettercart.ca/api',  
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod: (process.env.REACT_APP_STAGE === 'dev'?dev:local);

// export default {
//   API_ROOT: 'http://localhost:8081/api',
//   PUBLIC_DOMAIN: 'https://share.bettercart.ca',
//   facebookAppID: '385972628953653',
//   googleAnalytics: 'UA-107639646-2',
//   API_VERSION:'/v1'  
// }


 //Update to AWS 2019-Oct-31
export default {
  ...config,
  SHARE_DOMAIN: 'https://share.bettercart.ca',
  facebookAppID: '941839156172819', 
  googleAnalytics: 'UA-107639646-2',
  mailChimp: 'fb19f7f778b8aedbd405e5cd36ac3db5-us19',
  API_VERSION:'/v1',
  TURN_ON_ADS:true,
  ELASTIC_SEARCH:true,//process.env.REACT_APP_STAGE !=='local',
  DEV_CONSOLE_LOG_PRINT:process.env.REACT_APP_STAGE ==='local' 
}

//export default {
//   API_ROOT: 'http://bettercartapi-dev.ca-central-1.elasticbeanstalk.com/api',
//   facebookAppID: '394815170932004', 
//}

// export default {
//   API_ROOT: 'http://bettercart-prod.ca-central-1.elasticbeanstalk.com/api',
//   facebookAppID: '394815170932004', 
// }