import React, { Component } from "react";
import '../data/leaflet.css';
import {
  Map,
  TileLayer,
  Marker,
  Tooltip,
} from "react-leaflet";

export default class DirectionMap extends Component {
  state = {
    fromlat: 57.74,
    fromlng: 11.94,
    tolat: 57.74,
    tolng: 11.94,
    zoom: 12,
    isMapInit: false,
  };

  componentDidMount() {
    this.setInitLocation();
  }

  setInitLocation = () => {
    if (localStorage.getItem("latitude")) {
      const latitude = parseFloat(localStorage.getItem("latitude"));
      const longitude = parseFloat(localStorage.getItem("longitude"));
      this.setState({
        fromlat: latitude,
        fromlng: longitude,
      });
    }
  };
  saveMap = (map) => {
    this.map = map;
    this.setState({
      isMapInit: true,
    });
  };

  render() {
    //const position = [this.state.fromlat, this.state.fromlng];    
    const toPosition = [
      this.props.store.latitude,
      this.props.store.longitude,
    ];
    // console.log(toPosition);
    return (
      <Map center={toPosition} zoom={this.state.zoom} ref={this.saveMap}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="//{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={toPosition}
          //icon={makerIconGreen}
        >
          <Tooltip direction="center" offset={[0, -50]} opacity={1} permanent>
            <span>{this.props.store.storeName}</span>
          </Tooltip>
        </Marker>
        {/* <Marker
          position={fromPosition}
          //icon={makerIconGreen}
        >
          <Tooltip direction="center" offset={[0, -50]} opacity={1} permanent>
            <span>Your location</span>
          </Tooltip>
        </Marker> */}
        {/* {this.state.isMapInit && (
          <Routing map={this.map} from={fromPosition} to={toPosition} />
        )} */}
      </Map>
    );
  }
}
