import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Utility } from "../../utility/utility";
import globalStyles from "../../styles/styles";

import { login, setLogInLoader, setLogInError } from "../../actions/session";
import { setLocation } from "../../actions/location";
import { setFilteredStores } from "../../actions/searchfilters";
import ReactGA from "react-ga";

import {
  fetchStoreFeaturedProducts,
  clearSearchHistory,
} from "../../actions/products";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  loginError: {
    ...theme.mixins.gutters(),
    width: "100%",
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: "8px",
    paddingLeft: "8px",
  },
  buttonProgress: {
    ...globalStyles.buttonSpinnerProgress,
  },
  logInButton: {
    marginTop: "10px",
  },
});

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  componentDidMount() {
    this.setState(this.getDefaultState());
    this.props.setLogInLoader(false);
    this.props.setLogInError(null);
  }

  getDefaultState = () => {
    return {
      fields: {
        email: "",
        password: "",
      },
      errors: {},
    };
  };

  onValueChange = (e) => {
    e.persist();
    this.setState((state) => {
      return (state.fields[e.target.name] = e.target.value);
    });
  };

  validateForm = () => {
    let fields = this.state.fields;
    let isFormValid = true;
    let errors = {};

    if (!Utility.isSet(fields["email"])) {
      errors["email"] = `Email is required`;
      isFormValid = false;
    }

    if (!Utility.isEmailValid(fields["email"])) {
      errors["email"] = `Please enter a valid email address`;
      isFormValid = false;
    }

    if (
      !Utility.isSet(fields["password"]) ||
      !fields["password"] ||
      fields["password"].length === 0
    ) {
      errors["password"] = `Password is required`;
      isFormValid = false;
    }

    this.setState({
      errors: errors,
    });

    return isFormValid;
  };

  onLogin = (e) => {
    e.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    this.props
      .login(this.state.fields.email, this.state.fields.password)
      .then((data) => {
        if (!!data.user.lat) {
          this.props.setLocation(
            data.user.postalCode,
            data.user.lat,
            data.user.lon
          );
          //Set filtered Stores
          // console.log("test");
          // console.log(data.user.favoriteStores);
          this.props.setFilteredStores(data.user.favoriteStores);
          this.props.clearSearchHistory();
          this.props.fetchStoreFeaturedProducts(
            [],
            data.user.favoriteStores,
            data.user.lat,
            data.user.lon
          );
        }
        
        this.props.onLoginSuccess();
      });
  };

  onResetPasswordRequest = () => {
    this.props.history.push("/resetpassword/request");
  };

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={this.onLogin}
      >
        {this.props.session.loginError &&
          this.props.session.loginError.response &&
          (this.props.session.loginError.response.status === 400 ||
            this.props.session.loginError.response.status === 404) && (
            <Paper className={classes.loginError} elevation={0}>
              <Typography
                variant="h6"
                component="h3"
                color="error"
                align="center"
              >
                Sorry, we didn't recognize your username and password
              </Typography>
            </Paper>
          )}

        {this.props.session.loginError &&
          this.props.session.loginError.response &&
          this.props.session.loginError.response.status >= 500 && (
            <Paper className={classes.loginError} elevation={1}>
              <Typography variant="h5" component="h3" color="error">
                Sorry, something went wrong.
              </Typography>
              <Typography component="p">
                We're working on it and we'll get it fixed as soon as we can.
              </Typography>
            </Paper>
          )}
        <TextField
          label="Email"
          name="email"
          type="email"
          placeholder="Enter your email"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange={this.onValueChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {this.state.errors["email"] && (
          <Typography variant="caption" color="error">
            {this.state.errors["email"]}
          </Typography>
        )}

        <TextField
          label="Password"
          name="password"
          type="password"
          placeholder="Enter your password"
          fullWidth
          margin="normal"
          variant="outlined"
          onChange={this.onValueChange}
          InputLabelProps={{
            shrink: true,
          }}
        />

        {this.state.errors["password"] && (
          <Typography variant="caption" color="error">
            {this.state.errors["password"]}
          </Typography>
        )}

        <br />
        <br />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          fullWidth={true}
        >
          Log In
          {this.props.session.showLoginLoader && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
        <Button
          variant="text"
          color="inherit"
          fullWidth={true}
          className={classes.logInButton}
          onClick={this.props.showSignUpForm}
        >
          New here? Sign Up
        </Button>
        <Button
          fullWidth={true}
          variant="text"
          color="inherit"
          className={classes.logInButton}
          onClick={this.onResetPasswordRequest}
        >
          Forgot your Password?
        </Button>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch, props) => ({
  login: (username, password) => dispatch(login(username, password)),
  setLocation: (postal, latitude, longitude) =>
    dispatch(setLocation(postal, latitude, longitude)),
  setFilteredStores: (filteredStores) =>
    dispatch(setFilteredStores(filteredStores)),
  setLogInError: (loginError) => dispatch(setLogInError(loginError)),
  setLogInLoader: (showLoginLoader) =>
    dispatch(setLogInLoader(showLoginLoader)),
  fetchStoreFeaturedProducts: (
    cart = [],
    filteredStores,
    latitude,
    longitude
  ) =>
    dispatch(
      fetchStoreFeaturedProducts(
        (cart = []),
        filteredStores,
        latitude,
        longitude
      )
    ),
  clearSearchHistory: () => dispatch(clearSearchHistory()),
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))
);
