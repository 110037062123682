import React, { useState } from 'react';
import { Button, Popover, List, ListItem, Checkbox, Typography } from '@material-ui/core';

import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";

const SimilarButton = ({similarTypes,selectedProjectId, product,handleGoNext}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  
  const items = similarTypes;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checkedItems.indexOf(value);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedItems(newChecked);
  };


// Perform bitwise OR on an array of integers and return the result as an integer
const orArrayIntegers=(arr)=> {
    if (arr.length === 0) return 0; // Handle empty array case

    // Initialize the result with the first integer
    let result = arr[0];

    // Perform bitwise OR across all integers
    for (let i = 1; i < arr.length; i++) {
        result |= arr[i]; // Bitwise OR
    }

    return result;
}
  const handleSave = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/similar`;
    const similarTypeId = orArrayIntegers(checkedItems)
    // console.log(Boolean(1&10))
    // console.log(Boolean(2&10))
    // console.log(Boolean(8&10))
    // console.log(Boolean(4&10))
      const postData = {
        product_matched_id: product.potential.id,
        product_id: product.original.id,
        project_id: selectedProjectId,
        sim_name:product.sim_name,
        similar_type_id:similarTypeId,
        simSource: '_ve',
      };
      // console.log(postData);
      axios
        .post(url, postData, {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        })
        .then(() => {          
          handleGoNext();
          setCheckedItems([])
        })
        .catch((err) => {
          console.log(err);
        });
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Similar
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="h6" style={{ padding: '8px 16px' }}>
          Similar
        </Typography>
        <List>
          {items.map((item) => (
            <ListItem key={item.id} button onClick={handleToggle(item.id)} title={item.description}  >
              <Checkbox
                edge="start"
                checked={checkedItems.indexOf(item.id) !== -1}
                
                tabIndex={-1}
                disableRipple
              />
              <span style={{fontWeight:'bold', color:item.color}}>{item.name}</span>
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          onClick={handleClose}
          style={{ margin: '8px 16px' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ margin: '8px 16px' }}
        >
          Save
        </Button>
        
      </Popover>
    </div>
  );
};

export default SimilarButton;
