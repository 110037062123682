import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import AccountCircle from "@material-ui/icons/Face";
import WarningIcon from "@material-ui/icons/Warning";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import axios from "axios";
import envConfig from "../../../bin/env.config";
import store from "../../../store/store";

import { Helpers } from "../../../utility/helpers";
import EditNoteTextBox from "./EditNoteTextBox";

const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  // removeAcceptedButton: {
  //   color: "blue",
  //   borderColor: "blue",
  // },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  originalCard: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  lockedByYou: {
    backgroundColor: theme.palette.primary.main,
  },
  lockedByOther: {
    backgroundColor: "red",
    color: "#FFF",
  },
  groupLink:{
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  }
}));

export default function ReviewItemOriginal(props) {
  const classes = useStyles();
  const { originalProduct, locked } = props;
  let productImageUrl = originalProduct.image_url;
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className={classes.original}>
      <Card className={`${classes.card} ${classes.originalCard}`}>
        <CardHeader
          title={
            <img
              alt='ADQ'
              style={{ height: "50px" }}
              src='https://www.alimentsduquebec.com/build-front/icons/AlimentsDuQuebec_Logo.svg'
            />            
          }
        />
        <CardMedia
          className={classes.media}
          component="img"
          image={productImageUrl}
          title={originalProduct.name}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/resources/images/no-picture-available.png";
          }}
        />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.productName} variant="body2">
            {Helpers.titleCase(originalProduct.name)}
          </Typography>
          <Typography className={classes.companyName} variant="body2">
            {originalProduct.size} ID#{originalProduct.id} {" "}  
          </Typography>
          <Typography className={classes.companyName} variant="body2">
            <a href={originalProduct.link}>ADQ product link</a>  
          </Typography>
          <Typography variant="body2">
            <b>#name_en:</b> {originalProduct.name_en}<br/>
            <b>#keyword 1:</b> {originalProduct.keyword_1} <br/>
            <b>#keyword 2:</b> {originalProduct.keyword_2}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography variant="body2">
            
          </Typography>
        </CardActions>        
      </Card>
    </Grid>
  );
}
