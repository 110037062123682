import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


import CartButton from "../CartButton/CartButton";
import ProductDetail from './ProductDetail';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function ProductViewDialog(props) {
  const classes = useStyles();
  const { open, onClose, product, productShare, productLike, productAlert, showAlert, isAdmin} = props;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={onClose}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
            <div className={classes.grow} />

            <CartButton />
          </Toolbar>
        </AppBar>
        <ProductDetail 
            product={product}
            productShare={productShare}
                // productLike={this.onProductLiked}
            productLike={ productLike }
            productAlert={productAlert}
            // hideProductViewButton={true}
            // showFavoriteButton={true}
            showAlert={showAlert}
            isAdmin={isAdmin}
        />
    </Dialog>
  );
}
