import React,{useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import CitiesDropDownBox from './CitiesDropDownBox'
import ChainDropDownBox from './ChainDropDownBox'
const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.secondary.main,
  },
  button: {    
    marginRight:'8px',
  },
  textbox: {
    marginRight:'20px',
    width:'200px'
  }, 
  textbox1: {
    marginRight:'20px',
    width:'80px'
  }, 
}));

export default function SummaryReportFilterBox({doFilter}) {
  const classes = useStyles();
  const [inputState, setInputState] = useState({city: "",company_id:0});
  const handleChange = (name, value) =>{    
    setInputState({
      ...inputState,
      [name]: value,
    });
  }
  const onSearchClick = () => {
    doFilter(inputState)
  };
   useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
      doFilter(inputState)      
  // return () => clearTimeout(delayDebounceFn)
  }, [inputState]);
  return (
    <><CitiesDropDownBox
        onChange={handleChange}
      />
      <ChainDropDownBox
        onChange={handleChange}
      />
       {/* <Button
          color="primary"
          variant="contained"
          onClick={onSearchClick}
        >
          Filter
        </Button>                 */}
    </>
  );
}
