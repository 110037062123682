import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@material-ui/core';
import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "rgba(255,255,0,0.3)",
    padding:'2px 8px 2px 8px',
    fontSize:'0.875rem'
  }, 
}));

export default function EditActualSizeTextBox(props) {
  const classes = useStyles();
  const { id, value} = props;
  const [inputValue, setInputValue] = useState(value)
  const token = useSelector((state) => state.session.token);

  const onChange = (event) => {
    //Update data
    setInputValue(event.target.value)
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/update-product-actual-size`;
    axios.post(
      url,
      { 
        id: id,
        actual_size: event.target.value
      },
      {
        headers: {
          Authorization: JSON.stringify(token),
        },
      }
    );    
  };
  return (
    <InputBase
        className={classes.input}
        placeholder={`actual size`}
        value={inputValue}
        onChange={onChange}
    />
  );
}
