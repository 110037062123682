import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from "@material-ui/core";


import  ProjectCustomerProductMappingInfo  from "./ProjectCustomerProductMappingInfo";
import  ProjectProductMatchingInfo  from "./ProjectProductMatchingInfo";
import  ProjectProductMatchingAssignedInfo  from "./ProjectProductMatchingAssignedInfo";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogSummary({open, onClose, project}) {
  
  return (
      <Dialog
        fullWidth = {true}
        maxWidth ='lg'
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="Project Summary"
      >
        <DialogTitle>{project&&`${project.name} Summary `}</DialogTitle>
        <DialogContent>
          <div style={{display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',flexDirection:'row'}}>
                  <ProjectCustomerProductMappingInfo open={open} project={project}/>
                  <ProjectProductMatchingInfo open={open} project={project}/>
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
                <ProjectProductMatchingAssignedInfo  open={open} project={project}/>
            </div>
          </div>
        </DialogContent>        
      </Dialog>
  );
}
