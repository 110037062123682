export const setFavoriteStoresLoader = (favoriteStoresLoader = true) => ({
  type: 'SET_FAVORITE_STORES_LOADER',
  favoriteStoresLoader
});
export const setFavoriteStoresError = (favoriteStoresError) => ({
  type: 'SET_FAVORITE_STORES_ERROR',
  favoriteStoresError
});
export const setFavoriteStoresSuccess = (showFavoriteStoresSuccess = true) => ({
  type: 'SHOW_FAVORITE_STORES_SUCCESS',
  showFavoriteStoresSuccess
});

export const setFavoriteProductLoader = (favoriteProductLoader = true) => ({
  type: 'SET_FAVORITE_PRODUCT_LOADER',
  favoriteProductLoader
});

export const setFavoriteProductError = (favoriteProductError) => ({
  type: 'SET_FAVORITE_PRODUCT_ERROR',
  favoriteProductError
});

export const setFavoriteProductSuccess = (showFavoriteProductSuccess = true) => ({
  type: 'SHOW_FAVORITE_PRODUCT_SUCCESS',
  showFavoriteProductSuccess
});

export const setRemoveFavoriteProductLoader = (removeFavoriteProductLoader = true) => ({
  type: 'SET_REMOVE_FAVORITE_PRODUCT_LOADER',
  removeFavoriteProductLoader
});

export const setRemoveFavoriteProductError = (removeFavoriteProductError) => ({
  type: 'SET_REMOVE_FAVORITE_PRODUCT_ERROR',
  removeFavoriteProductError
});

export const setRemoveFavoriteProductSuccess = (showRemoveFavoriteProductSuccess = true) => ({
  type: 'SHOW_REMOVE_FAVORITE_PRODUCT_SUCCESS',
  showRemoveFavoriteProductSuccess
});
//--------------------------
export const setAlertProductLoader = (alertProductLoader = true) => ({
  type: 'SET_ALERT_PRODUCT_LOADER',
  alertProductLoader
});

export const setAlertProductError = (alertProductError) => ({
  type: 'SET_ALERT_PRODUCT_ERROR',
  alertProductError
});

export const setAlertProductSuccess = (showAlertProductSuccess = true) => ({
  type: 'SHOW_ALERT_PRODUCT_SUCCESS',
  showAlertProductSuccess
});

export const setRemoveAlertProductLoader = (removeAlertProductLoader = true) => ({
  type: 'SET_REMOVE_ALERT_PRODUCT_LOADER',
  removeAlertProductLoader
});

export const setRemoveAlertProductError = (removeAlertProductError) => ({
  type: 'SET_REMOVE_ALERT_PRODUCT_ERROR',
  removeAlertProductError
});

export const setRemoveAlertProductSuccess = (showRemoveAlertProductSuccess = true) => ({
  type: 'SHOW_REMOVE_ALERT_PRODUCT_SUCCESS',
  showRemoveAlertProductSuccess
});
//--------------------------

export const setSavedCarttLoader = (savedCartLoader = true) => ({
  type: 'SET_SAVED_CART_LOADER',
  savedCartLoader
});

export const setSavedCartError = (savedCaartError) => ({
  type: 'SET_SAVED_CART_ERROR',
  savedCaartError
});

export const setSavedCartSuccess = (showSavedCartSuccess = true) => ({
  type: 'SHOW_SAVED_CART_SUCCESS',
  showSavedCartSuccess
});

export const setRemoveSavedCarttLoader = (removeSavedCartLoader = true) => ({
  type: 'SET_REMOVE_SAVED_CART_LOADER',
  removeSavedCartLoader
});

export const setRemoveSavedCartError = (removeSavedCaartError) => ({
  type: 'SET_REMOVE_SAVED_CART_ERROR',
  removeSavedCaartError
});

export const setRemoveSavedCartSuccess = (showRemoveSavedCartSuccess = true) => ({
  type: 'SHOW_REMOVE_SAVED_CART_SUCCESS',
  showRemoveSavedCartSuccess
});

export const setUserProfileLoader = (isLoadingUserProfile = true) => ({
  type: 'SET_USER_PROFILE_LOADER',
  isLoadingUserProfile
});

export const setUserProfileError = (errorOccurredLoadingUserProfile = true) => ({
  type: 'SET_USER_PROFILE_ERROR',
  errorOccurredLoadingUserProfile
});
