import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,  
  Button,
  Paper
} from "@material-ui/core";

import CountCompetitorProducts from "./CountCompetitorProducts";
// import BProductsAndCompetitorPanel from "./Products/BProductsAndCompetitorPanel";


import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../../bin/env.config";
const useStyles = makeStyles((theme) => ({
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  productName: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    cursor: "pointer"
  },
  removeButton: {
    color: "red",
    borderColor: "red",
  },
}));
export default function CompetitorsPanel({cproductId,reloadData}) {  
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  // const token = useSelector((state) => state.session.token);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/competitor/products?id=${cproductId}`;
  //     const result = await axios.post(url, {
  //       headers: {
  //         Authorization: token,
  //       },
  //     });
  //     setDataSource(result.data.data);
  //     setIsLoading(false);      
  //   };
  //   fetchData();
  
  // }, []);
  

  return ( 
    <Table >
      <TableBody> 
          <TableRow >
            <TableCell colSpan={7} align="left">
                <CountCompetitorProducts companyReportToolProductId={cproductId}/> 
            </TableCell>
          </TableRow>
      </TableBody>
    </Table> 
  );
}
