import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftTwoTone from '@material-ui/icons/KeyboardArrowLeftTwoTone';

import ShoppingCart from '@material-ui/icons/ShoppingCart';
import { setProducts } from '../../actions/pricecomparison';
import PriceComparison from '../PriceComparison/PriceComparison';
import { loadSavedCart } from '../../actions/cart';
import { setFilteredStores } from '../../actions/searchfilters';

import Heading from "../Heading/Heading";

const drawerWidth = 350;
const styles = (theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    // padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      //marginLeft: drawerWidth,
      //width: `calc(100% - ${drawerWidth}px)`,
    }
  },
  logo: {
    height: '42.5 px',
    width: '220px',
    margin: theme.spacing(1),
    cursor: 'pointer'
  },  
});

class SummaryPage extends Component {

  constructor () {
    super();
    this.state = {
      isCartDrawerOpen: false,    
    };
    
  }

  componentDidMount () {
    
    if (localStorage.getItem('cart')  && !this.props.cart.items ){                 
      this.props.loadSavedCart(JSON.parse(localStorage.getItem('cart')));      
    }
    
    if (localStorage.getItem('stores')) {      
      const localSavedStores = JSON.parse(localStorage.getItem('stores'));              
      this.props.setFilteredStores(localSavedStores);      
    }    

  }


  componentWillMount () {

    if (this.props.cart.length === 0) {
      //this.onBack();
    }
    //this.props.setProducts(this.props.cart);
  }

  onBack = () => {
    this.props.history.push('/pricecomparesummary');
  }

  toggleList = () => {
      this.setState(state => ({
        isCartDrawerOpen: !state.isCartDrawerOpen
      }));
  }

  render () {

    const { cart, classes } = this.props;
    return (
      <div>
          <Heading page={"summarypage"}/>      
          <AppBar position="sticky" color="inherit">
            <Toolbar>
              <IconButton color="inherit" onClick={this.onBack} aria-label="Close">
                <ArrowBackIcon />
              </IconButton>              
              <div className={classes.grow} />                          
            </Toolbar>
          </AppBar>
        <br />
        <PriceComparison isCartDrawerOpen={ this.state.isCartDrawerOpen } toggleList={ this.toggleList } />
      </div>
    );
  }

}

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  priceComparison: state.priceComparison,
  searchfilters: state.searchfilters,
});
const mapDispatchToProps = (dispatch, props) => ({
  setProducts: (products) => dispatch(setProducts(products)),
  loadSavedCart: (cart) => dispatch(loadSavedCart(cart)),
  setFilteredStores: (filteredStores) => dispatch(setFilteredStores(filteredStores)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SummaryPage));
