import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ProductItem from "../../Product/ProductItem";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Slider from "react-slick";
import StoreLogo from "../../StoreLogo/StoreLogo";
import ViewAllDialog from "./ViewAllDialog";
import StorefrontIcon from "@material-ui/icons/Storefront";

const styles = (theme) => ({
  storeName: {
    marginLeft: theme.spacing(2),
  },
  noResults: {
    marginLeft: theme.spacing(2),
  },
  sliderStoreLogoContainer: {
    width: "180px",
    margin: theme.spacing(2),
    marginLeft: 0,
    opacity: "0.9",
    padding: theme.spacing(1),
    backgroundColor: "#ccc",
  },
  storeLogo: {
    width: "180px",
    height: "40px",
    display: "block",
  },
  slider: {
    // marginLeft: theme.spacing(2) + 'px',
    // marginRight: theme.spacing(2) + 'px',
    marginBottom: theme.spacing(5) + "px",
    padding: 0,
    paddingLeft: "45px",
    paddingRight: "45px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "0px",
      "& .slick-list": {
        //display: 'none !important'
        padding: "0 20% 0 0",
      },
    },
  },
  viewAllContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    cursor: "pointer",
    textTransform: "uppercase",
  },
  viewAllStoreName: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "1.125rem",
  },
  viewAllButton: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "1.125rem",
    color: theme.palette.primary.main,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { classes, companyData } = this.props;
    const sliderSettings = {
      // lazyLoad: 'ondemand',
      dots: true,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      // centerMode: true,
      // autoplay: true,
      // autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3.5,
            slidesToScroll: 3,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.5,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.5,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
          },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ],
    };

    // <StoreLogo store={storeData.store}></StoreLogo>

    // console.log('search result');
    // console.log(companyData);

    return (
      <div>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Grid item>
            {/* {companyData.matches.length > 4 && (
              <Button
                variant="text"
                color="primary"
                size="large"
                onClick={this.openModal}
                endIcon={<StorefrontIcon/>}
              >
                View all
              </Button>
            )} */}
            {companyData.matches.length > 4 && (
              <div className={classes.viewAllContainer}>
                <span
                  className={classes.viewAllStoreName}
                  onClick={this.openModal}
                >
                  {companyData.matches[0].store.name}
                  &nbsp; &#x000B7; &nbsp;
                </span>
                <span
                  className={classes.viewAllButton}
                  onClick={this.openModal}
                  // endIcon={<StorefrontIcon/>}
                >
                  VIEW ALL
                </span>
              </div>
            )}
            {companyData.matches.length <= 4 && (
              <div className={classes.viewAllContainer}>
                <span className={classes.viewAllStoreName}>
                  {companyData.matches[0].store.name}
                </span>
              </div>
            )}
          </Grid>
        </Grid>

        {companyData.company && (
          <div>
            {companyData.matches.length === 0 && (
              <Typography
                variant="caption"
                size="small"
                paragraph={true}
                className={classes.noResults}
              >
                No results
              </Typography>
            )}

            <Slider {...sliderSettings} className={classes.slider}>
              {[...companyData.matches].slice(0, 15).map((item) => (
                <ProductItem
                  key={item._id}
                  product={item}
                  productShare={this.props.productShare}
                  productSelect={this.props.productSelect}
                  productView={this.props.productView}
                  productLike={this.props.productLike}
                  productAlert={this.props.productAlert}
                  showAlert={this.props.showAlert}
                  openEditProductDialog={this.props.openEditProductDialog}
                  isAdmin={this.props.isAdmin}
                />
              ))}
            </Slider>
            <ViewAllDialog
              open={this.state.isModalOpen}
              onClose={this.closeModal}
              companyData={companyData}
              products={companyData.matches}
              productShare={this.props.productShare}
              productSelect={this.props.productSelect}
              productView={this.props.productView}
              productLike={this.props.productLike}
              productAlert={this.props.productAlert}
              showAlert={this.props.showAlert}
              openEditProductDialog={this.props.openEditProductDialog}
              isAdmin={this.props.isAdmin}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SearchList);
