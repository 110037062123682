import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import envConfig from '../../bin/env.config';

import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Snackbar from '@material-ui/core/Snackbar';

import { findProduct } from '../../actions/products';
import { startPriceCompare } from '../../actions/pricecomparison';
import { loadSavedCart } from '../../actions/cart';

import { Helpers } from '../../utility/helpers';

import Cart from './Cart';

import { addFavoriteProduct, removeFavoriteProduct } from '../../actions/session';
import { setFavoriteProductError, setFavoriteProductSuccess } from '../../actions/user';
import { setFilteredStores } from '../../actions/searchfilters';

import ReactGA from 'react-ga';
import grey from '@material-ui/core/colors/grey';

import Heading from "../Heading/Heading";

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  body: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100vh'
  },
  ShoppingCart: {
    flex: '0 0 300px',
    alignItems: 'center'
  },
  advertising: {
    flex: '0 0 300px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  list: {
    //background: theme.palette.primary.main,    
    textAlign: 'center',
    alignItems:'center',
    padding: theme.spacing(5),
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
    }
    ///boxShadow: 'inset 0 0 30px #022974'    
  },
  itemList: {
    maxWidth: '250px',
    textAlign: 'left',   
    borderColor: '#cdd1ce'   ,
    borderWidth: '1px',
    borderStyle: 'solid',          
  },
  storeLogo: {
    width: '250px',
    height: 'auto',    
  },
  productItem: {
    margin: theme.spacing(1),       
  },
  grow: {
    flexGrow: 1,
  },
  storeMatchContainer: {
    margin: theme.spacing(2),
    paddingBottom: theme.spacing(5)
  },
  storeColumn: {
  },
  otherStoresTitle: {
    paddingTop: 0,
    marginTop: 0
  },
  slider: {
    paddingLeft: '55px',
    paddingRight: '55px',
    '& .slick-dots': {      
      display: 'none !important'
    }
  },
  shareButton: {
    display: 'inline-block',
    marginRight: "10px",
    '&:hover': {
      opacity: '0.5'
    }
  },
  snackbarRoot:{
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: '2px',
    borderStyle: 'solid',
    backgroundColor: "#fff",
    fontWeight: 'bold',
    marginTop: '60px',
    marginRight: '-10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    }
  },
  snackbarRootError:{
    color: '#ff0000',
    borderColor: '#ff0000',
    borderWidth: '2px',
    borderStyle: 'solid',
    backgroundColor: "#fff",
    fontWeight: 'bold',
    marginTop: '60px',
    marginRight: '-10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    }
  },
  noMatchRoot: {
    background: grey[100],
    boxShadow: 'none',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '210px'
    // ,
    // width: '100%'
  },
   

});



class CartPage extends Component {

  productLoaded = false;

  state = {
  }

  constructor (props) {
    super(props);
    this.state = {      
      isTellAFriendOpen: false,
      isLoginModalOpen: false,   
      selectedStores: undefined,
      productUrl: '',      
    };
    this.state.selectedStores = this.props.searchfilters.filteredStores;
    ReactGA.pageview("ShoppingCart"); 
  }

  componentDidMount () {
    if (localStorage.getItem('cart')  && !this.props.cart.items ){           
      this.props.loadSavedCart(JSON.parse(localStorage.getItem('cart')));
    }

    if (this.state.selectedStores && this.state.selectedStores.length===0){
      if (localStorage.getItem('stores')) {      
        const localSavedStores = JSON.parse(localStorage.getItem('stores'));              
        this.props.setFilteredStores(localSavedStores);
      }    
    }   
  }

  componentDidUpdate (prevState) {
    if (this.productLoaded === false && this.props.productBeingViewed) {
      this.loadPage();
      this.productLoaded = true;
    }
  }

  loadPage = () => {
    const params = {
      latitude: this.props.location.latitude,
      longitude: this.props.location.longitude,
      products: []
    };

    const product = this.props.productBeingViewed;
    params.products.push({
      productID: product.productID,
      name: product.name,
      brand: product.brand,
      size: product.size,
      brandKeywords: product.brandKeywords,
      nounKeywords: product.nounKeywords,
      adjectivesKeywords: product.adjectivesKeywords,
      sizeKeywords: product.sizeKeywords,
      categoryType: product.categoryType,
      immediateAdjective: product.immediateAdjective
    });

    this.props.startPriceCompare(params);
  }

  onBack = () => {
    this.props.history.push('/home');
  }




  renderLeftAdvertising =() => {    
    const { classes, session } = this.props;         
    const stores = JSON.parse(localStorage.getItem('stores'));       
    return (        
        <div className={ classes.list }>                   
        {
          stores.map((store) => {                                                                            
            //let company = <img src={store.companyData.companyLogoLink} className={ classes.storeLogo }/>;                                            
            return (                   
              <Card className={classes.noMatchRoot}>
                  <Typography align="center" variant="overline" noWrap={true}>Ads</Typography>
              </Card>
            );
          })
        }            
      </div> 
    )                  
  }
  renderRightAdvertising =() => {
    return this.renderLeftAdvertising();
  }

  render () {
    const { classes } = this.props;
  
    return (
      <div>                
          <AppBar position="sticky" color="inherit">
            <Toolbar>
              <IconButton color="inherit" onClick={this.onBack} aria-label="Close">
                <ArrowBackIcon />
              </IconButton>                
              <div className={classes.grow} />                          
            </Toolbar>
          </AppBar>
          <div className={classes.body}>
            <div className={classes.advertising}>
              {
                //this.renderLeftAdvertising()
              }
            </div>
            <div className={classes.ShoppingCart}>
                <Cart/>
            </div>
            <div className={classes.advertising}>
              {
                //this.renderRightAdvertising()
              }
            </div>
          </div>
                         

        
      </div>
    );

  }
}

const mapStateToProps = (state, props) => ({
  cart: state.cart.items,
  products: state.products,
  searchfilters: state.searchfilters,
  session: state.session,
  location: state.location,
  cart: state.cart.items,
  pricecomparison: state.pricecomparison,
  isLoadingProduct: state.products.isLoadingProduct,
  errorOccurredLoadingProduct: state.products.errorOccurredLoadingProduct,
  productBeingViewed: state.products.productBeingViewed,
  errorOccurredLoadingProduct: state.products.errorOccurredLoadingProduct
});

const mapDispatchToProps = (dispatch, props) => ({
  startPriceCompare: (params) => dispatch(startPriceCompare(params)),
  findProduct: (product) => dispatch(findProduct(product)),
  setFilteredStores: (filteredStores) => dispatch(setFilteredStores(filteredStores)),
  loadSavedCart: (cart) => dispatch(loadSavedCart(cart)),
  addFavoriteProduct: (user, product) => dispatch(addFavoriteProduct(user, product)),
  removeFavoriteProduct: (product) => dispatch(removeFavoriteProduct(product)),
  setFavoriteProductError: (value) => dispatch(setFavoriteProductError(value)),
  setFavoriteProductSuccess: (value) => dispatch(setFavoriteProductSuccess(value))
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CartPage)));
