import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@material-ui/core';
import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "rgba(255,255,0,0.3)",
    padding:'2px 8px 2px 8px',
    fontSize:'0.875rem'
  }, 
}));

export default function NoteUpdate({ oodItem}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(oodItem.note)
  const token = useSelector((state) => state.session.token);

  const onChange = (event) => {
    //Update data
    setInputValue(event.target.value)   
  };
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
        const updateNote = async () => {
          setIsLoading(true);
          const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/ood-update`;
          axios.post(
            url,
            { 
              product_matching_group_id: oodItem.product_matching_group_id,
              store_id:oodItem.store_id,
              note: inputValue
            },
            {
              headers: {
                Authorization: JSON.stringify(token),
              },
            }
          );          
          setIsLoading(false);
        };
        inputValue!=oodItem.note&&updateNote();
      // Send Axios request here
    }, 3000)  
    return () => clearTimeout(delayDebounceFn)
  }, [inputValue]);
  return (
    <InputBase
        className={classes.input}
        placeholder={`Note here`}
        value={inputValue}
        onChange={onChange}
        disabled={isLoading}
    />
  );
}
