import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import DialogSelectBanners from "./DialogSelectBanners";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonSelectBanners({ project }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
 
  return (
    <>
      {project&&(
        <Button onClick={handleOnOpen} color="primary">
          B
        </Button>      
      )}
      
      <DialogSelectBanners
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}
        project={project}
      />
    </>
  );
}
