import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  storeLogoContainer: {
    width: '100%',
    // margin: theme.spacing(2),
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }

    // opacity: "0.9",
    // padding: theme.spacing(1)
    // ,
    // backgroundColor: '#ccc'
  },
  storeLogo: {
    width: '200px',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginTop: theme.spacing(1)
  },
  storeName: {
    textAlign: 'center'
  },
  locationIcon: {
    fontSize: "0.8rem",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
});

const StoreLogoOnline = (props) => {
  const { classes, company, store } = props;
  const companyName = company.name;
  // let textToDisplay = companyName;
  let imgLink = company.companyLogoLink;

  return (
    <div className={ classes.storeLogoContainer }>
      <img className={ classes.storeLogo } src={ imgLink } />
      {
        store && (
          <Typography className={ classes.storeName } variant="subtitle2" gutterBottom={true} noWrap={true}>{ store.name }</Typography>
        )
      }
    </div>
  );
}

export default withStyles(styles)(StoreLogoOnline);
