import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import ShareProductToEmailDialog from '../ShareProductToEmail/ShareProductToEmailDialog';
import ShareSuccessThankYou from '../ShareProductToEmail/ShareSuccessThankYou';

import { Helpers } from "../../utility/helpers";

import envConfig from "../../bin/env.config";

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";

import {
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  shareTitle: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "28px",
    fontFamily: "Montserrat",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  shareSubTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.1rem",
    paddingTop: "15px",
    fontFamily: "Montserrat",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  shareProductName: {
    paddingTop: "15px",
  },
  shareDialogText: {
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  },
  shareImagePreview: {
    width: "200px",
  },
  shareButtonContainer:{
    width:'100%',
    paddingTop:"10px",
    paddingBottom:"16px",
    textAlign:"center"
  },
  shareButton: {
    display: 'inline-block',
    marginRight: "10px",
    '&:hover': {
      opacity: '0.5'
    }
  },
  shareButtonEmail: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0px',
    font: 'inherit',
    color: 'inherit',
    cursor: 'pointer',
    display: 'inline-block',
    marginRight: "0px",
    '&:hover': {
      opacity: '0.5'
    }
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function ProductShareDialog(props) {
  const classes = useStyles();
  const { open, onClose, product } = props;
  const [isShareEmailOpen, setIsShareEmailOpen] = useState(false);
  const [isShareSuccessOpen, setIsShareSuccessOpen] = useState(false);

  const shareUrl = `${envConfig.SHARE_DOMAIN}/product/${
    product._id
  }/province/${product.store.province}`;
  const shareImage = product.imageURL;
  const shareProductContent = `${Helpers.fullProductNameWithBrand(
    product
  )} `;
  const shareQuote = "Check out this awesome product I found: ";
  const shareSubject = "Cool grocery savings site!";
  const shareBody = shareQuote + shareProductContent + ", " + shareUrl;
  const handleOnShareEmailOpen =()=>{
    onClose();
    setIsShareEmailOpen(true);
  }
  const handleOnShareEmailClose = () => {
    setIsShareEmailOpen(false); 
    onClose();   
  };
  const handleOnShareSuccess = () => {
    setIsShareEmailOpen(false);
    setIsShareSuccessOpen(true);
    
  };
  const handleOnThankYouClose = () => {
    setIsShareSuccessOpen(false);
    //onClose();
  };
  
  return (
    <>
    <Dialog
      //   fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={classes.shareTitle}>
        Share this BetterCart deal!
      </DialogTitle>
      <DialogContent className={classes.shareDialogText}>
        <img
          className={classes.shareImagePreview}
          src={shareImage}
        />
        <span className={classes.shareProductName}>
          {product.fullName}
        </span>
        <span className={classes.shareSubTitle}>{ }</span>
        
        <Typography>Select a method to share below:</Typography> 
        <div className={classes.shareButtonContainer}>
        <FacebookShareButton
          url={shareUrl}
          //url='https://dev.bettercart.ca'
          quote={shareBody}
          className={classes.shareButton}
        >
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
        <FacebookMessengerShareButton
          //appId='941839156172819'
          url={shareUrl}
          quote={shareBody}
          className={classes.shareButton}
        >
          <FacebookMessengerIcon size={32} round={true} />
        </FacebookMessengerShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={shareQuote}
          className={classes.shareButton}
        >
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={shareQuote}
          className={classes.shareButton}
        >
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
        {/* <ViberShareButton
          url={shareUrl}
          title={shareQuote}
          className={classes.shareButton}
        >
          <ViberIcon size={32} round={true} />
        </ViberShareButton> */}
        <button 
                  onClick={handleOnShareEmailOpen}                  
                  className={classes.shareButtonEmail}>                                    
                  <EmailIcon size={32} round={true} />
                </button>
        </div>
      </DialogContent>
    </Dialog>
    <ShareProductToEmailDialog
        open={isShareEmailOpen}
        onClose={handleOnShareEmailClose}
        product={product}
        onShareSuccess = {handleOnShareSuccess} 
      />
    <ShareSuccessThankYou
      open={isShareSuccessOpen}
      onClose={handleOnThankYouClose}
    />
    </>
  );
}
