import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";

import Heading from "../Heading/Heading";

const styles = (theme) => ({
  helpContainer: {
    maxWidth: "750px",
    margin: "30px auto 200px auto",
  },
});

class HelpPage extends Component {
  constructor(props) {
    super(props);
    ReactGA.pageview("Help");
  }

  onBack = () => {
    this.props.history.push("/home");
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Heading page={"helppage"} />

        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.onBack}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="title" color="inherit"></Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.helpContainer}>
          <Typography variant="h4">
            At BetterCart, we want to make sure that things are as easy for you
            as possible, and that you save money every time you shop!
          </Typography>
          <br />
          <Typography variant="subtitle2">
            Below, you will find steps that will help you get started. If you
            have any questions about BetterCart, or want to know how you can do
            something on the site, just email{" "}
            <a href="mailto:support@bettercart.ca">support@bettercart.ca</a>. We
            are happy to help!
          </Typography>
          <br />
          <Typography variant="h5">Let’s Get You Started!</Typography>
          <br />
          <strong>Step 1:</strong> Indicate your postal code: To do this, go to
          the top left of your screen and click on “Change my Location.” A box
          will appear and you can enter the postal code that you would like
          BetterCart to use.
          <br />
          <br />
          <strong>Step 2:</strong> Indicate your kilometer radius: To do this,
          go to the top left of your screen and click on “Change proximity.” A
          box will appear and you can move the ball to the right to capture
          stores that are a greater distance from you, or move the ball to the
          left, to capture stores that are closer to you. You will notice that
          the default kilometre radius has been set at 10km, but we know that
          people are on the move and shop at stores across the city, so be sure
          to change your postal code whenever you want to capture stores that
          are a greater distance away.
          <br />
          <br />
          <strong>Step 3:</strong> Select your stores: If you look to the left
          of your screen, you will see a list of stores in your area. The stores
          listed are based on the postal code and kilometer radius you entered.
          Now, beside each store is a green box. Click on the box if you want
          BetterCart to consider the store. If you change your mind, and do not
          want a store to be considered anymore, just click on the box again to
          remove the “check mark.” <br />
          <br />
          <strong>Step 4:</strong> Search for your grocery items: With Steps 1-3
          completed, it’s time to get to your shopping! Here you go! To search
          for products, head to the top of your screen. You will see the “Search
          for a product” bar at the top. Enter your first product, and click
          “enter.” Every listing that BetterCart has for that product will then
          appear. You will see the name of the stores if you scroll down. To
          scroll within a store, use the arrow that appears at the right of the
          screen. If you click on it, you can scroll within each store and see
          what it has to offer! <br />
          <br />
          Now that the scrolling is clear, you will want to add products to your
          list! So, find the product that you want, click on the quantity that
          you want (use the plus or minus signs to adjust the quantity), and
          then add it to your list. You will see a bar that will tell you
          “Product has been added to your list.” Good for you!! You also will
          see in the bottom right of your screen a blue “YOUR LIST” bar that
          shows the product has been entered. Note that “YOUR LIST” reflects
          that a product has been entered but does not reflect the actual
          quantity that you would have selected. Don’t worry, BetterCart has
          your back! The quantity shows up, but just a little later on!
          <br />
          <br />
          Extra Notes for the BetterCart Scientist! You will start to see the
          difference in prices for the same product across stores. Often, this
          can be shocking to see just how much the price of products will
          differ. For instance, is it really a $2.00 difference for the same
          package of grape tomatoes, or a $6.00 difference for the same box of
          chicken fingers? Why on earth is celery root varying in price by $8.00
          between stores that are owned by the same company? And, why, for the
          same price, would I get 96 diapers at one store but only 32 at the
          other, when the names of the store are the same, and only the
          geographic location differs? Welcome to the power of BetterCart, where
          we help you find the best deals on your shopping cart! But, in the
          interest of time (yours that is!), let’s keep moving! <br />
          <br />
          <strong>Step 5A:</strong> Seeing Your Grocery List: When you have
          finished looking up products and adding them to “YOUR LIST,” you want
          to click on “YOUR LIST.” This will take you to a little inset window
          that shows you your shopping cart at a glance. If you are happy with
          your list, great, but if you want to make any changes to the quantity
          of an item, just go to the item, and adjust the plus or minus sign. If
          you want to clear a particular item, just click on the little trashcan
          sign, and this will remove that particular item from your shopping
          cart. <br />
          <br />
          <strong>Step 5B:</strong> When you click on “YOUR LIST”, it is at that
          time that you can also save your list, print your list, or clear your
          list. If you want to save your list, just click on the “Save” button
          and a little inset window will pop up. Call your list whatever you
          would like (e.g., “Staples”, “Pizza Night”, or “Holiday Baking”), and
          it will be saved for you. The next time you are on BetterCart, just go
          to “Saved Lists” on the left of your screen (by the postal code, and
          store selection area), click on the saved list you want, and
          BetterCart will re-run the price comparisons for you! This will keep
          you saving the most money on your shopping cart from week to week,
          which is pretty awesome, right! <br />
          <br />
          <strong>Step 6:</strong> See Your Price Comparisons: Once you are
          happy with your shopping cart, click on “See Your Price Comparisons.”
          You are now on the “Price comparisons for your shopping list” page! At
          this point, BetterCart goes into action and does its very best to
          compare all of the products on your list across stores. BetterCart can
          then give you information about how much your shopping cart of
          products would cost if you shopped at the various stores listed. For
          instance, if you see “$19.58” at one store, $23.46 another, and $32.98
          at another, then you can decide which store is giving you the best
          deal!!
          <br />
          <br />
          <strong>Happy Shopping from the BetterCart Team!! </strong>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(HelpPage);
