import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  TextField
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../../bin/env.config";

import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function DialogEdit({open, onClose, product, project_id, reloadData}) {
  const token = useSelector((state) => state.session.token);
  const initState = {id:null, code:'',name:'',description:'',sort_order:0}
  const [inputState, setInputState] = useState(initState);
  const [serverUpdating, setServerUpdating] = useState(false);
  const handleChange = (event) => {
    const inputName = event.target.name;
    setInputState({
      ...inputState,
      [inputName]: event.target.value,
    });
    // if(inputName===''){

    // }
  };
  const onSave = () => {
    setServerUpdating(true)
  };
  useEffect(() => {
    product&&setInputState(product)
  }, [product]);
  useEffect(() => {
    const serverUpdate = async () => {
      let putData = {
        ...inputState,
        project_id: project_id
      }; 
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/update-product`;
      const result = await axios.post(url, putData, {
        headers: {
          Authorization: JSON.stringify(token),
        },
      });
      setServerUpdating(false)
      setInputState(initState)
      reloadData()
      console.log('Save');
      onClose()
    };
    serverUpdating&&serverUpdate();
  }, [serverUpdating]);
  return (
      <Dialog
        //fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="Update Product"
      >
        <DialogTitle>{product?`Update - ${product.name}`:'Add Product'}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            required
            label="Full product name"
            // placeholder="Who do you want to share this with?"
            fullWidth
            onChange={handleChange}
            value={inputState.name}   
            inputProps={{
              name: "name",
              id: "input-name",
              required: false,
            }}
          />
          <TextField            
            label="Key words"
            // placeholder="Who do you want to share this with?"
            fullWidth
            onChange={handleChange}
            value={inputState.key_words}   
            inputProps={{
              name: "key_words",
              id: "input-key_words",
            }}
          />  
          <TextField            
            label="Brand name"
            // placeholder="Who do you want to share this with?"
            fullWidth
            onChange={handleChange}
            value={inputState.brand_name}   
            inputProps={{
              name: "brand_name",
              id: "input-brand_name",
            }}
          />  
          <TextField            
            label="Type"
            // placeholder="Who do you want to share this with?"
            fullWidth
            onChange={handleChange}
            value={inputState.type}   
            inputProps={{
              name: "type",
              id: "input-type",
            }}
          />          
          <TextField
            label="Category"
            fullWidth
            onChange={handleChange}
            value={inputState.category}
            inputProps={{
              name: "category",
              id: "input-category",
            }}            
          />    
          <TextField
            label="Sub Category"
            fullWidth
            onChange={handleChange}
            value={inputState.sub_category}
            inputProps={{
              name: "sub_category",
              id: "input-sub_category",
            }}            
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSave}>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  );
}
