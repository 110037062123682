import React from "react";
// import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    //background: '#12C50D',
    paddingTop: "26px",
    textAlign: "center",
    width: "750px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "14px",
      width: "90vw",
      // paddingLeft:"24px",
      // paddingRight:"24px",
    },
  },
  introText: {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    fontSize: "25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "23px",
    },
  },
  imgContainer: {
    paddingTop: "30px",
  },
  heartImg: {
    height: "auto",
    width: "200px",   
    [theme.breakpoints.down("sm")]: {
      width: "80px",
    },
  },
}));
export default function WhyWeCreateSiteContent() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* <Typography className={classes.introText}>
        We've created this site to help Canadians find the household items they
        need during the COVID-19 crisis.
      </Typography> */}
      <Typography className={classes.imgContainer}>
        <img
          src="./resources/images/Canadian-Heart.png"
          className={classes.heartImg}
        />
      </Typography>
    </div>
  );
}
