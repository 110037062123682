import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from "@material-ui/core/Button";
import VizSensor from "react-visibility-sensor";

const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 200,
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    
  },
  loading:{
    color:theme.palette.secondary.main,
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "auto",
  },  
  ingroup: {},
  
}));

export default function ProductMatchingItemLoading({doLoadMore}) {
  const classes = useStyles();
  
  return (
    
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={3}
        className={classes.ingroup}
      >
        <VizSensor onChange={doLoadMore}>
        <Card className={classes.card}>
          <CardActionArea>
          <CardMedia
              className={classes.media}
              component="div"
            >
            <CircularProgress  className={classes.loading}/>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography className={classes.productName} variant="body2">
                &nbsp;
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
          <Button size="small">load more</Button>
          </CardActions>
        </Card>
        </VizSensor>
      </Grid>
  );
}
