import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';



import { Helpers } from '../../utility/helpers';
import { Utility } from '../../utility/utility';
import ReactGA from 'react-ga';

import { addSavedCart } from '../../actions/session';
import { setSavedCartSuccess } from '../../actions/user';
import { startPriceCompare, updateProductPick, setSelectedStoreCart } from '../../actions/pricecomparison';

import { startRemoveProductToCart, startClearCart, updateProduct } from '../../actions/cart';
import { Hidden } from '@material-ui/core';

import PriceComparisonSummaryChart from './PriceComparisonSummaryChart';

const styles = theme => ({
  root: {
    width: '600px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]:{
      width: '300px'
    }
  },
  productImage: {
    width: 80,
    height: 80,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]:{
      width: 25,
      height: 25,
    }
  },
  card: {
    boxShadow: 'none'
  },
  small: {
    '& svg': {
        fontSize: 18
    }
  },
  listInstructions: {
    color: '#aaa',
    display: 'block',
    paddingTop: theme.spacing(2)
  },
  itemActions: {
    display: 'flex',
    alignItems: 'center',
    '@media(print)': {
      display: 'none'
    }
  },
  moneyBag: {
    fontSize: '1.4em',
  },
  seeComparisons: {
    padding: '0 22px',
    height: '40px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',  
  }, 
  header: {
    textAlign: 'center',
    paddingBottom: '20px',
    paddingTop: '20px'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',    
    fontSize: '28px',    
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }    
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',    
    fontSize: '14px',      
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    }    
  },
actionButton: {
    margin: '5px',
    padding: '5px',
    width: '90% !important',
    fontWeight: 'bold',  
    fontFamily: 'Montserrat',
  }, 
  companyLogo: {
    height: '60px',
    width: '180px',
    //margin: theme.spacing(1),
    cursor: 'pointer',
    display: 'block',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '120px',
      // margin: 'auto',
    } 
  },
  storeName:{
    width:"180px",
    whiteSpace: "nowrap", 
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      width:"65px",
      fontSize: '14px',
    } 
  },
  matchImage: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1)
  },

  product: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    //justifyContent: 'center',
    alignItems: 'center', 
    
  },
  productImg: {
    flexBasis: '10%',
    
  },
  productInfo: {
    flexBasis: '70%',
  
    textAlign: 'left'
  },
  productPrice: {
    flexBasis: '20%',    
    textAlign: 'right'
  },
  moreMatches: {
    justifyContent: 'left !important',
    fontWeight: 'bold'
  },
  divider: {
    height: '2px',
    backgroundColor: '#f6f6f6',    
  }, 
  totalCost: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '60px',
    alignItems: 'center',
  }, 
  totalCostText: {
    flexBasis: '50%',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '18px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    } 
  }, 
  totalCostValue: {
    flexBasis: '50%',    
    textAlign: 'right'
  }, 
  missingProductsMessage: {
    textAlign: 'center',    
    paddingTop: '10px'
  },
  missingProductsMessageText: {    
    fontWeight: 'bold'
  },
  listOtherStore:{
    paddingLeft:"0px",
    paddingRight:"0px"
  },
  otherStore: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '60px', 
    border: "1px solid #ccc",  
    borderRadius:'4px',
    alignItems: 'center',
  }, 
  otherStoreInfo: {
    //flexBasis: '65%',    
    display:'flex',
    justifyContent:"flex-start",
    alignItems: 'center',
  }, 
  otherStoreTotalCostValue: {
    flexBasis: '20%',    
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',      
    }  
  }, 
  otherStoreTotalControl: {
    flexBasis: '20%',    
    textAlign: 'center',
    margin: 'auto',
    marginRight: '2px'
  }, 
  buttonText: {    
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    }    
  }  
});

class PriceComparisonSummaryNew extends Component {

  constructor (props) {
    super(props);

    this.state = {
      anchorEl: null,
      selectedProduct: null,
      showSavedCartSuccess: false,
      isCartNameModalOpen: false, 
      isMenuOpen: false,   
      cartName: '', 
      priceComparison: undefined,
      errors: {}
    };
    this.state.priceComparison = this.props.pricecomparison;
    ReactGA.pageview('/YourListAtStore');

  }

  refTop = React.createRef();
  
  componentDidMount () {    
    //this.loadPage();    
  }

  

  loadPage = () => {         
    
    // if (localStorage.getItem('pricecomparison')) {      
    //   const localPriceComparison = JSON.parse(localStorage.getItem('pricecomparison'));       

    //   this.setState({
    //     priceComparison: localPriceComparison,        
    //   })    
    // }  
    
  }

  removeProduct = (product) => {
    this.props.startRemoveProductToCart(product);
  }

  onHideSavedCartSuccess = () => {
    this.setState({
      isCartNameModalOpen: false,
      cartName: ''
    })
    this.props.setSavedCartSuccess(false)
  }

  renderSubHeader = () => {
    const productCount = this.props.cart.length;
    return `${productCount} item${(productCount > 1 || productCount == 0) ? 's' : ''}`;
  }

  processProduct = () => {
    const { customSeeMySavingsHandlerFn } = this.props;

    // customSeeMySavingsHandlerFn={ () => { if (customSeeMySavingsHandlerFn) { customSeeMySavingsHandlerFn() } } }

    if (customSeeMySavingsHandlerFn) {
      customSeeMySavingsHandlerFn()
    } else {
      this.props.history.push("/pricecompare");      
    }

  }

  onCartNameChange = (e) => {
    this.setState({
      cartName: e.target.value
    })
  }

  closeCartDrawer = () => {
    this.setState({ isCartDrawerOpen: false });
  }

  openLoginPageModal = () => {
    this.setState({ isLoginModalOpen: true });
  }
  cloaseLoginPageModal = () => {
    this.setState({ isLoginModalOpen: false });
  }

  openCartNameModal = () => {
    this.setState({ isCartNameModalOpen: true });
    // console.log("setting to true");
  }

  closeCartNameModal = () => {
    this.setState({ isCartNameModalOpen: false });
    // console.log("setting to false");    
  }  

  increaseQuantity = (product) => {
    product.quantity++;
    this.props.updateProduct(product);
  }

  decreaseQuantity = (product) => {
    product.quantity--;
    this.props.updateProduct(product);
  }

  onSaveCartHandler = () => {
    const { session } = this.props;
    // console.log("in save cart handler");
    if (!Helpers.isLoggedIn(session.user)) {
      this.openLoginPageModal();
      return;
    }

    this.openCartNameModal();
  }

  startCartSaving = () => {
    const { session, cart } = this.props;
    
    if (!this.validateForm()) {
      return;
    }

    const cartForSaving = [];
    for (let product of cart) {
      const p = {...product};
      p.sourceProductID = product._id;
      cartForSaving.push(p);
    }

    this.props.addSavedCart(this.state.cartName, cartForSaving);
  }

  validateForm = () => {
    let isFormValid = true;
    let errors = {};

    if (!Utility.isSet(this.state.cartName) || !this.state.cartName || this.state.cartName.length === 0) {
      errors['cartName'] = `Cart Name is required`;
      isFormValid = false;
    }

    this.setState({
      errors: errors
    });

    return isFormValid;
  }

  onLoginSuccessHandler = () => {
    this.cloaseLoginPageModal();
    this.onSaveCartHandler();
  }  

  openProductMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  closeProductMenu = () => {
    this.setState({ anchorEl: null });
  };
  chooseProduct = (product) => {
    let newProduct = {...product};
    //newProduct.quantity = this.state.selectedProduct.quantity;    
    // console.log(newProduct);
    this.setState({ anchorEl: null, selectedProduct: newProduct });
    //this.props.onProductPick(newProduct);
  }

  isRegularPriceAndCurrentPriceDifferent = (product) => {    
    return product.pricing.currentPrice !== product.pricing.regularPrice;
  }

  pricingText = (product) => {
    const { classes } = this.props;            
    if (!product) {
      return '';
    }
    
    return (
      <div>
       <span className={ classes.productPrice } >{ product.priceDisplayText1 != 'Flyer Item' ? product.priceDisplayText1 : '' }</span> 
        &nbsp;&nbsp;
        {
          (product.isOnSale && (product.pricing.discountType != 5) && this.isRegularPriceAndCurrentPriceDifferent(product) ) && (
            <span className={ classes.regularPrice }>{ Utility.isEmpty(product.pricing.regularPrice) ? '$' + product.pricing.regularPrice : '' }</span>
          )
        }
      </div>
    );
  }
      

  onSelectStoreCart =(store) =>{
    this.props.selectStoreCart(store);
    // this.props.history.push('/cartstore');
  }
  
  

  getTotal = (matchedProducts) => {
    let total = 0;
    //console.log(matchedProducts);
    matchedProducts.map((matchedProduct, index) => {
      const product = matchedProduct.selectedProduct;
      const quantity = matchedProduct.quantity;

      if (product) {
        total += Helpers.calculateRealPrice(product) * quantity;
      }
    });
    // return Helpers.asDecimalText(total);
    return total;
  }

  countMissingItem = (matchedProducts) => {    
    let missingProducts = 0;

    for (let productMatch of matchedProducts) {      
      if (!productMatch.selectedProduct) {
        missingProducts++;
      }
    }
    return missingProducts;
  }

  viewSideBySide = () => {
    this.props.viewSideBySide();
  }
  
  

  renderSummaryStoreList = (matchedData) =>{
    const { classes } = this.props;
    if (!matchedData){
      return '';
    }
    var matchedDataTemp = matchedData.map(priceComparisonResult =>{
           return {
            ...priceComparisonResult,
            countMissing : this.countMissingItem(priceComparisonResult.matches),
            total: this.getTotal(priceComparisonResult.matches)
           }     
    }).sort((a,b)=> a.total - b.total); 
    //console.log(matchedDataTemp);
    return (
      <div>
        <div className={ classes.header }>            
          <Typography className={ classes.title }>
            Price comparisons for your shopping cart:
          </Typography>       
          <Typography className={ classes.subTitle }>
            We’ve crunched the numbers and here’s the amount
            you’d spend if you shopped at each of the stores:
          </Typography> 
        </div>            
        <List>
          {
            matchedDataTemp.map(priceComparisonResult => {
              return (
                <ListItem key={priceComparisonResult.store.storeID}
                onClick={ () => this.onSelectStoreCart(priceComparisonResult.store) }
                className={classes.listOtherStore}
                >
                  <div className={ classes.otherStore }> 
                    <div className={ classes.otherStoreInfo }> 
                      <img className={ classes.companyLogo } 
                          src={ priceComparisonResult.store.companyData.companyLogoLink } 
                      />
                      <span className={classes.storeName}>
                        {priceComparisonResult.store.name}
                      </span>
                    </div>
                    
                    <div className={ classes.otherStoreTotalCostValue }> 
                      <Typography className = { classes.totalCostText } color='secondary'>
                        ${Helpers.asDecimalText(priceComparisonResult.total)}
                      </Typography>     
                      {
                        (priceComparisonResult.countMissing > 0) && (
                          <Typography variant="caption" align="center" color="error">*miss<Hidden smDown>ing</Hidden>  {priceComparisonResult.countMissing} <Hidden smDown>product{priceComparisonResult.countMissing > 1 ? 's' : ''} </Hidden></Typography>
                        )
                      }                   
                    </div>
                    <div className={ classes.otherStoreTotalControl }> 
                      <Button 
                        onClick={ () => this.props.selectStoreCart(priceComparisonResult.store) }
                        //className={ classes.viewAtStore }
                        color="primary"
                        variant="contained"                  
                        //fullWidth={ true } 
                      >
                        <Typography
                          color="inherit"
                          className = { classes.buttonText }
                        >
                          View 
                        </Typography>
                      </Button>
                    </div>                    
                  </div>                                                
                </ListItem>
              );
            })
          }                  
        </List>
        <Button 
          color="primary"
          className = { classes.seeComparisons }
          disabled={this.props.cart.length === 0}
          size="large"
          variant="contained"
          fullWidth={ true }
          onClick={ this.viewSideBySide }
        >
          <Typography
            color="inherit"
            className = { classes.buttonText }
          >
            View side by side comparison
          </Typography>          
        </Button>
        <PriceComparisonSummaryChart  matchedData={matchedDataTemp}/>
      </div>
    )
  }
  renderStoreBestMatches =(matchedData)=>{
    return matchedData && matchedData.length && (
      this.renderSummaryStoreList(matchedData));
  }
  render () {
    const { classes, matchedData, isLoading } = this.props;                
    const { errorOccurred } = this.props.pricecomparison;
    //let isLoading = this.props.pricecomparison.isLoading;
    

    return (
      <div className={ classes.root } >    
        {
          this.renderStoreBestMatches(matchedData)
        }                                                                                
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  session: state.session,  
  cart: state.cart.items,
  pricecomparison: state.pricecomparison,
  user: state.user
});

const mapDispatchToProps = (dispatch, props) => ({
  startRemoveProductToCart: (product) => dispatch(startRemoveProductToCart(product)),
  startPriceCompare: (params) => dispatch(startPriceCompare(params)),
  startClearCart: () => dispatch(startClearCart()),
  updateProduct: (product) => dispatch(updateProduct(product)),
  updateProductPick: (product, store, row, col) => dispatch(updateProductPick(product, store, row, col)),
  addSavedCart: (cartName, products) => dispatch(addSavedCart(cartName, products)),
  setSavedCartSuccess: (showSavedCartSuccess) => dispatch(setSavedCartSuccess(showSavedCartSuccess))
});

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(PriceComparisonSummaryNew)));