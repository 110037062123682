import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

import PossibleExistingMatchingGroup from "./PossibleExisting/PossibleExistingMatchingGroup";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function PossibleExistingMatchingGroupPage(props) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Possible existing matching group
          </Typography>
          {/* <Typography variant="h6" noWrap>
            {original.name} {original.size}
          </Typography> */}
        </Toolbar>
      </AppBar>
      <PossibleExistingMatchingGroup {...props}/>
    </>
  );
}
