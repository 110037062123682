import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  IconButton,
  Paper
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import ButtonEdit from "./Projects/ButtonEdit";
import ButtonSummary from "./Projects/ButtonSummary";
import ButtonSelectBanners from "./Projects/ButtonSelectBanners";
import ButtonSelectCities from "./Projects/ButtonSelectCities";
import ButtonDelete from "./Projects/ButtonDelete";
import ButtonSync from "./Projects/ButtonSync";
import ProductsCount from "./Projects/ProductsCount";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  table: {
    minWidth: 350,
    "& tbody>.MuiTableRow-root:hover": {
      background: "#DDD",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.noWrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export default function CompanyReportToolPage() {
  const history = useHistory();
  const classes = useStyles();  
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id")
  const [filter, setFilter]=useState({q:''})
  const [dataSource, setDataSource] = useState([]);
  const [reloadData, setReloadData] = useState(0);

  const token = useSelector((state) => state.session.token);
  
  const loader = document.querySelector(".loader");
  const showLoader = () => loader.classList.remove("loader--hide");

  const hideLoader = () => {
    loader.classList.add("loader--hide");
    // updateContainer.classList.add("loader--hide");
  };
  const doReloadData =()=>{setReloadData(reloadData+1)}
  useEffect(() => {
    
    const fetchData = async () => {
      showLoader();
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company-report-tool/projects?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(url,filter, {
        headers: {
          Authorization: token,
        },
      });
      setDataSource(result.data.data);
      setIsLoading(false);
      hideLoader();
    };
    fetchData();
  
}, [filter,skip, order, orderBy,reloadData]);
const handleSort=(column)=>{
  setOrder(order==='desc'?'asc':'desc');
  setOrderBy(column);
  setSkip(0);
}
  return (
    <>
     {/* <CssBaseline /> */}
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          {/* <IconButton
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="Close"
          >
            <ArrowBackIcon />
          </IconButton> */}
          <Typography className={classes.title} variant="h6" noWrap>
            Company & Report Tool
          </Typography>   
          <ButtonEdit reloadData={doReloadData}/>       
        </Toolbar>
      </AppBar>
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
        className={classes.root}
        container
        alignItems="center"
        justifyContent="center"
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader aria-label="simple table">
            <TableHead >
              <TableRow>
                {/* <TableCell align="center">data_year_week</TableCell> */}
                <TableCell align="left" width={'200px'}>
                  Command                
                </TableCell>
                <TableCell align="left">
                  ID                
                </TableCell>
                <TableCell align="left" >                    
                  <SortLabel onClick={()=>handleSort('name')}>Name</SortLabel>
                </TableCell>
                <TableCell align="left">
                  # Products                
                </TableCell>
                <TableCell align="left">
                  Last Updated                
                </TableCell>
                <TableCell align="left">
                  Config                
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">
                      <ButtonDelete project={row} reloadData={doReloadData}/> 
                      <ButtonEdit project={row} reloadData={doReloadData}/> 
                      <ButtonSummary project={row} />
                      <ButtonSync project={row}/> 
                    </TableCell>
                    <TableCell align="left">
                      {row.id}
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        to={`/company-report-tool-product/${row.id}`}
                        className={classes.projectName}
                      >
                        {row.name}
                      </Link>
                      <div>{row.description}</div>
                    </TableCell>
                    <TableCell align="left">
                      <ProductsCount projectId={row.id}/>
                    </TableCell>
                    <TableCell align="left">{row.updated_at}</TableCell>
                    <TableCell align="left">
                      <ButtonSelectBanners project={row} />
                      <ButtonSelectCities project={row} />
                    </TableCell>
                  </TableRow>                  
                
              ))}
            </TableBody>             
          </Table>
        </TableContainer>
      </Grid>
      )}
    </>
  );
}
