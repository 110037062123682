import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import StoreDetail from "./StoreDetail";

import axios from "axios";
import envConfig from "../../../bin/env.config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  storeListContainer: {
    maxWidth: "95%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function StoreList(props) {
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = {
    latitude: props.location.latitude,
    longitude: props.location.longitude,
    proximity: 50,
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/products/stock`;
     
      axios.get(url, { params }).then((result) => {
        setStores(result.data.data.stores);
      });
      setIsLoading(false);
    };
    fetchData();
  }, []);
  const classes = useStyles();
  // console.log()
  return isLoading ? (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  ) : (
    <div className={classes.storeListContainer}>
      {stores.map((store) => (
        <StoreDetail key={store.storeDetails.storeID} store={store} />
      ))}
    </div>
  );
}
