// import { searchProduct } from './products';

export const setFilteredStores = (filteredStores = []) => ({
  type: 'SET_FILTERED_STORES',
  filteredStores
});

export const setProximity = (proximity) => ({
  type: 'SET_PROXIMITY',
  proximity
});

// export const setProximity = (proximity) => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: 'SET_PROXIMITY',
//       proximity
//     });

//     dispatch(searchProduct(state.products.searchTerm));
//   };
// };