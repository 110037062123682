import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    width:"50px",
    alignItems: "center",
    // marginBottom:'8px',
    backgroundColor: "#f6f6f6",
    border: "1px solid " + theme.palette.primary.main,
    borderRadius: "8px",
  },
  searchIcon: {
    zIndex: "1000",
    top: "0px",
    right: "0px",
    backgroundColor: "#2650a2",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  input: {
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    padding: "4px 16px",
    color: "#FFF",
  },
}));

export default function ScoreBox({value='0.4', doChangeScore }) {
  const classes = useStyles();
  const [scoreValue, setScoreValue] = useState(value);
  const handleChange = (e) => {
    setScoreValue(e.target.value);
    doChangeScore && doChangeScore(e.target.value);
  };
  const keyPressed = ({ key }) => {
    if (key === "Enter") {
      doChangeScore(scoreValue);
    }
  };
  const submitHandler = (e) => {
    // Prevent form submission on Enter key
    e.preventDefault();
  };
  return (
    <Paper component="form" className={classes.root} onSubmit={submitHandler}>
      <InputBase
        className={classes.input}
        onChange={handleChange}
        value={scoreValue}
        type='number'
        inputProps={{
          maxLength: 1,
          step: "0.1"
        }}
        // defaultValue={value}
        onKeyPress={keyPressed}
      />
    </Paper>
  );
}
