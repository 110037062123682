import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  rootLeft: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
    backgroundColor: theme.palette.secondary.main,
    // paddingTop: "64px",
    // width:"50%"
  },
  rootRight: {
    display: "flex",
  },

  acceptContainer: {
    backgroundColor: "#DDD",
  },
  allWrongButton:{
    color: '#fff',
    backgroundColor: 'rgb(220, 0, 78)',
    borderColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:active': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
    '&:focus': {
      backgroundColor: 'rgb(154, 0, 54)',     
    },
  },
  title: {
    paddingLeft: '10px',
    paddingRight: '50px',
    fontSize: '20px',
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  acceptContainer: {
    backgroundColor: "#DDD",
  },

}));

export default function LeaderBoard({
  selectedProjectId
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { rmtRefresh } = useSelector(
    (state) => state.session
  );
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (selectedProjectId >= 0) {
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/rapid-matching-tool/leader-board`;
        const result = await axios.post(
          url,
          {
            projectId: selectedProjectId           
          },
          {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          }
        );
        setDataSource(result.data.data);
      }
      setIsLoading(false);     
    };
    fetchData();
  }, [rmtRefresh,selectedProjectId]);
  
  return (
    <List className={classes.listProducts}>
      {isLoading ? (
        <ListItem
          button
          // onClick={() => handleSelectedGroupClick(group)}
        >
          <ListItemText
            key={'isLoadingKey'}
            primary={`Loading ...`}
            secondary={<CircularProgress size={16} />}
          />                  
        </ListItem>
      ) : (<>
            {dataSource.length > 0 &&
              dataSource.map((item, index) => (
                <ListItem
                  button
                  key={index}
                  // onClick={() => handleSelectedGroupClick(group)}
                >
                 
                  <ListItemText
                    primary={`${index+1}. ${item.email} (${ Number(item.t).toLocaleString()})`}
                    secondary={<span>
                      <span style={{color:'#00b04f'}}>Matches:<b>{Number(item.m).toLocaleString() }</b>;&nbsp;&nbsp;</span>
                      <span style={{color:'#DC004E'}}>Does not match:<b>{Number(item.w).toLocaleString() }</b>;&nbsp;&nbsp;</span>
                      <span style={{color:'#2650A2'}}>Skip it:<b>{Number(item.s).toLocaleString() }</b>;&nbsp;&nbsp;</span>
                      <span >Similar:<b>{Number(item.similar).toLocaleString() }</b>;&nbsp;&nbsp;</span>
                    </span>}                     
                  />                  
                </ListItem>
              ))}
           </> 
          )}
      </List>
   
  );
}
