import green from '@material-ui/core/colors/green';

export default {
  buttonSpinnerProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};