import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import envConfig from "../../../bin/env.config";
import {  useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  
}));

export default function CitiesDropDownBox({onChange}) {
  const classes = useStyles();
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const token = useSelector((state) => state.session.token);
 const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSelectCity = (city) => {
    setSelectedCity(city)
    setAnchorEl(null);
    onChange('city',city)
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/cities`;      
      const result = await axios.get(url,{
        headers: {
          Authorization: token,
        },
      });
      setCities(result.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return (
    <>
    <Button color="primary" className={classes.buttonCategoryMenu} onClick={handleOpenMenu}
           endIcon={<ExpandMoreIcon/>} disabled={isLoading}
          >{selectedCity||'All Cities'}</Button>
    <Menu
        id="cities-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={()=>setAnchorEl(null)}
      >
        <MenuItem selected={selectedCity==''} onClick={()=>handleSelectCity('')}>All Cities</MenuItem>
        {cities&&cities.map((city)=>
          <MenuItem selected={city==selectedCity} onClick={()=>handleSelectCity(city)}>{city}</MenuItem>
        )}
    </Menu>
    </>    
  );
}
