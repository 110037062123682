import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import axios from "axios";
import envConfig from "../../../bin/env.config";
import {  useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  statusButton:{
    fontWeight: "bold",
    cursor: "pointer",
  }
}));

export default function StatusUpdate({dataStatus,oodItem}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(oodItem.status);
  const [anchorEl, setAnchorEl] = useState(null);  
  const token = useSelector((state) => state.session.token);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelected = (statusId) => {
    setSelectedStatus(statusId)
    setAnchorEl(null);
  };  
  useEffect(() => {    
        const updateStatus = async () => {
          setIsLoading(true);
          const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/check-prices-data/ood-update`;
          axios.post(
            url,
            { 
              product_matching_group_id: oodItem.product_matching_group_id,
              store_id:oodItem.store_id,
              status: selectedStatus
            },
            {
              headers: {
                Authorization: JSON.stringify(token),
              },
            }
          );          
          setIsLoading(false);
        };
        selectedStatus!=oodItem.status&&updateStatus();  
  }, [selectedStatus]);
  return (
    <>
    <span style={{color:dataStatus.dict[selectedStatus].color}} 
          className={classes.statusButton} 
          onClick={handleOpenMenu}>
          {dataStatus.dict[selectedStatus].name}</span>
     
    <Menu
        id="cities-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={()=>setAnchorEl(null)}
      >
        {dataStatus.list.map((status)=>
          status.id<10&&
            <MenuItem selected={status.id==selectedStatus} onClick={()=>handleSelected(status.id)}>
              <span style={{color:status.color}} title={status.description}>
              {status.name} 
              </span>
            </MenuItem>          
        )}
    </Menu>
    </>    
  );
}
