import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";

import ListItem from "./ListItem";
import ProductMatchingGroupListLoading from "./ProductMatchingGroupListItemLoading";

import SearchBox from "./SearchBox";
import axios from "axios";
import envConfig from "../../bin/env.config";
import store from "../../store/store";

const useStyles = makeStyles((theme) => ({  
  table: {
    minWidth: 350,
    '& tbody>.MuiTableRow-root:hover': {
      background: '#DDD',
    }
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function SimilarGroupsSearch(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(10000);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [groups, setGroups] = useState([]);
  const { isManage,currentProductMatchingGroup  } = props;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // console.log('here'+searchText);
      const url = isManage?`${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/search-similar-groups?groupId=${currentProductMatchingGroup.similar_group_id}&similar=true&orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`:
                            `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/search-similar-groups?similar=false&orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      
      const result = await axios.post(
        url,
        { q: searchText },
        {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        }
      );
      
      if (skip === 0) {
        setGroups(result.data.data);
      } else {
        setGroups([...groups, ...result.data.data]);
      }

      setLoadMore(result.data.data.length === limit);
      setIsLoading(false);
    };

    fetchData();
  }, [searchText, skip, order, orderBy]);
  const doLoadMore = (isVisible) => {
    isVisible && setSkip(skip + limit);
  };
  const doSearch = (q) => {
    setSearchText(q);
    setSkip(0);
  };
  const handleSortClick = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSkip(0);
  };
  
  const doSelect = (productMatchingGroup)=>{
    doMatch2GroupSimilar(productMatchingGroup.id,currentProductMatchingGroup.id)
  }
  const doRemove =(productMatchingGroup)=>{
    // console.log(productMatchingGroup);
    doRemoveGroupSimilar(productMatchingGroup.similar_group_id,productMatchingGroup.id)
  }
  const doMatch2GroupSimilar = (groupId1,groupId2) => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/similar`;
      // console.log(postData);
      axios
        .post(url, 
          {id1: groupId1, id2: groupId2},
          {
              headers: {
                'Authorization': JSON.stringify(store.getState().session.token)
              }
          })
        .then((response) => {
          // console.log(response);
          let retGroups = groups.filter((p) => p.id != groupId1);
          setGroups(retGroups);
          alert('Match 2 similar groups success');
          props.doClose();
        })
        .catch((err) => {
          alert(err);
        });
        
  };
  const doRemoveGroupSimilar = (groupId1,groupId2) => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/remove-similar`;
    // console.log(postData);
    axios
      .post(url, 
        {id1: groupId1, id2: groupId2},
        {
            headers: {
              'Authorization': JSON.stringify(store.getState().session.token)
            }
        })
      .then((response) => {
        alert('Remove group similar success!');
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
      
};
  return (
    <>      
      {isLoading ? (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <div>Loading ...</div>
        </Grid>
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="center"
          justifyContent="center"
        >
          <SearchBox doSearch={doSearch} />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === "group_id"}
                        direction={orderBy === "group_id" ? order : "asc"}
                        onClick={() => handleSortClick("group_id")}
                      >
                        ID
                        {orderBy === "group_id" ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>  
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "name"}
                      direction={orderBy === "name" ? order : "asc"}
                      onClick={() => handleSortClick("name")}
                    >
                      Name eg.
                      {orderBy === "name" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={orderBy === "brand_name"}
                      direction={orderBy === "brand_name" ? order : "asc"}
                      onClick={() => handleSortClick("brand_name")}
                    >
                      Brand.
                      {orderBy === "brand_name" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === "size"}
                      direction={orderBy === "size" ? order : "asc"}
                      onClick={() => handleSortClick("size")}
                    >
                      Size eg.
                      {orderBy === "size" ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((row, i) => (
                  // <span>{row.image_url}</span>
                  (row.id != currentProductMatchingGroup.id||isManage)&&
                  <ListItem key={i} productMatchingGroup={row} isManage={isManage} doSelect={doSelect} doRemove={doRemove} />
                ))}
                {loadMore && (
                  <ProductMatchingGroupListLoading doLoadMore={doLoadMore} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
