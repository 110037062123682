import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';


import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';


import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ArrowDropDownSharp from '@material-ui/icons/ArrowDropDownSharp';
import grey from '@material-ui/core/colors/grey';
import { Helpers } from '../../utility/helpers';
import { Utility } from '../../utility/utility';

// import Radio from '@material-ui/core/Radio';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import Skeleton from 'react-loading-skeleton';

import { startAddProductToCart } from '../../actions/cart';



const styles = theme => ({
  root: {
    // ...theme.mixins.gutters(),
    padding: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    // width: '100%',
    height: '250px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '3px',
      marginLeft: '3px',
    }
  },
  rootHorizontal: {
    //padding: 0,
    marginRight: '8px',
    marginLeft: '8px',
    borderRadius: '0px',
    height: '250px',
    //width: '250px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '3px',
      marginLeft: '3px',
    }
  },
  noMatchRoot: {
    background: grey[100],
    boxShadow: 'none',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '210px'
    // ,
    // width: '100%'
  },
  cardContent: {
    boxSizing: 'border-box',
    paddingTop: '0'
  },  
  cardActions: {
    paddingLeft: '5px',
    clear: 'both',
    width: '100%'
  },
  leftIcon: {
    marginRight: theme.spacing(1) - 2,
  },
  iconSmall: {
    fontSize: 20
  },
  productPick: {
    width: '80px'
  },
  addToCartButton: {
    marginTop: theme.spacing(1)
  },
  media: {
    //  object-fit is not supported by IE11.
    objectFit: 'contain',
    cursor: 'pointer',
    width: 160,
    height: 160,
    border: 0,
    marginLeft: '10px',
    marginRight: '10px',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      // width: 60,
      // height: 60,     
    }
  },

  cardContentHorizontal: {
    boxSizing: 'border-box',
    paddingTop: '10px'
  },  
  mediaHorizontal: {    
    objectFit: 'contain',
    cursor: 'pointer',
    width: 80,
    height: 80,
    border: 0,    
    marginLeft: '10px',
    marginRight: '10px',
  },
  TopContentHorizontal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:"center",
    alignItems:"center",
    paddingTop: '10px', 
    //flexWrap: 'wrap'
  },
  productImageHorizontal: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      //width: '100%',    
      marginLeft: '5px'        
    }
  },
  productPriceHorizontal: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      //width: '100%',
      marginLeft: '10px'
    }
  },
  productNameHorizontal: {

  },
  productName: {
    marginTop: '5px',    
    fontWeight: 'bold',    
    fontSize: '13px',    
    fontFamily: 'Montserrat',            
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  productPriceText: {
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Montserrat',   
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
    // ,
    // lineHeight: '1.2em'
  },
  
  productSubPriceText: {
    fontSize: '12px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px'
    }
    // ,
    // lineHeight: '1.2em'
  },

  matchImage: {
    width: 25,
    height: 25,
    marginRight: theme.spacing(1)
  },
  regularPrice: {
    textDecoration: "line-through",
    color: '#fff !important',
    fontWeight: 'normal !important'
  },
  quantityText: {
    paddingTop: '0.5em'
  },
  moreMatches: {
    marginTop: '10px',
    color: '#888',
    justifyContent: 'left !important',
  }, 

  product: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    //justifyContent: 'center',
    alignItems: 'center', 
    
  },
  productImg: {
    flexBasis: '10%',
    
  },
  productInfo: {
    flexBasis: '70%',
  
    textAlign: 'left'
  },
  productPrice: {
    flexBasis: '20%',    
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      textAlign: 'left',
      fontSize: '0.8rem'
    }
  }, 
  
});

const ITEM_HEIGHT = 48;

class ProductMatch extends Component {

  constructor () {
    super();
    this.state = {
      anchorEl: null,
      selectedProduct: null
    }
  }

  componentDidMount () {
    const { productMatchData } = this.props;
    // console.log("Begin");
    // console.log(this.props);
    // console.log("End");
    // console.log('productMatchData');
    // console.log(productMatchData);
    if (!this.state.selectedProduct) {
      if (this.hasAtLeastOneProduct()) {
        this.setState({
          selectedProduct: productMatchData.selectedProduct
        });
        return;
      }
    }
  }

  componentWillReceiveProps (nextProps) {
    
    // if (this.props === nextProps) {
      // this.setState({
      //   selectedProduct: nextProps.productMatchData.selectedProduct
      // });
      // console.log('skip');
      // return;
    // }

    if (nextProps.productMatchData) {
      this.setState({
        selectedProduct: nextProps.productMatchData.selectedProduct
      });
    }

  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.productMatchData !== this.props.productMatchData) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  productSelect = () => {
    const product = this.state.selectedProduct;
    this.props.startAddProductToCart({...product}, 'todaysDeals');
  }

  isProductInTheCart = () => {
    return typeof this.props.cart.itemMappings[this.state.selectedProduct._id] === 'object';
  }

  hasAtLeastOneProduct = () => {
    return this.props.productMatchData && this.props.productMatchData.moreMatches && this.props.productMatchData.moreMatches.length > 0;
  }

  openProductMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeProductMenu = () => {
    this.setState({ anchorEl: null });
  };

  chooseProduct = (product) => {
    const newProduct = {...product};
    newProduct.quantity = this.state.selectedProduct.quantity;    
    // console.log(newProduct);
    this.setState({ anchorEl: null, selectedProduct: newProduct });
    this.props.onProductPick(newProduct);
  }

  renderLoadingState = () => {
    const { classes } = this.props;
    return (
      <div>
          <Skeleton width={175} />
          <br/>
          <Skeleton width={75} />
          <br/>
          <Skeleton width={150} />
          <br/>
          <Skeleton width={150} />
      </div>
    );
  }

  isRegularPriceAndCurrentPriceDifferent = () => {
    const { selectedProduct } = this.state;
    const product = selectedProduct;
    return product.pricing.currentPrice !== product.pricing.regularPrice;
  }

  pricingText = () => {
    const { classes } = this.props;
    const { selectedProduct } = this.state;
    const product = selectedProduct;
  

    if (!product) {
      return '';
    }
    
    return (
      <div>
       <span className={ classes.productPrice } >{ product.priceDisplayText1 != 'Flyer Item' ? product.priceDisplayText1 : '' }</span> 
        &nbsp;&nbsp;
        {
          (product.isOnSale && (product.pricing.discountType != 5) && this.isRegularPriceAndCurrentPriceDifferent() ) && (
            <span className={ classes.regularPrice }>{ Utility.isEmpty(product.pricing.regularPrice) ? '$' + product.pricing.regularPrice : '' }</span>
          )
        }
      </div>
    );
  }

  // dealText = () => {
  //   const { selectedProduct } = this.state;
  //   const product = selectedProduct;
  //   return product.priceDisplayText2;
  // }

  // isSpecialDiscount = () => {
  //   const { selectedProduct } = this.state;
  //   const product = selectedProduct;
  //   const discountType = product.pricing.discountType
  //   return discountType === 3 || discountType === 4 || discountType === 5
  // }

  renderProductDescriptionVertical = () => {
    const { anchorEl, selectedProduct } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const { productMatchData } = this.props;
    const { classes } = this.props;

    if (!selectedProduct) {
      return;
    }

    // Force to use https for external links
    let productImageUrl = selectedProduct.imageURL.replace('http:','https:');

    return (
      <>
        <Card className={ classes.root } style={{ height: this.props.showAddToCartButton ? '300px' : '210px'}} elevation={1}>
          <Grid container spacing={0}  >
            <Grid item style={{maxWidth:'160px'}} >
              <CardMedia
                  className={classes.media}
                  component="img"
                  image={ productImageUrl }
                  title={selectedProduct.name}
                >
              </CardMedia>
            {
              productMatchData.moreMatches.length > 1 && (
                <Button
                  fullWidth={true}
                  size="small"
                  onClick={ this.openProductMenu }
                  className={ classes.moreMatches }
                >
                  More matches <ArrowDropDownSharp/>
                </Button>
              )
            }              
            </Grid>
            <Grid item>
              <CardContent className={classes.cardContent}>
                <Typography className={ classes.productName } variant="body2">
                  { Helpers.fullProductNameWithBrand(selectedProduct) } { selectedProduct.size }
                </Typography>
                <Typography variant="h6" color={ selectedProduct.isOnSale ? 'secondary' : 'secondary' }>
                  { 
                  //#200523 URGENT: The cart comparison page is pulling the wrong product price
                  Helpers.pricingText(selectedProduct)
                    // Helpers.isSpecialDiscount(selectedProduct) ? Helpers.dealText(selectedProduct) : this.pricingText() 
                  }
                </Typography>

                <Typography variant="body1" color={ selectedProduct.isOnSale ? 'secondary' : 'secondary' }>
                  { 
                    // Helpers.isSpecialDiscount(selectedProduct) ? this.pricingText() : Helpers.dealText(selectedProduct)
                  }
                </Typography>

                <Typography variant="caption">
                  { selectedProduct.priceUnit }
                </Typography>

                <Typography variant="caption">
                  { selectedProduct.dateText }
                </Typography>

                {
                  this.props.showQuantity && (
                    <Typography variant='caption' align="left"><b>Qty: {productMatchData.quantity}</b></Typography>
                  )
                }

              </CardContent>
              <CardActions className={ classes.cardActions }>
                {
                  this.props.showPickButton && (
                    <Button
                      className={ classes.productPick }
                      onClick={ () => this.props.onProductPick(selectedProduct) }
                      color="secondary"
                      size="small"
                      fullWidth={ true } 
                    >
                      <Typography
                        color="inherit"
                        variant="caption"
                      >
                        Pick
                      </Typography>
                    </Button>
                  )
                }          
                {
                  this.props.showAddToCartButton && (
                    <Button
                      className={ classes.addToCartButton }
                      onClick={ () => this.productSelect(selectedProduct) }
                      color="primary"
                      variant="contained"
                      size="small"
                      disabled={ this.isProductInTheCart() }
                      fullWidth={ true } 
                    >
                      <Typography
                        color="inherit"
                        variant="caption"
                      >
                        { this.isProductInTheCart() ? 'Add to Cart' : 'Add to Cart' }
                      </Typography>
                    </Button>
                  )
                }
              </CardActions>
            </Grid>
          </Grid>
        </Card>

        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={isMenuOpen}
          onClose={this.closeProductMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5
              // ,
              // width: 200,
            },
          }}
        >
          {productMatchData.moreMatches.map(option => (
            <MenuItem 
              key={option._id} 
              selected={option === 'Pyxis'}
              onClick={ () => {this.chooseProduct(option) }}>
              <img className={ classes.matchImage } src={ option.imageURL } />
              {option.fullName}
            </MenuItem>
          ))}
        </Menu>

      </>
    );
  }

  renderProductDescriptionHorizontal =() =>{
    const { anchorEl, selectedProduct } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const { productMatchData } = this.props;
    const { classes } = this.props;

    if (!selectedProduct) {
      return;
    }

    // Force to use https for external links
    let productImageUrl = selectedProduct.imageURL.replace('http:','https:');

    return (
      <>        
          <Card className={ classes.rootHorizontal } >            
            <div className={ classes.TopContentHorizontal }>
              <div className={ classes.productImageHorizontal }>
                <CardMedia
                    className={classes.mediaHorizontal}
                    component="img"
                    image={ productImageUrl }
                    title={selectedProduct.name}
                  >
                </CardMedia>  
              </div>
              <div className={ classes.productPriceHorizontal }>
                <Typography className={ classes.productPriceText } color={ selectedProduct.isOnSale ? 'secondary' : 'secondary' }>
                  { 
                    //#200523 URGENT: The cart comparison page is pulling the wrong product price
                    Helpers.pricingText(selectedProduct)                     
                    //  Helpers.isSpecialDiscount(selectedProduct) ? Helpers.dealText(selectedProduct) : this.pricingText()                     
                  }
                </Typography>

                <Typography className={ classes.productSubPriceText } color={ selectedProduct.isOnSale ? 'secondary' : 'secondary' }>
                  { 
                    // Helpers.isSpecialDiscount(selectedProduct) ? this.pricingText() : Helpers.dealText(selectedProduct)
                  }
                </Typography>

                <Typography className={ classes.productSubPriceText }>
                  { selectedProduct.priceUnit }
                </Typography>
                
                {
                  this.props.showQuantity && (
                    <Typography className={ classes.productSubPriceText } align="left">Qty: {productMatchData.quantity}</Typography>
                  )
                }
              </div>
            </div>                                                      
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom className={ classes.productName } variant="body2">
                { Helpers.fullProductNameWithBrand(selectedProduct) } { selectedProduct.size }
              </Typography>
              <Typography variant="caption">
                { selectedProduct.dateText }
              </Typography>

              
              {
                productMatchData.moreMatches.length > 1 && (
                  <Button
                    fullWidth={true}
                    size="small"
                    onClick={ this.openProductMenu }
                    className={ classes.moreMatches }
                  >
                    <Typography className={ classes.productSubPriceText }>
                      More matches 
                    </Typography>
                    <ArrowDropDownSharp/>
                  </Button>
                )
              }  

            </CardContent>
            <CardActions className={ classes.cardActions }>
              {
                this.props.showPickButton && (
                  <Button
                    className={ classes.productPick }
                    onClick={ () => this.props.onProductPick(selectedProduct) }
                    color="secondary"
                    size="small"
                    fullWidth={ true } 
                  >
                    <Typography
                      color="inherit"
                      variant="caption"
                    >
                      Pick
                    </Typography>
                  </Button>
                )
              }          
              {
                this.props.showAddToCartButton && (
                  <Button
                    className={ classes.addToCartButton }
                    onClick={ () => this.productSelect(selectedProduct) }
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={ this.isProductInTheCart() }
                    fullWidth={ true } 
                  >
                    <Typography
                      color="inherit"
                      variant="caption"
                    >
                      { this.isProductInTheCart() ? 'Add to Cart' : 'Add to Cart' }
                    </Typography>
                  </Button>
                )
              }
            </CardActions>              
          </Card>

          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={isMenuOpen}
            onClose={this.closeProductMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
                // ,
                // width: 200,
              },
            }}
          >
            {productMatchData.moreMatches.map(option => (
              <MenuItem 
                key={option._id} 
                selected={option === 'Pyxis'}
                onClick={ () => {this.chooseProduct(option) }}>
                <img className={ classes.matchImage } src={ option.imageURL } />
                <Typography className={ classes.productSubPriceText }>
                  {option.fullName}
                </Typography>                
              </MenuItem>
            ))}
          </Menu>          
      </>
    );
  }

  renderProductAsListItem () {
    const { anchorEl, selectedProduct } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const { productMatchData } = this.props;
    const { classes } = this.props;

    if (!selectedProduct) {
      return;
    }

    // Force to use https for external links
    let productImageUrl = selectedProduct.imageURL.replace('http:','https:');
    let isSpecialDiscountResult = Helpers.isSpecialDiscount(selectedProduct);
    return (
      <div>        
        <div className={ classes.product }>
          <div className={ classes.productImg }>
            <img className={ classes.media } 
                src={ selectedProduct.imageURL.replace('http:','https:') } 
            />
          </div>
          <div className={ classes.productInfo }>
            <Typography gutterBottom className={ classes.productName } variant="body2">
              { Helpers.fullProductNameWithBrand(selectedProduct) } { selectedProduct.size }
            </Typography>
            {
              this.props.showQuantity && (
                <Typography variant='caption' align="left"><b>Qty: {productMatchData.quantity}</b></Typography>
              )
            }
            <Hidden smUp>
              <div className={ classes.productPrice }> 
                <Typography variant="body1" color={'secondary'} className={classes.productPriceText}>
                  {/* { Helpers.isSpecialDiscount(selectedProduct) ? Helpers.dealText(selectedProduct) : this.pricingText()}
                  , { Helpers.isSpecialDiscount(selectedProduct) ? this.pricingText() : Helpers.dealText(selectedProduct) }
                 */}
                  { Helpers.pricingText(selectedProduct)}
                  {/* { isSpecialDiscountResult ? ',':'' }
                  { isSpecialDiscountResult ? '':this.pricingText() } */}
                
                </Typography>
              </div>
            </Hidden>
            {
                productMatchData.moreMatches.length > 1 && (
                  <Button
                    fullWidth={true}
                    size="small"
                    onClick={ this.openProductMenu }
                    className={ classes.moreMatches }
                  >
                    More matches <ArrowDropDownSharp/>
                  </Button>
                )
              }   
          </div>
          <Hidden smDown>  
            <div className={ classes.productPrice }> 
              <Typography variant="h6" color={ selectedProduct.isOnSale ? 'secondary' : 'secondary' }>
                { 
                // #200523 URGENT: The cart comparison page is pulling the wrong product price
                  Helpers.pricingText(selectedProduct) 
                  // Helpers.isSpecialDiscount(selectedProduct) ? Helpers.dealText(selectedProduct) : this.pricingText() 
                }
              </Typography>

              <Typography variant="body1" color={ selectedProduct.isOnSale ? 'secondary' : 'secondary' }>
                { 
                  Helpers.isSpecialDiscount(selectedProduct) ? this.pricingText() : Helpers.dealText(selectedProduct)
                }
              </Typography>

              <Typography variant="caption">
                { selectedProduct.priceUnit }
              </Typography>

              <Typography variant="caption">
                { selectedProduct.dateText }
              </Typography>
              
            </div>
          </Hidden>
        </div>                                                                                             
          <Menu
            id="long-menu"
            anchorEl={this.state.anchorEl}
            open={isMenuOpen}
            onClose={this.closeProductMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5
                // ,
                // width: 200,
              },
            }}
          >
            {productMatchData.moreMatches.map(option => (
              <MenuItem 
                key={option._id} 
                selected={option === 'Pyxis'}
                onClick={ () => {this.chooseProduct(option) }}>
                <img className={ classes.matchImage } src={ option.imageURL } />
                {option.fullName}
              </MenuItem>
            ))}
          </Menu>          
      </div>
    )
  }


  renderEmptyState = () => {
    const { classes } = this.props;
    return (
      <Card className={ `${classes.root} ${classes.noMatchRoot}` }>
          <Typography align="center" variant="overline" noWrap={true}>No Match</Typography>
      </Card>
    )
  }

  renderProductDescription = () =>{
    return this.props.layout ==='cart-hozirontal' ? this.renderProductDescriptionHorizontal() : this.renderProductDescriptionVertical()
  }

  renderProduct = () => {
    return this.hasAtLeastOneProduct() ? this.renderProductDescription() : this.renderEmptyState()
    //return this.hasAtLeastOneProduct() ? this.renderProductDescription() : ''
  }

  renderProductCart = () =>{
    return this.props.isLoading ? this.renderLoadingState() : this.renderProduct()            
  }

  render (props) {
    return (
      <div>
        {
          this.props.layout ==='list' ? this.renderProductAsListItem() : this.renderProductCart()
        }
      </div>
    );
  }

}

const mapStateToProps = (state, props) => ({
  cart: state.cart
});
const mapDispatchToProps = (dispatch, props) => ({
  startAddProductToCart: (product, productSource) => dispatch(startAddProductToCart(product, productSource)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProductMatch));
