import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PlacesAutocomplete from 'react-places-autocomplete';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import LocationOn from '@material-ui/icons/PinDrop';
import MyLocation from '@material-ui/icons/MyLocation';
import CircularProgress from '@material-ui/core/CircularProgress';
import DistancePicker from '../DistancePicker/DistancePicker';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

import { savePostalRadius } from "../../actions/session";
import { 
  setLocation,
  setLocationAndGetStoresNearby
} from '../../actions/location';
import { Helpers } from "../../utility/helpers";

import { setFilteredStores } from "../../actions/searchfilters";
import Alert from "../Alert/Alert";

import Geocode from "react-geocode";

import {
  geocodeByAddress,
  // geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';

const styles = theme => ({ 
  locationButton: {
    justifyContent: 'center'
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  
  locationPicker: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '450px',
    height: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      height: theme.spacing(6),
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '25px',
    fontWeight: '400', 
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    textTransform: "uppercase"
  },
  iconButtonText: {
    fontSize: '48px',
    fontWeight: 'bold',    
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    }
  },
  iconButton: {
    padding: 10,    
  },
  divider: {
    height: "100%",
    margin: "8px",
  },
  button: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '458px',    
    height: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: '258px',
      height: theme.spacing(6),
    }
  },
  buttonText: {
    color: '#fff',
    //fontWeight: 'bold',    
    fontSize: '25px',  
    fontWeight: '600',  
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    }
  }, 
  distancePicker: {
    padding: '10px 5px',
    display: 'block',    
    textAlign: 'center',
    width: '450px',
    height: '180px',
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      height: '140px',
    }
  },
  distanceText: {
    color: '#00b04f',
    fontWeight: 'bold',    
    fontSize: '20px',    
    fontFamily: 'Montserrat',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    }
  }, 
  distance: {
    '& > *': {
      margin: '5px',      
    },
  },  
  distanceButton: {
    size: 'large',
    [theme.breakpoints.down('sm')]: {
      size: 'small',
    }
  } ,

  snackbarRoot:{
    //color: theme.palette.secondary.main,
    //borderColor: theme.palette.secondary.main,
    color: '#ff0000',
    borderColor: '#ff0000',
    borderWidth: '2px',
    borderStyle: 'solid',
    backgroundColor: "#fff",
    fontWeight: 'bold',
    marginTop: '60px',
    //marginRight: '-10px'
  },
  fabProgress: {
    color: '#00b04f',
    position: 'absolute',
    //top: -2,
    //left: -2,
    zIndex: 1,
  },
  
});
function debounce (fn, delay) {
  return args => {
      clearTimeout(fn.id)

      fn.id = setTimeout(() => {
          fn.call(this, args)
      }, delay)
  }
}
class LocationPicker extends Component {

  constructor (props) {
    super(props);

    this.state = {
      searchValue: this.props.location.postal || '',
      issSearching: false,
      postalCodeNotFound: false,   
      proximityMenu: Boolean(this.props.location.postal),
      currentPostal: this.props.location.postal || '',

    };    
    this.alertPopup = React.createRef();
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.doGoogleCheck = this.doGoogleCheck.bind(this);
  
    // Debounce
    this.doGoogleCheck = debounce(this.doGoogleCheck, 500);
    // console.log(this.props.location);
  }
  openAlertPopup = (severity,message)=>{
    this.alertPopup.current.doAlert(severity, message);
  }
  showAlertError=()=>{
    this.openAlertPopup("error","Please enter your postal code!");
  }
  componentDidMount () {   
  }
  keyPress=(e)=>{
    if (e.keyCode === 13) {
      //alert('enter press');
      e.preventDefault();
      if(this.state.proximityMenu){
        this.onButtonClick();
      }
    }
  }
  onSearchInputChange = (e)=>{
    // console.log(e);
    if (e.keyCode === 13) {
        e.preventDefault();
    }else{
      let searchTerm = e.target.value.trim();
      this.setState({
        searchValue: searchTerm.toUpperCase(),
      }) 
      
      this.doGoogleCheck(e);
    }
  } 


  doGoogleCheck = () => {
    let searchTerm = this.state.searchValue;
      
    this.setState({
      issSearching: true
    });
      
      let postal = '';
      let province = 'SK'
      let country = 'SK'
      geocodeByAddress(searchTerm)
        .then(results => {  
          console.log(results);
          if (results.length === 0) {
            throw Error("Invalid search value");
          }
          // console.log(results);
          const address = results[0];
          const addressLength = address.address_components.length;
          // console.log(addressLength);
          postal = address.address_components[0].long_name;
          province = address.address_components[addressLength-2].short_name;
          country = address.address_components[addressLength-1].short_name;
          // console.log(country);
          // if ((country != 'CA') ||(country != 'US')) {
          //   throw Error("Invalid search value");
          // }
          // this.setState({
          //   searchValue: postal.trim()
          // });
  
          return getLatLng(address)
        })
        .then(latLng => {
          // console.log(latLng);
          this.setState({
            issSearching: false,
            proximityMenu: true
          });
          
          this.setLocation(postal, latLng.lat, latLng.lng, province);
        
        })
        .catch(error => {
         
          let postalCodeNotFound = false;
          let errorOccurred = false;
  
          if (error === 'ZERO_RESULTS') {
            postalCodeNotFound = true;
          } else {
            errorOccurred = true;
          }
          
          if (searchTerm.length>7){
            searchTerm = searchTerm.substring(0, 7);
          }
         
          this.setState({
           issSearching: false,
            postalCodeNotFound,
          });
          if(errorOccurred)this.showAlertError();
        });
    
  }

  onLocateClick = () => {    
    this.setState({
      issSearching: true
    }); 
    if (navigator.geolocation) {
      
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
           // for when getting location is a success
           //console.log('latitude', position.coords.latitude, 
           //            'longitude', position.coords.longitude);

           // get postal from google
           Geocode.setApiKey("AIzaSyB7Gz3tys7ltMXac8LOi8Tb_q2a4ow7kSs");
           Geocode.setLanguage("en");
           //Geocode.enableDebug();
           // Get address from latidude & longitude.
            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
              response => {
                const formattedAddress = response.results[0].formatted_address;                
                if (formattedAddress){
                  const address = formattedAddress.split(',');
                  const postal = address[address.length-2].substring(3).trim();                  
                  const province = address[address.length-2].substring(0,3).trim();
                  // console.log(province);              
                  this.setState({
                    searchValue: postal,
                    proximityMenu: true,
                    issSearching: false,
                  });
                  this.setLocation(postal, position.coords.latitude, position.coords.longitude, province);
                }
                
              },
              error => {
                console.error(error);
              }
            );           
        },
        (error_message) => {
          // for when getting location results in an error
          console.error('An error has occured while retrieving location', error_message)
        }
      );
    } else {
      // geolocation is not supported
      // get your location some other way
      console.log('geolocation is not enabled on this browser')
    }     
  }

  setLocation = (postal, latitude, longitude) =>{
    // if (this.state.currentPostal != postal){
      this.props.setLocation(postal, latitude, longitude); 
    // }
  }

  doLoadData =  async () => {
        // console.log(this.props.location);
        const { postal, latitude, longitude } = this.props.location;
        const { proximity } = this.props.searchfilters;

        await this.props.setFilteredStores([]);//Reset filtered Store
        // console.log('here 0')
        await this.props.setLocationAndGetStoresNearby(postal, latitude, longitude, proximity);
        // console.log('here');
        if (Helpers.isLoggedIn(this.props.session.user)) {
          //Save postalCode and Radius       
          this.props.savePostalRadius(
            postal,
            proximity,
            latitude,
            longitude
          );
        
        }
        return true;      
  }
  onButtonClick = async () => { 
    // alert('here');
    try{
      if (this.props.location.postal!==''){
      
        await this.doLoadData();//.then(()=>{console.log('after do Load Data then ')});
        // console.log('after do Load Data');
        this.props.goNextPage();    
        
      } 
      else{
        this.showAlertError();
      }      
    }catch(e){console.log(e)}
    
  }
  
  
  render () {
    const { classes, buttonText,hideDistanceChose } = this.props; 
    const isMenuOpen = Boolean(this.state.proximityMenu);      
    return (
      <div>
        <Grid container justifyContent="center">
          <Grid item >
            <Paper component="form" className={classes.locationPicker}>
              <IconButton className={classes.iconButton} aria-label="menu" disabled={true}>
                <LocationOn className={classes.iconButtonText}/>
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Enter your postal code"
                value={ this.state.searchValue }
                onChange={this.onSearchInputChange}  
                onKeyDown={this.keyPress}
                autoFocus                                   
              />
              
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton 
                color="primary" 
                className={classes.iconButton} aria-label="locate"
                onClick={this.onLocateClick}
              >
                <MyLocation className={classes.iconButtonText} />
                {this.state.issSearching && <CircularProgress className={classes.fabProgress} />}
              </IconButton>              
            </Paper>
            <br/>
            {
              this.props.location.postal&&!hideDistanceChose && isMenuOpen && (
                <DistancePicker />                
              )               
            }                        
            <Button 
              className={classes.button} 
              onClick={ () => this.onButtonClick() }
              // disabled={ this.state.currentPostal.length != 2 }
              variant="contained" 
              color="secondary" 
              disableElevation>
              <Typography className={ classes.buttonText }>{!!buttonText?buttonText:"Start shopping"}</Typography>                
            </Button>            
          </Grid>
        </Grid>
        <Alert ref={this.alertPopup} />
        
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  location: state.location,  
  session: state.session,
  searchfilters: state.searchfilters,
});

const mapDispatchToProps = (dispatch, props) => ({
  setFilteredStores: (stores) => {
    dispatch(setFilteredStores(stores));
  },
  savePostalRadius: (postal, radius, latitude,longitude) => {
    dispatch(savePostalRadius(postal, radius, latitude,longitude));
  },
  setLocation: (postal, latitude, longitude) => dispatch(setLocation(postal, latitude, longitude)),  
  //setLocationAndUpdateStoresNearby: (postal, latitude, longitude) => dispatch(setLocationAndUpdateStoresNearby(postal, latitude, longitude)),
  setLocationAndGetStoresNearby: (postal, latitude, longitude,proximity) => dispatch(setLocationAndGetStoresNearby(postal, latitude, longitude, proximity)),
  // clearSearchHistory: () => dispatch(clearSearchHistory()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LocationPicker));
