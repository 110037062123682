import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from 'react-loading-skeleton';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { Utility } from '../../utility/utility';

import { setFilteredStores } from '../../actions/searchfilters';
import { saveFavoriteStores } from '../../actions/session';

import ReactGA from 'react-ga';


const styles = theme => ({ 
  storeList: {
    width: '100%'
  },
  reportingButton: {
    color: '#999',
    marginTop:'1em'
  }
});

class StorePickerComponent extends Component {

  state = {};

  constructor (props) {
    super(props);
    this.state = { 
      isHidden: false,
      isReportDialogOpen: false,
      isLoading: true
    }
  }

  handleChange = storeID => event => {
    // this.setState({ [storeID]: event.target.checked });
             
    const checked = event.target.checked;
    let updatedFilteredStores = this.props.searchfilters.filteredStores;
        
    if (checked) {
      for (let store of this.props.stores) {
        if (store._id === storeID) {
          updatedFilteredStores.push(store);
          break;
        }
      }
    } else {
      updatedFilteredStores = updatedFilteredStores.filter((store) => {
        return store._id !== storeID;
      });
    }    
    
    this.props.setFilteredStores(updatedFilteredStores);
  };

  onSave = () => {
    const selectedStores = this.props.stores.filter((store) => this.state[store._id]);
    this.props.setFilteredStores(selectedStores);
    this.props.selectedStores(selectedStores);
    
    let favoriteStores='';
    if (selectedStores && selectedStores.length > 0) {
      const stores = selectedStores.map((store) => store._id);
      favoriteStores = stores.join(',');
    }    
    this.props.saveFavoriteStores(favoriteStores,this.props.location.postal);    
  }


  onOpenReportDialog = () => {
    this.setState({ isReportDialogOpen: true });
    // console.log("setting to false");    
    ReactGA.pageview("ReportAMissingStore");    
  }   

  onCloseReportDialog = () => {
    this.setState({ isReportDialogOpen: false });
    // console.log("setting to false");    
  }    

  toggleHidden = () => {
    this.setState({
      isHidden: !this.state.isHidden
    })
  }

  renderLoadingState = () => {
    return (
      <div>
        <Skeleton width={180} />
        <br/>
        <Skeleton width={180} />
        <br/>
        <Skeleton width={180} />
      </div>
    );
  }

  renderForm = () => {
    const { classes, session } = this.props;           
    if(!this.state.isHidden){
      return (
        <div>
          <FormGroup className={ classes.storeList }>
              {
                this.props.stores.map((store) => {
                  let content =  store.name;
                  if (store.distance){
                    content +=' - ' + Utility.roundTo1Decimal(store.distance) + 'km';
                  }                                   
                  
                  return (
                    <FormControlLabel
                      key={ store._id }
                      control={
                         <Checkbox
                          checked={this.state[store._id]}                          
                          onChange={this.handleChange(store._id)}
                          value={ store._id }
                        />
                      }
                      label={ content }                      
                    />
                  );
                })
              }
            </FormGroup>
            <br/>
            <Button
              onClick={ () => this.onSave() }
              disabled={ this.props.searchfilters.filteredStores.length === 0 }
              color="secondary"
              variant="contained"
              size="small"
              fullWidth={true}
              >
              Save
            </Button>
            <Button
              onClick={ () => this.onOpenReportDialog() }
              size="small"
              fullWidth={true}
              disabled={ this.props.searchfilters.filteredStores.length === 0 }
              className = { classes.reportingButton }
              >
              Report a missing store
            </Button>
          </div>
        );
    }
  }

  renderReportDialog = () => {
    return (
      <Dialog 
        open={ this.state.isReportDialogOpen }
        onClose={this.onCloseReportDialog}
        >
        <DialogTitle>Report a missing store location</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please <a href="mailto:support@bettercart.ca?subject=I%20found%20a%20missing%20store&body=Hi,%0D%0A%0D%0A%20I%20found%20that%20there%20is%20a%20store%20missing%20from%20your%20list.%0D%0A%0D%0AThe%20store%20location%20that%20is%20missing%20is:%0D%0A%0D%0A">email us</a> if we are missing a store from any of the grocer chains we work with.<br /><br />
              Currently BetterCart works with the following grocery chains:
              <ul>
                <li>
                  Real Canadian Superstore
                </li>
                <li>
                  Save on Foods
                </li>
                <li>
                  Sobeys
                </li>
                <li>
                  The Independent Grocer
                </li>
                <li>
                  Extra Foods
                </li>
                <li>
                  Walmart
                </li>
                <li>
                  Thrify Foods
                </li>
                <li>
                  Co-op
                </li>                
                <li>
                  Quality Foods
                </li>                 
                <li>
                  No Frills
                </li> 
                <li>
                  Foodland
                </li> 
                <li>
                  Atlantic Superstore
                </li>                                                 
                <li>
                  Fortinos
                </li>  
                <li>
                  Independent City Market
                </li>  
                <li>
                  Maxi
                </li>  
                <li>
                  Dominion
                </li>  
                <li>
                  Valu-Mart
                </li>  
                <li>
                  Zehrs
                 </li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseReportDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>          
      </Dialog>
    )
  }

  render () {
    //  let favoriteStores=[];  
    //  console.log(this.state.isLoaded);    
    //  if (this.state.isLoaded){
    //   for (let s of this.props.searchfilters.filteredStores) {    
    //     favoriteStores.push(s._id);
    //   }      
    //  }else{
    //   if (this.props.session.user){
    //     favoriteStores = this.props.session.user.favoriteStores;
    //   }
    //   this.setState({
    //     isLoaded: true
    //   })
    //  }     
   
    let updatedFilteredStores = this.props.searchfilters.filteredStores;
    // console.log(this.state.isLoading);
    // console.log('updatedFilteredStores'+ updatedFilteredStores.length);
    // console.log('this.props.session.user:'+ this.props.session.user);

    if (updatedFilteredStores.length>0){
      if (this.state.isLoading){
        let favoriteStores=[];        
        if (this.props.session.user){
          favoriteStores = this.props.session.user.favoriteStores;
          
          if (favoriteStores && favoriteStores.length>0){
            updatedFilteredStores = updatedFilteredStores.filter((store) => {
              return favoriteStores.includes(store._id);
            });        
          }          
          this.props.setFilteredStores(updatedFilteredStores);
          this.setState({
            isLoading: false
          })
        }
      }
    }
                    
    this.props.stores.forEach((store) => {
      let selected = false;      
                        
      for (let s of this.props.searchfilters.filteredStores) {
        if (s._id === store._id) {
          selected = true;
        }      
      }

      this.state[store._id] = selected;
    });

    return (
      <div style={{width:'100%'}}>
        { !this.state.isHidden && (<ArrowDropDown onClick={this.toggleHidden} style={{float:'right'}} />)}
        { this.state.isHidden && (<ArrowRight onClick={this.toggleHidden} style={{float:'right'}} />)}
        <Typography variant="h6" onClick={this.toggleHidden} style={{width:'100%'}}>Select The Stores You Want To Shop At </Typography>

        {
          this.props.isLoadingTodaysDeals ? this.renderLoadingState() : this.renderForm()
        }
        { this.renderReportDialog() }
      </div>
    );
  }
};

const mapStateToProps = (state, props) => ({
  location: state.location,
  session: state.session,
  searchfilters: state.searchfilters,
  isLoadingTodaysDeals: state.products.isLoadingTodaysDeals,
});

const mapDispatchToProps = (dispatch, props) => ({
  setFilteredStores: (stores) => { dispatch(setFilteredStores(stores)) },
  saveFavoriteStores: (stores,postal) => { dispatch(saveFavoriteStores(stores,postal)) }
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StorePickerComponent));
