import React, {
    useState,
    useEffect,    
  } from "react";
  import { makeStyles } from "@material-ui/core/styles";
  import Menu from '@material-ui/core/Menu';
  import MenuItem from '@material-ui/core/MenuItem';
  import Fade from '@material-ui/core/Fade';

  import axios from "axios";
  import envConfig from "../../../../bin/env.config";
  import store from "../../../../store/store";
  const useStyles = makeStyles((theme) => ({}));
  
  export default function MoreButtonsMenu(props){
    const classes = useStyles();
    const {
      closeMoreButtonsMenu,
      anchorEl,
      groupId,
      productMatch,
      updateIsMultiVariety
    } = props;
    const isMenuOpen = Boolean(anchorEl);
    const handleUpdateIsMultiVariety = () => {
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/update-is-multi-variety`;
      if (productMatch && productMatch.id > 0) {
        let tempProductMatch = {...productMatch}
        tempProductMatch.type = productMatch.type==='variety'?'potential':'variety'
        let postData = {
          product_variety_id: tempProductMatch.id,
          product_id: groupId,
          isMultiVariety: productMatch.type==='variety'?0:1
         };
        axios
          .post(url, postData, {
            headers: {
              Authorization: JSON.stringify(store.getState().session.token),
            },
          })
          .then(() => {
            updateIsMultiVariety && updateIsMultiVariety(tempProductMatch); 
            closeMoreButtonsMenu && closeMoreButtonsMenu();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };  
    return (
        <Menu
          id="more-buttons-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={closeMoreButtonsMenu}
          PaperProps={{
            style: {
              // maxHeight: ITEM_HEIGHT * 4.5
              // ,
              // width: 200,
            },
          }}
          TransitionComponent={Fade}
        >
          {productMatch && productMatch.type==='variety'?(
            <MenuItem onClick={handleUpdateIsMultiVariety}>
              Is not multi variety
            </MenuItem>
          ):(
            <MenuItem onClick={handleUpdateIsMultiVariety}> 
              Is multi variety
            </MenuItem>
          )
          }          
        </Menu>
    );
  };
  