import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import SettingsIcon  from "@material-ui/icons/Settings";
import DialogWaitingCalculateMatches from "./DialogWaitingCalculateMatches";
import {
  IconButton,
  Button
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
 
}));



export default function ButtonCalculateMatches({ project_id, flow_type="Matching" }) {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOnOpen = () => {
    setIsDialogOpen(true);
  };
  const handleOnClose = () => {
    setIsDialogOpen(false);
  }; 
 
  return (
    <>     
      <Button  variant="contained" startIcon={<SettingsIcon />} onClick={handleOnOpen}>
            Calculate {flow_type}
      </Button>
      
      <DialogWaitingCalculateMatches
        //fullScreen
        open={isDialogOpen}
        onClose={handleOnClose}       
        project_id={project_id}
        flow_type={flow_type}
      />
    </>
  );
}
