import axios from 'axios';
import envConfig from '../bin/env.config';
import {
  setFavoriteStoresLoader,
  setFavoriteStoresError,
  setFavoriteStoresSuccess,
  
  setFavoriteProductLoader, 
  setFavoriteProductError, 
  setFavoriteProductSuccess,   
  
  setAlertProductLoader, 
  setAlertProductError, 
  setAlertProductSuccess,  

  setSavedCarttLoader, 
  setSavedCartError, 
  setSavedCartSuccess, 
  setUserProfileLoader, 
  setUserProfileError,
  
  setRemoveFavoriteProductLoader,
  setRemoveFavoriteProductError,
  setRemoveFavoriteProductSuccess,

  setRemoveAlertProductLoader,
  setRemoveAlertProductError,
  setRemoveAlertProductSuccess,

  setRemoveSavedCarttLoader,
  setRemoveSavedCartError,
  setRemoveSavedCartSuccess
} from './user';

// import { setFilteredStores} from './searchfilters';

export const setHideInstruction = (hideInstruction = true) => ({
  type: 'SET_HIDE_INSTRUCTION',
  hideInstruction
});
export const setRmtRefresh = () => ({
  type: 'RMT_REFRESH',
});
export const setLogInLoader = (showLoginLoader = true) => ({
  type: 'SET_LOG_IN_LOADER',
  showLoginLoader
});

export const setLogInError = (loginError) => ({
  type: 'SET_LOG_IN_ERROR',
  loginError
});

export const setFacebookLogInLoader = (showFacebookLoginLoader = true) => ({
  type: 'SET_FACEBOOK_LOG_IN_LOADER',
  showFacebookLoginLoader
});

export const setFacebookLogInError = (facebookLoginError) => ({
  type: 'SET_FACEBOOK_LOG_IN_ERROR',
  facebookLoginError
});

export const setLogInUser = (user = {}) => {  
  // localStorage.setItem('user', JSON.stringify(user));
  return {
    type: 'SET_LOG_IN_USER',
    user
 }   
};

export const setAuthToken = (data = { user: {}, token: '' }) => {
  return (dispatch) => {
    
    // localStorage.setItem('token', data.token);

    dispatch(setLogInUser(data.user))
    dispatch({
      type: 'SET_AUTH_TOKEN',
      token: data.token
    });
  };
};

export const setSignUpLoader = (showSignUpLoader = true) => ({
  type: 'SET_SIGN_UP_LOADER',
  showSignUpLoader
});

export const setSignUpError = (signUpError) => ({
  type: 'SET_SIGN_UP_ERROR',
  signUpError
});

export const logout = () => {
 return {
    type: 'LOGOUT'
 } 
}

export const login = (username, password) => {
  return (dispatch, getState) => {

    return new Promise((resolve, reject) => {
      dispatch(setLogInLoader(true));
      dispatch(setLogInError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/auth`;
      axios.post(url, {
          username: username,
          password: password,
        })  
        .then((response) => {
          const data = response.data.data;
            // console.log(data);
            // setTimeout(() => {
            dispatch(setLogInLoader(false));
            dispatch(setAuthToken(data));
            // dispatch(setLocation())
            
            // dispatch(setFilteredStores(data.user.favoriteStores)); //Comment out to get current selected store
            resolve(data);
          // }, 3000);

        })
        .catch((err) => {
          reject(err);
          dispatch(setLogInLoader(false));
          dispatch(setLogInError(err));
        });
    })
  };
}

export const facebookLogin = (fbID, firstName, lastName, email) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setFacebookLogInLoader(true));
      dispatch(setFacebookLogInError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/auth/facebook`;
      axios.post(url, {
          fbID: fbID,
          firstName: firstName,
          lastName: lastName,
          email: email
        })  
        .then((response) => {
          const data = response.data.data;
          setTimeout(() => {
            dispatch(setFacebookLogInLoader(false));
            dispatch(setAuthToken(data));
            resolve(true);
          }, 3000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setFacebookLogInLoader(false));
          dispatch(setFacebookLogInError(err));
        });
    })
  };
}

export const signup = (user: {}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(setSignUpLoader(true));
      dispatch(setSignUpError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/signup`;
      axios.post(url, user)  
        .then((response) => {
          const data = response.data.data;

          // setTimeout(() => {
            dispatch(setSignUpLoader(false));
            dispatch(setAuthToken(data));
            resolve(true);
          // }, 3000);

        })
        .catch((err) => {
          reject(err);
          dispatch(setSignUpLoader(false));
          dispatch(setSignUpError(err));
        });

    });
    
  };
}

export const fetchUserProfile = () => {
  return (dispatch, getState) => {
    dispatch(setUserProfileLoader(true));
    dispatch(setUserProfileError(false));

    let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me`;
    axios.get(url,
      {
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      })  
      .then((response) => {
        const data = response.data.data;

        // setTimeout(() => {
          // console.log(data);
          dispatch(setLogInUser(data));
          dispatch(setUserProfileLoader(false));
        // }, 3000);

      })
      .catch((err) => {
        dispatch(setUserProfileLoader(false));
        dispatch(setUserProfileError(true));
      });
  };
}

export const addFavoriteProduct = (product) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setFavoriteProductLoader(true));
      dispatch(setFavoriteProductError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/products/favorites`;
      axios.post(url, {
          product: product
        },
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          // setTimeout(() => {
            dispatch(setFavoriteProductLoader(false));
            dispatch(setLogInUser(data));
            dispatch(setFavoriteProductSuccess(true));
            resolve(true);
          // }, 1000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setFavoriteProductLoader(false));
          dispatch(setFavoriteProductError(err));
        });
    });
    
  };
}

export const removeFavoriteProduct = (product) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // dispatch(setRemoveFavoriteProductLoader(true));
      // dispatch(setRemoveFavoriteProductError(null));

      // console.log('product:Session:::' + JSON.stringify(product));
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/products/favorites/${product.productID}`;
      axios.delete(url, 
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          setTimeout(() => {
            dispatch(setLogInUser(data));
            // dispatch(setRemoveFavoriteProductLoader(true));
            resolve(true);
          }, 1000);
        })
        .catch((err) => {
          reject(err);
          // dispatch(setRemoveFavoriteProductLoader(false));
          // dispatch(setRemoveFavoriteProductError(err));
        });
    });
    
  };
}

export const addAlertProduct = (product) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setAlertProductLoader(true));
      dispatch(setAlertProductError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/products/alerts`;
      axios.post(url, {
          product: product
        },
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          // setTimeout(() => {
            dispatch(setAlertProductLoader(false));
            dispatch(setLogInUser(data));
            dispatch(setAlertProductSuccess(true));
            resolve(true);
          // }, 1000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setAlertProductLoader(false));
          dispatch(setAlertProductError(err));
        });
    });
    
  };
}

export const removeAlertProduct = (product) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setRemoveAlertProductLoader(true));
      dispatch(setRemoveAlertProductError(null));

      // console.log('product:Session:::' + JSON.stringify(product));
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/products/alerts/${product.productID}`;
      axios.delete(url, 
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          setTimeout(() => {
            dispatch(setLogInUser(data));
            dispatch(setRemoveAlertProductLoader(true));
            resolve(true);
          }, 1000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setRemoveAlertProductLoader(false));
          dispatch(setRemoveAlertProductError(err));
        });
    });
    
  };
}

export const addSavedCart = (cartName = '', cart = []) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setSavedCarttLoader(true));
      dispatch(setSavedCartError(null));
      dispatch(setSavedCartSuccess(false));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/carts`;
      axios.post(url, {
          cart: {
            name: cartName,
            products: cart 
          }
        },
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          setTimeout(() => {
            dispatch(setSavedCarttLoader(false));
            // dispatch(setLogInUser(data));
            dispatch(setSavedCartSuccess(true));
            resolve(true);
          }, 1000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setSavedCarttLoader(false));
          dispatch(setSavedCartError(err));
        });
    });
    
  };
}

export const removeSavedCart = (cartName = '') => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setRemoveSavedCarttLoader(true));
      dispatch(setRemoveFavoriteProductError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/carts`;
      axios.delete(url,
        {
          data: {
            cartName: cartName
          },
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          setTimeout(() => {
            dispatch(setLogInUser(data));
            dispatch(setRemoveSavedCarttLoader(false));
            resolve(true);
          }, 1000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setRemoveSavedCarttLoader(false));
          dispatch(setRemoveFavoriteProductError(err));
        });
    });
    
  };
}

export const saveFavoriteStores = (stores, postal) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(setFavoriteProductLoader(true));
      dispatch(setFavoriteProductError(null));

      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/stores`;
      axios.post(url, {
          stores: stores,
          postal: postal
        },
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          const data = response.data.data;
          // setTimeout(() => {
            dispatch(setFavoriteStoresLoader(false));
            dispatch(setLogInUser(data));
            dispatch(setFavoriteStoresSuccess(true));
            resolve(true);
          // }, 1000);
        })
        .catch((err) => {
          reject(err);
          dispatch(setFavoriteStoresLoader(false));
          dispatch(setFavoriteStoresError(err));
        });
    });
    
  };
}

export const savePostalRadius = (postal, radius, latitude, longitude ) => {  
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users/me/location`;
      let dataPut={
        postalCode: postal,
        searchRadius: radius,
        lat: latitude,
        lon: longitude,
        userID: getState().session.user._id
      }    
      axios.put(url, dataPut,
        {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          }
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    });
    
  };
}


export const productShareMail = (email: {}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {      
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/productshareemail`;
      // console.log(url);
      // console.log(JSON.stringify(email));
      axios.post(url, email)  
        .then((response) => {                    
            resolve(response);          
        })
        .catch((err) => {
          reject(err);          
        });
    });
    
  };
}
